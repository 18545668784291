import { TPerson } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";
// import { MOCKED_COLLEAGUES } from "./mock";

export const getUsersByCabinet = (
  id: TGetUsersByCabinetRequest
): RequestPromise<TGetUsersByCabinetResponse> => {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({ data: MOCKED_COLLEAGUES });
  //   }, 1000);
  // }) as never;
  return apiRequest.get(`/users/cabinet/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetUsersByCabinetRequest = number;

export type TGetUsersByCabinetResponse = TPerson[];
