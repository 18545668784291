import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatsByApplicationIdActions } from "./byApplicationId";
import { chatSendMessageActions } from "./send";
import { chatGetActions } from "./get";
import { chatRefreshActions } from "./refresh";
import * as api from "api/chat";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { chatsActions } from ".";

export const getChats = createAsyncThunk<
  api.TGetChatsResponse,
  api.TGetChatsRequest
>("chat/getChats", (payload, { dispatch, rejectWithValue, getState }) => {
  dispatch(chatsActions.setLoading(true));
  dispatch(chatsActions.setError(null));
  return api
    .getChats()
    .then(({ data }) => {
      dispatch(chatsActions.setChats(data));
      dispatch(chatsActions.setSuccess(true));

      return data;
    })
    .catch((err: RequestError) => {
      dispatch(chatsActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(chatsActions.setLoading(false));
    });
});

export const clearChat = createAsyncThunk<void, void>(
  "chat/clearChat",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(chatsByApplicationIdActions.clearState());
    dispatch(chatsActions.clearState());
    dispatch(chatSendMessageActions.clearState());
    dispatch(chatGetActions.clearState());
    dispatch(chatRefreshActions.clearState());
  }
);
