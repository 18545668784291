export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_FORMAT_SHORTENED = "DD.MM";

export const TIME_FORMAT = "HH:mm:ss";
export const TIME_FORMAT_SHORTENED = "HH:mm";

export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const ISO_DATE = "YYYY-MM-DD";
export const ISO_DATE_TIME = "YYYY-MM-DDTHH:mm:ss";
