import { Descriptions } from "antd";
import React, { ComponentProps, FC, useMemo } from "react";
import { StyledDescriptions } from "./DescriptionsCustom.styles";
import { DEVICE } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";

type TDescriptionsCustomProps = ComponentProps<typeof Descriptions> & {
  vertical?: boolean;
};

export const DescriptionsCustom: FC<TDescriptionsCustomProps> = ({
  items,
  vertical,
  ...props
}) => {
  const isSM = useMediaQuery({ query: DEVICE.SM });

  const itemsWithColon = useMemo(
    () => items?.map((item) => ({ ...item, label: `${item?.label}:` })),
    [items]
  );

  return (
    <StyledDescriptions
      layout={vertical || isSM ? "vertical" : "horizontal"}
      size={vertical || isSM ? "small" : undefined}
      bordered={vertical || isSM ? false : true}
      column={1}
      colon={false}
      items={itemsWithColon}
      $verticalStyled={vertical || isSM}
      {...props}
    />
  );
};
