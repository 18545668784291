import { TChatMessage } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const refreshChat = (
  data: TRefreshChatRequest
): RequestPromise<TRefreshChatResponse> => {
  return apiRequest.post(`/chat/refresh`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TRefreshChatRequest = {
  chatId: number;
  lastMessageId: number;
};

export type TRefreshChatResponse = TChatMessage[];
