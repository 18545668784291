import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "antd";
import { DEVICE } from "constants/breakpoints";

export const Wrapper = styled.div`
  display: flex;
`;
export const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const DataTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 1px solid #f0f0f0;
  padding-right: 8px;
  padding-bottom: 8px;
  position: relative;

  @media ${DEVICE.LG} {
    gap: 16px;
    padding-right: calc(40px + 16px);
  }
  @media ${DEVICE.SM} {
    padding-right: unset;
  }
`;
export const LinkButton = styled(Typography.Text)<{ $accent?: boolean }>`
  text-decoration: underline;
  padding: 0;
  border: none;
  height: unset;
  cursor: pointer;

  ${({ $accent }) =>
    $accent &&
    css`
      color: #1677ff;
    `}
`;
export const TitleBlock = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media ${DEVICE.LG} {
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }
`;
export const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 300px;

  @media ${DEVICE.SM} {
    width: unset;
  }
`;
export const OffersCountBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const CTABlock = styled.div`
  display: flex;
  gap: 8px;

  @media ${DEVICE.LG} {
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: column;
    gap: 16px;
  }
  @media ${DEVICE.SM} {
    position: unset;
    flex-direction: row;
    gap: 24px;
  }
`;
export const DataBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  padding-right: 8px;

  @media ${DEVICE.LG} {
    padding-right: 0;
  }
`;
export const StagesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  padding-left: 8px;
  border-left: 1px solid #f0f0f0;
`;
