import { createAsyncThunk } from "@reduxjs/toolkit";
import { fileDeleteActions } from ".";
import * as api from "api/files";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const deleteFile = createAsyncThunk<
  api.TDeleteFileResponse,
  api.TDeleteFileRequest
>("files/deleteFile", (payload, { dispatch, rejectWithValue }) => {
  dispatch(fileDeleteActions.setLoading(true));
  dispatch(fileDeleteActions.setError(null));

  return api
    .deleteFile(payload)
    .then(({ data }) => {
      dispatch(fileDeleteActions.setFile(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(fileDeleteActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(fileDeleteActions.setLoading(false));
    });
});
