import { createAsyncThunk } from "@reduxjs/toolkit";
import { fileAddActions } from ".";
import * as api from "api/files";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const addFile = createAsyncThunk<
  api.TAddFileResponse,
  api.TAddFileRequestThunk
>(
  "files/addFile",
  ({ fileData, onUploadProgress }, { dispatch, rejectWithValue }) => {
    dispatch(fileAddActions.setLoading(true));
    dispatch(fileAddActions.setError(null));

    const fmData = new FormData();
    (Object.keys(fileData) as Array<keyof typeof fileData>).forEach((key) => {
      fmData.append(key, fileData[key] as File);
    });

    return api
      .addFile({ fileData: fmData, onUploadProgress })
      .then(({ data }) => {
        dispatch(fileAddActions.setFile(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(fileAddActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(fileAddActions.setLoading(false));
      });
  }
);
