import { combineReducers } from "@reduxjs/toolkit";
import { chatsByApplicationIdReducer } from "./byApplicationId";
import { chatSendMessageReducer } from "./send";
import { chatGetReducer } from "./get";
import { chatRefreshReducer } from "./refresh";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RequestError, TChat, TChatMessage } from "types";
import { TChatsState } from "./types";

const initialState: TChatsState = {
  isLoading: false,
  error: null,
  chats: undefined,
  success: false,
  curChatId: undefined
};

export const ChatsSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    clearState: () => initialState,
    setChats: (state, { payload }: PayloadAction<TChat[]>) => {
      state.chats = payload;
    },
    setChat: (state, { payload }: PayloadAction<TChat>) => {
      if (state.chats) {
        const index = state.chats?.findIndex(
          (chat) => chat?.id === payload?.id
        );
        if (index !== -1) {
          state.chats![index] = payload;
        } else {
          state.chats = [payload, ...state.chats];
        }
      }
    },
    setMessages: (
      state,
      { payload }: PayloadAction<{ messages: TChatMessage[]; id: number }>
    ) => {
      if (state.chats) {
        const index = state.chats?.findIndex(
          (chat) => chat?.id === payload?.id
        );
        if (index !== -1) {
          let messages = state.chats![index].messages;
          state.chats![index].messages = [...payload.messages, ...messages];
        }
      }
    },
    setSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.success = payload;
    },
    setCurChatId: (state, { payload }: PayloadAction<number | undefined>) => {
      state.curChatId = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const chatsActions = ChatsSlice.actions;
export const chatsStateReducer = ChatsSlice.reducer;

export const chatReducer = combineReducers({
  state: chatsStateReducer,
  byApplicationId: chatsByApplicationIdReducer,
  send: chatSendMessageReducer,
  get: chatGetReducer,
  refresh: chatRefreshReducer
});

export * as chatsStateSelectors from "./selectors";
