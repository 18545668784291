import { TCabinet } from "types";
import { apiRequest, RequestPromise } from "utils/api";
import Cookies from "js-cookie";

export * from "./id";
export * from "./add";
export * from "./update";
export * from "./delete";

export const getCabinets = (): RequestPromise<TGetCabinetsResponse> => {
  return apiRequest.get(`/cabinets/`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetCabinetsResponse = TCabinet[];
