import { createAsyncThunk } from "@reduxjs/toolkit";
import { offerForSellerUpdateActions } from ".";
import * as api from "api/offers_for_sellers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const updateOfferForSeller = createAsyncThunk<
  api.TUpdateOfferForSellerResponse,
  api.TUpdateOfferForSellerRequest
>(
  "offersForSeller/updateOfferForSeller",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(offerForSellerUpdateActions.setLoading(true));
    dispatch(offerForSellerUpdateActions.setError(null));
    return api
      .updateOfferForSeller(payload)
      .then(({ data }) => {
        dispatch(offerForSellerUpdateActions.setOfferForSeller(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offerForSellerUpdateActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offerForSellerUpdateActions.setLoading(false));
      });
  }
);
