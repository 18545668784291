import { createAsyncThunk } from "@reduxjs/toolkit";
import { approvementUpdateActions } from ".";
import * as api from "api/approvements";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const updateApprovement = createAsyncThunk<
  api.TUpdateApprovementResponse,
  api.TUpdateApprovementRequest
>("approvement/updateApprovement", (payload, { dispatch, rejectWithValue }) => {
  dispatch(approvementUpdateActions.setLoading(true));
  dispatch(approvementUpdateActions.setError(null));
  return api
    .updateApprovement(payload)
    .then(({ data }) => {
      dispatch(approvementUpdateActions.setApprovement(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(approvementUpdateActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(approvementUpdateActions.setLoading(false));
    });
});
