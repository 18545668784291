import { createAsyncThunk } from "@reduxjs/toolkit";
import { setPasswordActions } from ".";
import * as api from "api/unauthorized";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const setPassword = createAsyncThunk<
  api.TSetPasswordResponse,
  api.TSetPasswordRequest
>("user/setPassword", (payload, { dispatch, rejectWithValue }) => {
  dispatch(setPasswordActions.setLoading(true));
  dispatch(setPasswordActions.setError(null));
  return api
    .setPassword(payload)
    .then(({ data }) => {
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(setPasswordActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(setPasswordActions.setLoading(false));
    });
});
