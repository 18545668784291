import dayjs from "dayjs";
import { ISO_DATE } from "constants/dates";

export const fromIsoDate = (date?: string) => {
  if (!date) {
    return undefined;
  }

  return dayjs(date, ISO_DATE);
};
