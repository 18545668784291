import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TChatSendMessageState } from "./types";

import { RequestError } from "types";

const initialState: TChatSendMessageState = {
  isLoading: false,
  error: null
};

export const ChatSendMessageSlice = createSlice({
  name: "chat/Send",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const chatSendMessageActions = ChatSendMessageSlice.actions;
export const chatSendMessageReducer = ChatSendMessageSlice.reducer;

export * as chatSendMessageSelectors from "./selectors";
