import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TChatsByApplicationIdState } from "./types";

import { RequestError, TChat, TChatMessage } from "types";

const initialState: TChatsByApplicationIdState = {
  isLoading: false,
  error: null,
  chats: undefined,
  success: false,
  curChatId: undefined
};

export const ChatsByApplicationIdSlice = createSlice({
  name: "chat/ByApplicationId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setChats: (state, { payload }: PayloadAction<TChat[]>) => {
      state.chats = payload;
    },
    setChat: (state, { payload }: PayloadAction<TChat>) => {
      if (state.chats) {
        const index = state.chats?.findIndex(
          (chat) => chat?.id === payload?.id
        );
        if (index !== -1) {
          state.chats![index] = payload;
        } else {
          state.chats = [payload, ...state.chats];
        }
      }
    },
    setMessages: (
      state,
      { payload }: PayloadAction<{ messages: TChatMessage[]; id: number }>
    ) => {
      if (state.chats) {
        const index = state.chats?.findIndex(
          (chat) => chat?.id === payload?.id
        );
        if (index !== -1) {
          let messages = state.chats![index].messages;
          state.chats![index].messages = [...payload.messages, ...messages];
        }
      }
    },
    setSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.success = payload;
    },
    setCurChatId: (state, { payload }: PayloadAction<number | undefined>) => {
      state.curChatId = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const chatsByApplicationIdActions = ChatsByApplicationIdSlice.actions;
export const chatsByApplicationIdReducer = ChatsByApplicationIdSlice.reducer;

export * as chatsByApplicationIdSelectors from "./selectors";
