import { combineReducers } from "@reduxjs/toolkit";
import { ApplicationByIdReducer } from "./byId";
import { applicationAddReducer } from "./add";
import { applicationUpdateReducer } from "./update";
import { applicationsGetReducer } from "./get";
import { applicationPositionsReducer } from "./positions";
import { applicationUpdateStatusReducer } from "./update_status";

export const applicationsReducer = combineReducers({
  byId: ApplicationByIdReducer,
  add: applicationAddReducer,
  update: applicationUpdateReducer,
  get: applicationsGetReducer,
  positions: applicationPositionsReducer,
  updateStatus: applicationUpdateStatusReducer
});
