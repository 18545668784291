import { TProject } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addProject = (
  data: TAddProjectRequest
): RequestPromise<TAddProjectResponse> => {
  return apiRequest.post(`/project/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TAddProjectRequest = Omit<TProject, "project_id">;

export type TAddProjectResponse = TProject;
