import { TCabinet } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addCabinet = (
  data: TAddCabinetRequest
): RequestPromise<TAddCabinetResponse> => {
  return apiRequest.post(`/cabinets/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TAddCabinetRequest = Omit<TCabinet, "cabinet_id">;

export type TAddCabinetResponse = TCabinet;
