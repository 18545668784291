import { RequestPromise, apiRequest } from "utils/api";
// import { MOCKED_REGISTRATION } from "./mock";

export const register = (
  data: TRegistrationRequest
): RequestPromise<TRegistrationResponse> => {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({ data: MOCKED_REGISTRATION });
  //   }, 1000);
  // }) as never;

  return apiRequest.post(`/unauthorized/register`, data);
};

export type TRegistrationResponse = {
  email: string;
};

export type TRegistrationRequest = {
  phone: string;
  company_name: string;
  email: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
};
