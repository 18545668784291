import { TChat } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getChat = (
  data: TGetChatRequest
): RequestPromise<TGetChatResponse> => {
  return apiRequest.post(`/chat/get`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetChatRequest = {
  applicationId: number;
  sellerCabinetId: number;
};

export type TGetChatResponse = TChat;
