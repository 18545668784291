import { createAsyncThunk } from "@reduxjs/toolkit";
import { cabinetDeleteActions } from ".";
import * as api from "api/cabinets";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const deleteCabinet = createAsyncThunk<
  api.TDeleteCabinetResponse,
  api.TDeleteCabinetRequest
>("cabinet/deleteCabinet", (payload, { dispatch, rejectWithValue }) => {
  dispatch(cabinetDeleteActions.setLoading(true));
  dispatch(cabinetDeleteActions.setError(null));
  return api
    .deleteCabinet(payload)
    .then(({ data }) => {
      dispatch(cabinetDeleteActions.setCabinet(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(cabinetDeleteActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(cabinetDeleteActions.setLoading(false));
    });
});
