import React, { useCallback, useEffect } from "react";
import { ErrorAlert } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  Button,
  Checkbox,
  Form,
  FormProps,
  Grid,
  Input,
  Typography
} from "antd";
import { TRegistrationRequest } from "api/unauthorized";
import { MaskedInput } from "antd-mask-input";
import { Link } from "react-router-dom";
import { registerActions, registerSelectors } from "store/user/register";
import { register } from "store/user/register/thunk";
import { UnauthorizedFormTitle } from "components/common/redesign";
import { CTABlock, RegisteredTextBlock } from "./Registration.styles";

type TRegistrationFormValue = TRegistrationRequest & {
  approve: boolean;
};

export const Registration = () => {
  const dispatch = useAppDispatch();
  const screens = Grid.useBreakpoint();

  const { isLoading, error, email } = useAppSelector(
    registerSelectors.getState
  );

  const onRegister: FormProps<TRegistrationFormValue>["onFinish"] = useCallback(
    (values: TRegistrationFormValue) => {
      const { approve, ...data } = values;
      dispatch(register(data));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(registerActions.clearState());
    };
  }, [dispatch]);

  if (email) {
    return (
      <>
        <RegisteredTextBlock>
          <UnauthorizedFormTitle>
            Наш менеджер свяжется с Вами
          </UnauthorizedFormTitle>
          <Typography.Text style={{ textAlign: "center" }}>
            В ближайшее время на Вашу почту поступит письмо от нашего менеджера
            с инструкцией для продолжения регистрации.
          </Typography.Text>
        </RegisteredTextBlock>
        <Link to="https://contractor-b2b.ru">
          <Button size="large" style={{ width: 160 }}>
            Готово
          </Button>
        </Link>
      </>
    );
  }

  return (
    <>
      <UnauthorizedFormTitle>Регистрация</UnauthorizedFormTitle>

      <Form
        layout="vertical"
        name="register"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        style={{ maxWidth: "320px" }}
        onFinish={onRegister}
        // onFinishFailed={onFinishFailed}
        // autoComplete="off"
        labelWrap
        // colon={false}
        requiredMark={false}
      >
        <Typography.Text
          style={{
            display: "block",
            marginBottom: 16
          }}
        >
          Укажите Ваши данные для регистрации
        </Typography.Text>

        <Form.Item<TRegistrationFormValue>
          label="Фамилия"
          name="last_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Укажите фамилию"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TRegistrationFormValue>
          label="Имя"
          name="first_name"
          rules={[{ required: true, whitespace: true, message: "Укажите имя" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TRegistrationFormValue> label="Отчество" name="middle_name">
          <Input />
        </Form.Item>

        <Form.Item<TRegistrationFormValue>
          label="Контантный телефон"
          name="phone"
          rules={[
            {
              pattern: new RegExp(
                /^((8|\+7)[- ]?)(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
              ),
              message: "Укажите корректный телефон"
            },
            {
              required: true,
              message: "Укажите контантный телефона"
            }
          ]}
        >
          <MaskedInput mask={"0(000)000-00-00"} />
        </Form.Item>

        <Form.Item<TRegistrationFormValue>
          label="E-mail"
          name="email"
          rules={[{ required: true, message: "Укажите e-mail", type: "email" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TRegistrationFormValue>
          label="Фирма"
          name="company_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Укажите название компании"
            }
          ]}
          style={{
            marginBottom: screens?.sm ? 32 : undefined
          }}
        >
          <Input />
        </Form.Item>

        <Form.Item<TRegistrationFormValue>
          name="approve"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Подтвердите согласие"))
            }
          ]}
          style={{
            marginBottom: screens?.sm ? 32 : undefined
          }}
        >
          <Checkbox>
            Даю согласие на обработку своих персональных данных в соответствии
            с 
            <Link to="/privacy.pdf" target="_blank">
              Условиями
            </Link>
          </Checkbox>
        </Form.Item>

        <Form.Item
          wrapperCol={{ span: 24 }}
          style={{
            marginBottom: screens?.sm ? 32 : undefined
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            block
            size="large"
          >
            Зарегистрироваться
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />

        <CTABlock>
          <Typography.Text type="secondary">
            Уже зарегестрированы?
          </Typography.Text>
          <Link to="/login">
            <Button type="link" style={{ padding: "4px 5px" }}>
              Войти
            </Button>
          </Link>
        </CTABlock>
      </Form>
    </>
  );
};
