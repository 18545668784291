import { TOfferForBuyer } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getOffersForBuyerByApplicationId = (
  id: TGetOffersForBuyerByApplicationIdRequest
): RequestPromise<TGetOffersForBuyerByApplicationIdResponse> => {
  return apiRequest.get(`/offers-for-buyers/applications/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetOffersForBuyerByApplicationIdRequest = number;

export type TGetOffersForBuyerByApplicationIdResponse = TOfferForBuyer[];
