import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLicenseTypeByIdState } from "./types";

import { RequestError, TLicenseType } from "types";

const initialState: TLicenseTypeByIdState = {
  isLoading: false,
  error: null,
  licenseType: undefined
};

export const LicenseTypeByIdSlice = createSlice({
  name: "license/type/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicenseType: (state, { payload }: PayloadAction<TLicenseType>) => {
      state.licenseType = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const licenseTypeByIdActions = LicenseTypeByIdSlice.actions;
export const licenseTypeByIdReducer = LicenseTypeByIdSlice.reducer;

export * as licenseTypeByIdSelectors from "./selectors";
