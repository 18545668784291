import { EditOutlined } from "@ant-design/icons";
import { Button, DescriptionsProps } from "antd";
import { DescriptionsCustom } from "components/common/redesign/DescriptionsCustom/DescriptionsCustom";
import { TypographyTitleNoMargin } from "components/common/redesign/TypographyTitleNoMargin/TypographyTitleNoMargin";
import React, { FC, useMemo } from "react";
import { TPerson } from "types";

type TApplicationProcessProps = {
  creator?: TPerson;
  responsible: TPerson;
  onStepEdit?: (step: number) => void;
};

export const ApplicationProcess: FC<TApplicationProcessProps> = ({
  creator,
  responsible,
  onStepEdit
}) => {
  const processData: DescriptionsProps["items"] = useMemo(
    () => [
      ...(creator
        ? [
            {
              key: "creator",
              label: "Создатель заявки",
              children: `${creator?.last_name} ${creator?.first_name}`
            }
          ]
        : []),
      {
        key: "responsible",
        label: "Ответственный по заявке",
        children: (
          <>
            {responsible?.last_name} {responsible?.first_name}
            <br />
            {responsible?.position}
            <br />
            {responsible?.email}
            <br />
            {responsible?.phone}
          </>
        )
      }
    ],
    [creator, responsible]
  );

  return (
    <>
      {onStepEdit && (
        <TypographyTitleNoMargin level={5}>
          <Button icon={<EditOutlined onClick={() => onStepEdit?.(2)} />} />
          Процесс заявки
        </TypographyTitleNoMargin>
      )}
      <DescriptionsCustom
        items={processData}
        title={!onStepEdit ? "Процесс заявки" : undefined}
      />
    </>
  );
};
