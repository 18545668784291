import React, { useEffect } from "react";
import { userSelectors } from "store/user";
import { useAppDispatch, useAppSelector } from "store/store";
import { Wrapper } from "./Admin.styles";
import { Tabs, TabsProps, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  AccountsTab,
  ApplicationsOffersTab,
  CabinetsTab,
  EntitiesTab,
  LicensesTab,
  LicenseTypesTab,
  ProjectsTab,
  UsersTab
} from "./components";
import { cabinetsGetActions } from "store/cabinets/get";

export const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { admin_tab } = useParams<{ admin_tab: string }>();

  const { user } = useAppSelector(userSelectors.getState);

  const tabs: TabsProps["items"] = [
    {
      key: "cabinets",
      label: "Кабинеты",
      children: <CabinetsTab />
    },
    {
      key: "licenses",
      label: "Лицензии",
      children: <LicensesTab />
    },
    {
      key: "licenseTypes",
      label: "Типы лицензии",
      children: <LicenseTypesTab />
    },
    {
      key: "users",
      label: "Пользователи",
      children: <UsersTab />
    },
    {
      key: "companies",
      label: "Юр. лица",
      children: <EntitiesTab />
    },
    {
      key: "accounts",
      label: "Счета",
      children: <AccountsTab />
    },
    {
      key: "projects",
      label: "Проекты",
      children: <ProjectsTab />
    },
    {
      key: "applications_offers",
      label: "Заявки и Предложения",
      children: <ApplicationsOffersTab />
    }
  ];

  useEffect(() => {
    if (!user?.is_admin) {
      navigate("/", { replace: true, relative: "path" });
    }
  }, [navigate, user]);

  useEffect(() => {
    return () => {
      dispatch(cabinetsGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Панель администратора
      </Typography.Title>

      <Tabs
        items={tabs}
        destroyInactiveTabPane
        activeKey={admin_tab}
        onChange={(key) => {
          navigate(`../${key}`);
        }}
      />
    </Wrapper>
  );
};
