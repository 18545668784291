import { TChat } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getChatsByApplicationId = (
  id: TGetChatsByApplicationIdRequest
): RequestPromise<TGetChatsByApplicationIdResponse> => {
  return apiRequest.get(`/chat/application${id ? `/${id}` : ""}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetChatsByApplicationIdRequest = number | void;

export type TGetChatsByApplicationIdResponse = TChat[];
