import { TProject } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateProject = (
  data: TUpdateProjectRequest
): RequestPromise<TUpdateProjectResponse> => {
  return apiRequest.post(`/project/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateProjectRequest = TProject;

export type TUpdateProjectResponse = TProject;
