import React, { FC, useCallback, useMemo } from "react";
import { TAccount } from "types";
import { Panel } from "../Panel/Panel";
import { Button, List } from "antd";
import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  CreditCardOutlined,
  LinkOutlined
} from "@ant-design/icons";

type TAccountDataProps = {
  account?: TAccount;
  isSmall?: boolean;
  withCopy?: boolean;
};

export const AccountData: FC<TAccountDataProps> = ({
  account,
  isSmall = false,
  withCopy = false
}) => {
  const accountData = useMemo(() => {
    return [
      {
        label: "Активный",
        value: account?.status ? "Активный" : "Не активный",
        icon: account?.status ? <CheckOutlined /> : <CloseOutlined />
      },
      {
        label: "Название счета",
        value: account?.name,
        icon: <CreditCardOutlined />
      },
      {
        label: "Банк",
        value: account?.bank_name,
        icon: <BankOutlined />
      },
      {
        label: "Расчетный счет",
        value: account?.number,
        icon: <LinkOutlined />
      },
      {
        label: "Кор. счёт",
        value: account?.correspondent,
        icon: <LinkOutlined />
      },
      {
        label: "БИК",
        value: account?.bik,
        icon: <LinkOutlined />
      },
      {
        label: "ОКАТО",
        value: account?.okato,
        icon: <LinkOutlined />
      }
    ];
  }, [account]);

  const onCopy = useCallback(() => {
    const data = accountData
      .map((item) => `${item?.label}: ${item?.value || "-"}`)
      .join("\n");
    navigator.clipboard.writeText(data);
  }, [accountData]);

  const copyButton = useMemo(
    () => [
      ...(withCopy
        ? [
            {
              value: (
                <Button onClick={onCopy} icon={<CopyOutlined />}>
                  Копировать данные
                </Button>
              ),
              icon: undefined,
              label: undefined
            }
          ]
        : [])
    ],
    [onCopy, withCopy]
  );

  return (
    <Panel
      style={{
        display: "flex",
        flexDirection: "column"
      }}
      isSmall={isSmall}
    >
      <List
        size={isSmall ? "small" : undefined}
        dataSource={[...accountData, ...copyButton]}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={item?.icon}
              title={item?.value}
              description={item?.label}
            />
          </List.Item>
        )}
      />
    </Panel>
  );
};
