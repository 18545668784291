import React, { FC, useMemo } from "react";
import { TCabinet, TLicense, TLicenseType } from "types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { toDisplayDate } from "utils/dates";

type TLicensesTableProps = {
  licenses?: TLicense[];
  licenseTypes?: TLicenseType[];
  cabinets?: TCabinet[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  isDeleting?: boolean;
};

export const LicensesTable: FC<TLicensesTableProps> = ({
  licenses,
  licenseTypes,
  cabinets,
  onDelete,
  onEdit,
  isDeleting
}) => {
  const columns: ColumnsType<TLicense> = useMemo(
    () => [
      {
        title: "Кабинет",
        dataIndex: "cabinet_id",
        render: (value: number) => {
          const cabinet = cabinets?.find(
            (cabinet) => cabinet?.cabinet_id === value
          );
          return cabinet
            ? `${cabinet?.name} (${value ? "Поставщик" : "Покупатель"})`
            : value;
        }
      },
      {
        title: "Тип Лицензии",
        dataIndex: "license_type_id",
        render: (value: number) => {
          const licenseType = licenseTypes?.find(
            (licenseType) => licenseType?.license_type_id === value
          );
          return licenseType ? (
            <>
              {licenseType?.is_active ? <CheckOutlined /> : <CloseOutlined />}{" "}
              {licenseType?.name}
            </>
          ) : (
            value
          );
        }
      },
      {
        title: "Дата начала",
        dataIndex: "start_date",
        render: toDisplayDate
      },
      {
        title: "Дата окончания",
        dataIndex: "due_date",
        render: toDisplayDate
      },
      {
        title: "Комментарий",
        dataIndex: "comment"
      },
      {
        key: "edit",
        render: (value: TLicense) => (
          <Button
            onClick={() => onEdit?.(value?.license_id)}
            icon={<EditOutlined />}
          >
            Редактировать
          </Button>
        )
      },
      {
        key: "delete",
        render: (value: TLicense) => (
          <Button
            onClick={() => onDelete?.(value?.license_id)}
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
          >
            Удалить
          </Button>
        )
      }
    ],
    [cabinets, isDeleting, licenseTypes, onDelete, onEdit]
  );

  return (
    <Table
      dataSource={licenses}
      columns={columns}
      pagination={false}
      scroll={{ x: "1400px" }}
    />
  );
};
