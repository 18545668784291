import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/dates";
import { fromIsoDate } from "./fromIsoDate";

export const toDisplayDate = (date?: string | Date) => {
  if (!date) {
    return undefined;
  }

  const dDate = typeof date === "string" ? fromIsoDate(date) : dayjs(date);

  return dDate?.format(DATE_FORMAT);
};
