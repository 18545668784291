import { RequestPromise, apiRequest } from "utils/api";
// import { MOCKED_RESTORE_PASSWORD } from "./mock";

export const restorePassword = (
  data: TRestorePasswordRequest
): RequestPromise<TRestorePasswordResponse> => {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({ data: MOCKED_RESTORE_PASSWORD });
  //   }, 1000);
  // }) as never;

  return apiRequest.post(`/unauthorized/restorepassword`, data);
};

export type TRestorePasswordResponse = {
  email: string;
};

export type TRestorePasswordRequest = {
  email: string;
};
