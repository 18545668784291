import React, { FC, useEffect, useMemo } from "react";
import { TEntity } from "types";
import { DescriptionsProps } from "antd";
import { DescriptionsCustom } from "../DescriptionsCustom/DescriptionsCustom";

type TCompanyDataProps = {
  entity: TEntity;
  vertical?: boolean;
  setDataForCopy?: (value: string) => void;
};

export const CompanyData: FC<TCompanyDataProps> = ({
  entity,
  vertical,
  setDataForCopy
}) => {
  const generalData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "full_name",
        label: "Полное название",
        children: entity?.full_name
      },
      {
        key: "short_name",
        label: "Сокращенное название:",
        children: entity?.short_name
      },
      {
        key: "legal_address",
        label: "Юридический адрес",
        children: entity?.legal_address
      },
      {
        key: "physical_address",
        label: "Физический адрес",
        children: entity?.physical_address
      },
      {
        key: "company_email",
        label: "E-mail",
        children: entity?.company_email
      },
      {
        key: "company_phone",
        label: "Контактный телефон",
        children: entity?.company_phone
      },
      {
        key: "website",
        label: "Сайт компании",
        children: entity?.website
      },
      {
        key: "accountant_name",
        label: "Бухгалтер",
        children: entity?.accountant_name
      },
      { key: "inn", label: "ИНН", children: entity?.inn },
      { key: "ogrn", label: "ОГРН", children: entity?.ogrn },
      { key: "kpp", label: "КПП", children: entity?.kpp },
      {
        key: "is_active",
        label: "Действующее",
        children: entity?.is_active ? "Да" : "Нет"
      }
    ],
    [entity]
  );

  useEffect(() => {
    if (setDataForCopy) {
      setDataForCopy(
        generalData
          .map((item) => `${item?.label}: ${item?.children || "-"}`)
          .join("\n")
      );
    }
  }, [generalData, setDataForCopy]);

  return (
    <>
      <DescriptionsCustom vertical={vertical} items={generalData} />
    </>
  );
};
