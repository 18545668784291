import React from "react";
import { Tabs, TabsProps } from "antd";
import {
  CreditCardOutlined,
  ProjectOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined
} from "@ant-design/icons";

import { useNavigate, useParams } from "react-router-dom";
import {
  AccountsTab,
  ColleaguesTab,
  CompaniesTab,
  PersonalTab,
  ProjectsTab
} from "./components";

export const Profile = () => {
  const navigate = useNavigate();
  const { profile_tab } = useParams<{ profile_tab: string }>();

  const tabs: TabsProps["items"] = [
    /* TODO иконки */
    {
      key: "personal",
      label: "Профиль",
      icon: <UserOutlined />,
      children: <PersonalTab />
    },
    {
      key: "companies",
      label: "Мои компании",
      icon: <ShopOutlined />,
      children: <CompaniesTab />
    },
    {
      key: "colleagues",
      label: "Коллеги",
      icon: <TeamOutlined />,
      children: <ColleaguesTab />
    },
    {
      key: "projects",
      label: "Проекты",
      icon: <ProjectOutlined />,
      children: <ProjectsTab />
    },
    {
      key: "accounts",
      label: "Счета",
      icon: <CreditCardOutlined />,
      children: <AccountsTab />
    }
  ];

  return (
    <>
      <Tabs
        items={tabs}
        destroyInactiveTabPane
        activeKey={profile_tab}
        onChange={(key) => {
          navigate(`../${key}`);
        }}
        size="small"
        style={{ marginTop: "-16px" }}
      />
    </>
  );
};
