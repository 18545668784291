import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Layout, Menu, Typography } from "antd";
import { DEVICE } from "constants/breakpoints";
import { Link } from "react-router-dom";

export const LayoutStyled = styled(Layout)`
  height: 100%;
  min-height: 100%;
  flex-direction: column;
`;

export const Header = styled.div`
  background: #ffffff;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  height: 80px;
  min-height: 80px;

  @media ${DEVICE.XL} {
    padding: 0 16px;
  }
  @media ${DEVICE.SM} {
    height: 64px;
    min-height: 64px;
    padding: 0 24px;
  }
`;
export const LogoLink = styled(Link)`
  height: 44px;
  width: 260.33px;
  display: block;

  @media ${DEVICE.XL} {
    height: 30px;
    width: 177.5px;
  }
`;
export const Logo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const CTABlock = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
export const StyledButton = styled(Button)`
  padding: 0;
  font-size: 32;

  & .ant-btn-icon > .anticon {
    font-size: 32px;

    @media ${DEVICE.XL} {
      font-size: 28px;
    }
  }
`;
export const ProfileBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProfileTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ProfilePositionText = styled(Typography.Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const MainWrapper = styled.div`
  flex: 1;
  padding: 24px 24px 0;
  overflow: auto;
  display: flex;
  gap: 24px;
  background: #ebf2ff;
  position: relative;

  @media ${DEVICE.XL} {
    padding: 16px 16px 0;
    gap: 16px;
  }

  @media ${DEVICE.SM} {
    background: #ffffff;
    padding: 0;
  }
`;

export const MenuColumn = styled.div<{ $opened: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ $opened }) =>
    $opened &&
    css`
      min-width: 240px;
      width: 240px;
    `}
`;
export const MenuStyled = styled(Menu)`
  padding: 8px;
  border-radius: 8px;

  & .ant-menu-item {
    margin-left: 0;
    margin-right: 0;
  }
`;
export const Darkener = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #00000033;
  z-index: 1;
`;

export const ContentColumn = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Content = styled.div<{ $notScrollable: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 48px;
  border-radius: 8px;
  background: #ffffff;
  width: 100%;

  @media ${DEVICE.XL} {
    padding: 24px 16px 48px;
  }

  ${({ $notScrollable }) =>
    $notScrollable &&
    css`
      height: 100%;
      overflow: auto;
    `}
`;

export const Footer = styled.div<{ $opened: boolean }>`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-out 0.2s;

  ${({ $opened }) => css`
    margin-right: calc((${$opened ? "240px" : "80px"} + 24px));

    @media ${DEVICE.XL} {
      margin-right: calc((${$opened ? "240px" : "80px"} + 16px));
    }
    @media ${DEVICE.LG} {
      margin-right: calc((80px + 16px));
    }
    @media ${DEVICE.SM} {
      margin-right: unset;
    }
  `}
`;
export const CredText = styled(Typography.Text)`
  font-size: 12px;
  line-height: 16px;
`;
