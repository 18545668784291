import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOfferForSellerUpdateStatusState } from "./types";

import { RequestError } from "types";
import { TUpdateOfferForSellerStatusResponse } from "api/offers_for_sellers";

const initialState: TOfferForSellerUpdateStatusState = {
  isLoading: false,
  error: null,
  offerForSellerId: undefined,
  new_status_id: undefined
};

export const OfferForSellerUpdateStatusSlice = createSlice({
  name: "offerForSeller/update_status",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOfferForSeller: (
      state,
      { payload }: PayloadAction<TUpdateOfferForSellerStatusResponse>
    ) => {
      state.offerForSellerId = payload?.offer_id;
      state.new_status_id = payload?.new_status_id;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const offerForSellerUpdateStatusActions =
  OfferForSellerUpdateStatusSlice.actions;
export const offerForSellerUpdateStatusReducer =
  OfferForSellerUpdateStatusSlice.reducer;

export * as offerForSellerUpdateStatusSelectors from "./selectors";
