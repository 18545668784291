import { createAsyncThunk } from "@reduxjs/toolkit";
import { licenseTypeDeleteActions } from ".";
import * as api from "api/license";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const deleteLicenseType = createAsyncThunk<
  api.TDeleteLicenseTypeResponse,
  api.TDeleteLicenseTypeRequest
>(
  "license/type/deleteLicenseType",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(licenseTypeDeleteActions.setLoading(true));
    dispatch(licenseTypeDeleteActions.setError(null));
    return api
      .deleteLicenseType(payload)
      .then(({ data }) => {
        dispatch(licenseTypeDeleteActions.setLicenseType(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(licenseTypeDeleteActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(licenseTypeDeleteActions.setLoading(false));
      });
  }
);
