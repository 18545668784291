import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLicensesGetState } from "./types";

import { RequestError, TLicense } from "types";

const initialState: TLicensesGetState = {
  isLoading: false,
  error: null,
  licenses: undefined
};

export const LicensesGetSlice = createSlice({
  name: "license/get",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicenses: (state, { payload }: PayloadAction<TLicense[]>) => {
      state.licenses = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const licensesGetActions = LicensesGetSlice.actions;
export const licensesGetReducer = LicensesGetSlice.reducer;

export * as licensesGetSelectors from "./selectors";
