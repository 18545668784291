import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "antd";

export const TypographyTitleStyled = styled(Typography.Title)<{
  $removeWeight: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;

  margin: 0 !important;

  ${({ $removeWeight }) =>
    $removeWeight &&
    css`
      font-weight: 400 !important;
    `}
`;
