import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import React, { ComponentProps, FC, useMemo } from "react";
import { TApplication, TApplicationForSeller } from "types";
import { applicationStatusIdMapper } from "utils/mappers";

type TApplicationStatusTagProps = ComponentProps<typeof Tag> & {
  status_id: (TApplication | TApplicationForSeller)["application_status_id"];
};

export const ApplicationStatusTag: FC<TApplicationStatusTagProps> = ({
  status_id,
  ...props
}) => {
  const statusData = useMemo(
    () => applicationStatusIdMapper[status_id],
    [status_id]
  );

  return (
    <Tag
      color={statusData?.color}
      icon={
        status_id === 97 ? (
          <CheckOutlined />
        ) : status_id === 98 || status_id === 122 ? (
          <CloseOutlined />
        ) : undefined
      }
      {...props}
    >
      {statusData?.shortName || status_id}
    </Tag>
  );
};
