import { TAccount } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getAccountsByCabinetId = (
  id: TByCabinetIdAccountRequest
): RequestPromise<TByCabinetIdAccountResponse> => {
  return apiRequest.get(`/accounts/cabinet/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TByCabinetIdAccountRequest = number;

export type TByCabinetIdAccountResponse = TAccount[];
