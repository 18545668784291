import { TLicenseType } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const deleteLicenseType = (
  id: TDeleteLicenseTypeRequest
): RequestPromise<TDeleteLicenseTypeResponse> => {
  return apiRequest.post(`/license/type/delete/${id}`, undefined, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TDeleteLicenseTypeRequest = number;

export type TDeleteLicenseTypeResponse = TLicenseType;
