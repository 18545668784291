import { combineReducers } from "@reduxjs/toolkit";
import { licenseTypeByIdReducer } from "./byId";
import { licenseTypeAddReducer } from "./add";
import { licenseTypeUpdateReducer } from "./update";
import { licenseTypesGetReducer } from "./get";
import { licenseTypeDeleteReducer } from "./delete";

export const licenseTypesReducer = combineReducers({
  byId: licenseTypeByIdReducer,
  add: licenseTypeAddReducer,
  update: licenseTypeUpdateReducer,
  delete: licenseTypeDeleteReducer,
  get: licenseTypesGetReducer
});
