import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLicenseTypeAddState } from "./types";

import { RequestError, TLicenseType } from "types";

const initialState: TLicenseTypeAddState = {
  isLoading: false,
  error: null,
  licenseType: undefined
};

export const LicenseTypeAddSlice = createSlice({
  name: "license/type/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicenseType: (state, { payload }: PayloadAction<TLicenseType>) => {
      state.licenseType = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const licenseTypeAddActions = LicenseTypeAddSlice.actions;
export const licenseTypeAddReducer = LicenseTypeAddSlice.reducer;

export * as licenseTypeAddSelectors from "./selectors";
