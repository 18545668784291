import React, { FC, useCallback, useMemo } from "react";
import { Panel } from "..";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { RequestError, TCabinet, TLicense, TLicenseType } from "types";
import { ErrorAlert } from "..";
import dayjs, { Dayjs } from "dayjs";
import { DATE_FORMAT, ISO_DATE } from "constants/dates";
import { toSendDate } from "utils/dates";

type TLicenseFormValues = Omit<TLicense, "start_date" | "due_date"> & {
  start_date: Dayjs;
  due_date: Dayjs;
};

type TLicenseEditFormProps = {
  license?: TLicense;
  cabinets?: TCabinet[];
  licenseTypes?: TLicenseType[];
  onSubmit: (values: TLicense) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const LicenseEditForm: FC<TLicenseEditFormProps> = ({
  license,
  cabinets,
  licenseTypes,
  onSubmit,
  isLoading,
  error
}) => {
  const cabinetsOptions = useMemo(
    () =>
      cabinets?.map((cabinet) => ({
        label: cabinet?.name,
        value: cabinet?.cabinet_id
      })),
    [cabinets]
  );
  const licenseTypesOptions = useMemo(
    () =>
      licenseTypes?.map((licenseType) => ({
        label: licenseType?.name,
        value: licenseType?.license_type_id
      })),
    [licenseTypes]
  );

  const initialValues: TLicenseFormValues | undefined = useMemo(
    () =>
      license
        ? {
            ...license,
            start_date: dayjs(license?.start_date, ISO_DATE),
            due_date: dayjs(license?.due_date, ISO_DATE)
          }
        : undefined,
    [license]
  );

  const onSubmitHandler = useCallback(
    (license: TLicenseFormValues) => {
      if (license) {
        onSubmit({
          ...license,
          start_date: toSendDate(license?.start_date) as string,
          due_date: toSendDate(license?.due_date) as string
        });
      }
    },
    [onSubmit]
  );

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmitHandler}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={initialValues}
      >
        <Form.Item<TLicenseFormValues>
          label="Кабинет"
          name="cabinet_id"
          rules={[
            {
              required: true,
              message: "Выберите кабинет"
            }
          ]}
        >
          <Select
            options={cabinetsOptions}
            disabled={!cabinetsOptions?.length}
          />
        </Form.Item>

        <Form.Item<TLicenseFormValues>
          label="Тип лицензии"
          name="license_type_id"
          rules={[
            {
              required: true,
              message: "Выберите тип лицензии"
            }
          ]}
        >
          <Select
            options={licenseTypesOptions}
            disabled={!licenseTypesOptions?.length}
          />
        </Form.Item>

        <Form.Item<TLicenseFormValues>
          label="Дата начала лицензии"
          name="start_date"
          rules={[
            {
              required: true,
              message: "Выберите дату начала лицензии"
            }
          ]}
        >
          <DatePicker format={DATE_FORMAT} minDate={dayjs()} />
        </Form.Item>
        <Form.Item<TLicenseFormValues>
          label="Дата окончания лицензии"
          name="due_date"
          rules={[
            {
              required: true,
              message: "Выберите дату окончания лицензии"
            }
          ]}
        >
          <DatePicker format={DATE_FORMAT} minDate={dayjs()} />
        </Form.Item>

        <Form.Item<TLicenseFormValues> label="Комментарий" name="comment">
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
