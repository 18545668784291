import { createAsyncThunk } from "@reduxjs/toolkit";
import { offerForSellerAddActions } from "./add";
import { offerForSellerByIdActions } from "./byId";
import { offersForSellerGetActions } from "./get";
import { offerForSellerUpdateActions } from "./update";
import { offerForSellerUpdateStatusActions } from "./update_status";
import { offersForSellerByApplicationIdActions } from "./byApplicationId";

export const clearOffersForSeller = createAsyncThunk<void, void>(
  "offersForSeller/clearOffersForSeller",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(offerForSellerAddActions.clearState());
    dispatch(offerForSellerByIdActions.clearState());
    dispatch(offersForSellerGetActions.clearState());
    dispatch(offerForSellerUpdateActions.clearState());
    dispatch(offerForSellerUpdateStatusActions.clearState());
    dispatch(offersForSellerByApplicationIdActions.clearState());
  }
);
