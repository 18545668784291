import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";

export const TableScrollWrapper = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1;
`;
export const TableWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  @media ${DEVICE.LG} {
    gap: 16px;
  }
`;
export const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
