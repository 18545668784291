import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const sendChatMessage = (
  data: TSendChatMessageRequest
): RequestPromise<TSendChatMessageResponse> => {
  return apiRequest.post(`/chat/send`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TSendChatMessageRequest = {
  chatId: number;
  message: string;
};

export type TSendChatMessageResponse = void;
