import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";

export const CTABlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 240px;
  align-self: flex-end;

  @media ${DEVICE.LG} {
    width: 320px;
  }

  @media ${DEVICE.SM} {
    width: 100%;
  }
`;
