import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatsByApplicationIdActions } from ".";
import * as api from "api/chat";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getChatsByApplicationId = createAsyncThunk<
  api.TGetChatsByApplicationIdResponse,
  api.TGetChatsByApplicationIdRequest
>(
  "chat/getChatsByApplicationId",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(chatsByApplicationIdActions.setLoading(true));
    dispatch(chatsByApplicationIdActions.setError(null));
    return api
      .getChatsByApplicationId(payload)
      .then(({ data }) => {
        dispatch(chatsByApplicationIdActions.setChats(data));
        dispatch(chatsByApplicationIdActions.setSuccess(true));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(chatsByApplicationIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(chatsByApplicationIdActions.setLoading(false));
      });
  }
);
