import { combineReducers } from "@reduxjs/toolkit";
import { fileAddReducer } from "./add";
import { filesByObjectReducer } from "./byObject";
import { fileDeleteReducer } from "./delete";
import { fileByIdReducer } from "./byId";

export const filesReducer = combineReducers({
  add: fileAddReducer,
  byObject: filesByObjectReducer,
  delete: fileDeleteReducer,
  byId: fileByIdReducer
});
