import React, { FC, useMemo } from "react";
import { Button, Pagination, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { DEVICE } from "constants/breakpoints";
import {
  ApprovementFooter,
  ApprovementHeader,
  ApprovementItem,
  ApprovementsMobileContainer
} from "./ApprovementsTable.styles";
import { Empty } from "../Empty/Empty";
import { Divider } from "../Divider/Divider";

export type TShortenedApprovement = {
  name?: string;
  approvement_id: number;
  type?: "applications" | "offers";
  parent_id: string;
};

type TApprovementsTableProps = {
  approvements?: TShortenedApprovement[];
  onUpdate: (approvement_id: number, approvement_status_id: number) => void;
  isLoading: boolean;
  okCode: number;
  cancelCode: number;
  pagination?: TablePaginationConfig | false;
};

export const ApprovementsTable: FC<TApprovementsTableProps> = ({
  approvements,
  onUpdate,
  isLoading,
  okCode,
  cancelCode,
  pagination
}) => {
  const isLG = useMediaQuery({ query: DEVICE.LG });

  const columns: ColumnsType<TShortenedApprovement> = useMemo(
    () => [
      {
        title: "Наименование",
        key: "approvement_name",
        render: (value: TShortenedApprovement) => (
          <Link to={`../../${value?.type}/${value?.parent_id}`}>
            {value?.name}
          </Link>
        )
      },
      {
        key: "approve",
        render: (value: TShortenedApprovement) => (
          <Button
            onClick={() => onUpdate(value?.approvement_id, okCode)}
            loading={isLoading}
          >
            Согласовать
          </Button>
        )
      },
      {
        key: "cancel",
        render: (value: TShortenedApprovement) => (
          <Button
            onClick={() => onUpdate(value?.approvement_id, cancelCode)}
            loading={isLoading}
            danger
          >
            Отклонить
          </Button>
        )
      }
    ],
    [cancelCode, isLoading, okCode, onUpdate]
  );

  return (
    <ApprovementsMobileContainer>
      {approvements?.length ? (
        isLG ? (
          approvements?.map((approvement) => (
            <ApprovementItem>
              <ApprovementHeader>
                <Link
                  to={`../../${approvement?.type}/${approvement?.parent_id}`}
                >
                  {approvement?.name}
                </Link>
              </ApprovementHeader>
              <Divider />
              <ApprovementFooter>
                <Button
                  onClick={() => onUpdate(approvement?.approvement_id, okCode)}
                  loading={isLoading}
                  style={{ width: "100%" }}
                >
                  Согласовать
                </Button>

                <Button
                  onClick={() =>
                    onUpdate(approvement?.approvement_id, cancelCode)
                  }
                  loading={isLoading}
                  danger
                  style={{ width: "100%" }}
                >
                  Отклонить
                </Button>
              </ApprovementFooter>
            </ApprovementItem>
          ))
        ) : (
          <>
            <Table
              dataSource={approvements}
              columns={columns}
              pagination={false}
              showHeader={false}
            />
            {pagination && <Pagination {...pagination} align="end" />}
          </>
        )
      ) : (
        <Empty
          description="Пока нет ни одного согласования"
          style={{ flex: 1 }}
        />
      )}
    </ApprovementsMobileContainer>
  );
};
