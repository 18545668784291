import { combineReducers } from "@reduxjs/toolkit";
import { colleaguesByCabinetReducer } from "./byCabinet";
import { colleagueAddReducer } from "./add";
import { colleagueUpdateReducer } from "./update";
import { colleagueDeleteReducer } from "./delete";

export const colleaguesReducer = combineReducers({
  byCabintet: colleaguesByCabinetReducer,
  add: colleagueAddReducer,
  update: colleagueUpdateReducer,
  delete: colleagueDeleteReducer
});
