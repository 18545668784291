import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApprovementUpdateState } from "./types";

import { RequestError, TApplicationApprovement } from "types";

const initialState: TApprovementUpdateState = {
  isLoading: false,
  error: null,
  approvement: undefined
};

export const ApprovementUpdateSlice = createSlice({
  name: "approvement/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApprovement: (
      state,
      { payload }: PayloadAction<TApplicationApprovement>
    ) => {
      state.approvement = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const approvementUpdateActions = ApprovementUpdateSlice.actions;
export const approvementUpdateReducer = ApprovementUpdateSlice.reducer;

export * as approvementUpdateSelectors from "./selectors";
