import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TCabinetDeleteState } from "./types";

import { RequestError, TCabinet } from "types";

const initialState: TCabinetDeleteState = {
  isLoading: false,
  error: null,
  cabinet: undefined
};

export const CabinetDeleteSlice = createSlice({
  name: "cabinet/delete",
  initialState,
  reducers: {
    clearState: () => initialState,
    setCabinet: (state, { payload }: PayloadAction<TCabinet>) => {
      state.cabinet = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const cabinetDeleteActions = CabinetDeleteSlice.actions;
export const cabinetDeleteReducer = CabinetDeleteSlice.reducer;

export * as cabinetDeleteSelectors from "./selectors";
