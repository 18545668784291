import { TPerson } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateUser = (
  data: TUpdateUserRequest
): RequestPromise<TUpdateUserResponse> => {
  return apiRequest.post(`/users/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateUserRequest = TPerson;

export type TUpdateUserResponse = TPerson;
