import React, { FC, useCallback, useMemo } from "react";

import { DatePicker, Form, Input, Select, Typography } from "antd";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT, ISO_DATE_TIME } from "constants/dates";
import { toDisplayDate, toSendDateTime } from "utils/dates";
import {
  Divider,
  TOfferEditFormTabProps,
  TOfferFormValues,
  TypographyTitleNoMargin
} from "components/common/redesign";
import { CTABlock, NavButton } from "../../OfferEditForm.styles";

export const GeneralTab: FC<TOfferEditFormTabProps> = ({
  formData,
  application,
  entitiesForOffer,
  accounts,
  onSubmitStep
}) => {
  const [form] = Form.useForm<TOfferFormValues>();

  const sellerEntity = Form.useWatch("seller_entity", form);

  const entitiesOptions = useMemo(
    () =>
      (entitiesForOffer || [])?.map((entity) => ({
        label: entity?.full_name,
        value: entity?.entity_id
      })),
    [entitiesForOffer]
  );

  const accountsOptions = useMemo(
    () =>
      (accounts || [])
        .filter((account) => account?.entity_id === sellerEntity)
        .map((account) => ({
          label: account?.name,
          value: account?.account_id
        })),
    [accounts, sellerEntity]
  );

  const initialValues: TOfferFormValues = useMemo(() => {
    const { delivary_plan_dt, seller_entity, offer_comment, seller_account } =
      formData;

    return {
      delivary_plan_dt: delivary_plan_dt
        ? dayjs(delivary_plan_dt, ISO_DATE_TIME)
        : undefined,
      seller_entity,
      offer_comment,
      seller_account
    };
  }, [formData]);

  const onSubmitHandler = useCallback(
    (offer: TOfferFormValues) => {
      const { delivary_plan_dt, seller_entity, offer_comment, seller_account } =
        offer;
      onSubmitStep({
        delivary_plan_dt: toSendDateTime(delivary_plan_dt),
        seller_entity,
        offer_comment,
        seller_account
      });
    },
    [onSubmitStep]
  );

  const onEntityChange = useCallback(() => {
    form.setFieldValue("seller_account", undefined);
  }, [form]);

  return (
    <>
      <Form
        name="offer_edit_general"
        form={form}
        layout="vertical"
        requiredMark={false}
        style={{ maxWidth: "400px" }}
        onFinish={onSubmitHandler}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.Item>
          <TypographyTitleNoMargin level={5}>Поставка</TypographyTitleNoMargin>
        </Form.Item>

        <Form.Item label="Желаемая дата поставки">
          <Typography.Text>
            {toDisplayDate(application?.application_delivery_due_datetime)}
          </Typography.Text>
        </Form.Item>

        <Form.Item<TOfferFormValues>
          label="Дата поставки"
          name="delivary_plan_dt"
        >
          <DatePicker format={DATE_TIME_FORMAT} showTime minDate={dayjs()} />
        </Form.Item>

        <Form.Item<TOfferFormValues>
          label="Юр. лицо"
          name="seller_entity"
          rules={[
            {
              required: true,
              message: "Выберите юр. лицо"
            }
          ]}
        >
          <Select
            options={entitiesOptions}
            disabled={!entitiesOptions?.length}
            onChange={onEntityChange}
          />
        </Form.Item>

        <Form.Item<TOfferFormValues>
          label="Комментарий для покупателя"
          name="offer_comment"
        >
          <Input />
        </Form.Item>

        <Divider />

        <Form.Item>
          <TypographyTitleNoMargin level={5}>Оплата</TypographyTitleNoMargin>
        </Form.Item>

        <Form.Item<TOfferFormValues>
          label="Счет"
          name="seller_account"
          rules={[
            {
              required: true,
              message: "Выберите счет"
            }
          ]}
        >
          <Select
            options={accountsOptions}
            disabled={!accountsOptions?.length}
          />
        </Form.Item>
      </Form>

      <CTABlock>
        <NavButton type="primary" form="offer_edit_general" htmlType="submit">
          Далее
        </NavButton>
      </CTABlock>
    </>
  );
};
