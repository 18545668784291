import { createAsyncThunk } from "@reduxjs/toolkit";
import { licenseDeleteActions } from ".";
import * as api from "api/license";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const deleteLicense = createAsyncThunk<
  api.TDeleteLicenseResponse,
  api.TDeleteLicenseRequest
>("license/deleteLicense", (payload, { dispatch, rejectWithValue }) => {
  dispatch(licenseDeleteActions.setLoading(true));
  dispatch(licenseDeleteActions.setError(null));
  return api
    .deleteLicense(payload)
    .then(({ data }) => {
      dispatch(licenseDeleteActions.setLicense(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(licenseDeleteActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(licenseDeleteActions.setLoading(false));
    });
});
