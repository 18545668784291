import React, { FC, useMemo } from "react";
import { TAccount } from "types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

type TAccountsTableProps = {
  accounts?: TAccount[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  isDeleting?: boolean;
};

export const AccountsTable: FC<TAccountsTableProps> = ({
  accounts,
  onDelete,
  onEdit,
  isDeleting
}) => {
  const columns: ColumnsType<TAccount> = useMemo(
    () => [
      {
        title: "Название счета",
        dataIndex: "name"
      },
      {
        title: "Банк",
        dataIndex: "bank_name"
      },
      {
        title: "Расчетный счет",
        dataIndex: "number"
      },
      {
        key: "edit",
        render: (value: TAccount) => (
          <Button
            onClick={() => onEdit?.(value?.entity_id)}
            icon={<EditOutlined />}
          >
            Редактировать
          </Button>
        )
      },
      {
        key: "delete",
        render: (value: TAccount) => (
          <Button
            onClick={() => onDelete?.(value?.entity_id)}
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
          >
            Удалить
          </Button>
        )
      }
    ],
    [isDeleting, onDelete, onEdit]
  );

  return (
    <Table
      dataSource={accounts}
      columns={columns}
      pagination={false}
      scroll={{ x: "1200px" }}
    />
  );
};
