import { TAccount } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addAccount = (
  data: TAddAccountRequest
): RequestPromise<TAddAccountResponse> => {
  return apiRequest.post(`/accounts/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TAddAccountRequest = Omit<TAccount, "account_id">;

export type TAddAccountResponse = TAccount;
