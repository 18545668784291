import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  overflow: hidden;

  @media ${DEVICE.LG} {
    gap: 16px;
  }
`;

export const ChatsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
`;
