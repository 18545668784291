import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { TEntity } from "types";
import { Wrapper } from "./EntitiesTab.styles";
import { Button, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { CompaniesTable, CompanyEditForm } from "components/common/old";
import {
  entitiesByCabinetIdActions,
  entitiesByCabinetIdSelectors
} from "store/entities/byCabinetId";
import { entityAddActions, entityAddSelectors } from "store/entities/add";
import {
  entityUpdateActions,
  entityUpdateSelectors
} from "store/entities/update";
import {
  entityDeleteActions,
  entityDeleteSelectors
} from "store/entities/delete";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import { addEntity } from "store/entities/add/thunk";
import { updateEntity } from "store/entities/update/thunk";
import { deleteEntity } from "store/entities/delete/thunk";
import { adminActions, adminSelectors } from "store/admin";

export const EntitiesTab = () => {
  const dispatch = useAppDispatch();

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    entityAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    entityUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    entityDeleteSelectors.getState
  );
  const { selectedCabinet } = useAppSelector(adminSelectors.getState);

  const [isAdding, setIsAdding] = useState(false);
  const [entityInEdit, setEntityInEdit] = useState<TEntity | undefined>(
    undefined
  );

  const cabinetsOptions = useMemo(
    () =>
      cabinets?.map((cabinet) => ({
        label: cabinet?.name,
        value: cabinet?.cabinet_id
      })),
    [cabinets]
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const getEntities = useCallback(
    (cabinetId: number) => {
      dispatch(getEntitiesByCabinetId(cabinetId));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const entity = entities?.find((entity) => entity?.entity_id === id);
      setEntityInEdit(entity);
    },
    [entities]
  );
  const onCancelEdit = useCallback(() => {
    setEntityInEdit(undefined);
  }, []);

  const onAddEntity = useCallback(
    (values: TEntity) => {
      selectedCabinet &&
        dispatch(addEntity({ ...values, cabinet_id: selectedCabinet }))
          .unwrap()
          .then(() => {
            onCancelAdd();
            selectedCabinet && getEntities(selectedCabinet);
          });
    },
    [dispatch, getEntities, onCancelAdd, selectedCabinet]
  );
  const onEditEntity = useCallback(
    (values: TEntity) => {
      dispatch(updateEntity({ ...entityInEdit, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          selectedCabinet && getEntities(selectedCabinet);
        });
    },
    [dispatch, entityInEdit, getEntities, onCancelEdit, selectedCabinet]
  );
  const onDeleteEntity = useCallback(
    (id: number) => {
      dispatch(deleteEntity(id))
        .unwrap()
        .then(() => {
          selectedCabinet && getEntities(selectedCabinet);
        });
    },
    [dispatch, getEntities, selectedCabinet]
  );
  const onSelectCabinet = useCallback(
    (value: number | undefined) => {
      dispatch(adminActions.setSelectedCabinet(value));
    },
    [dispatch]
  );

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  useEffect(() => {
    selectedCabinet && getEntities(selectedCabinet);
  }, [dispatch, getEntities, selectedCabinet]);

  useEffect(() => {
    return () => {
      dispatch(entityAddActions.clearState());
      dispatch(entityUpdateActions.clearState());
      dispatch(entityDeleteActions.clearState());
      dispatch(entitiesByCabinetIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Select
        placeholder="Выберите кабинет"
        options={cabinetsOptions}
        onChange={onSelectCabinet}
        value={selectedCabinet}
        style={{ maxWidth: 300 }}
      />
      {selectedCabinet && (
        <>
          {!entityInEdit && (
            <Button
              icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
              onClick={isAdding ? onCancelAdd : onAdd}
            >
              {isAdding ? "Отмена" : "Добавить"}
            </Button>
          )}

          {entityInEdit ? (
            <>
              <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
                Отмена
              </Button>
              <CompanyEditForm
                entity={entityInEdit}
                onSubmit={onEditEntity}
                isLoading={updateIsLoading}
                error={updateError}
              />
            </>
          ) : isAdding ? (
            <CompanyEditForm
              onSubmit={onAddEntity}
              isLoading={addIsLoading}
              error={addError}
            />
          ) : (
            <CompaniesTable
              entities={entities}
              onEdit={onEdit}
              onDelete={onDeleteEntity}
              isDeleting={deleteIsLoading}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};
