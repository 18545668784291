import { combineReducers } from "@reduxjs/toolkit";
import { entityAddReducer } from "./add";
import { entityUpdateReducer } from "./update";
import { entityDeleteReducer } from "./delete";
import { entitiesByCabinetIdReducer } from "./byCabinetId";
import { entityByIdReducer } from "./byId";

export const entitiesReducer = combineReducers({
  add: entityAddReducer,
  update: entityUpdateReducer,
  delete: entityDeleteReducer,
  byCabinetId: entitiesByCabinetIdReducer,
  byId: entityByIdReducer
});
