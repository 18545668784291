import React, { FC, useMemo } from "react";
import { TOfferPosition } from "types";
import Table, { ColumnsType } from "antd/es/table";
import {
  applicationPositionCategoryIdMapper,
  applicationPositionUnitTypeIdMapper
} from "utils/mappers";
import { TableWrapper, TableScrollWrapper } from "./OfferPositionsTable.styles";
import { Empty, TypographyTitleNoMargin } from "components/common/redesign";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { padFractionalZeros, splitThousands } from "utils/numbers";
import { countPrice } from "utils/countPrice";
import { countOfferPositionsPrice } from "utils/countOfferPositionsPrice";

type TOfferPositionsTableProps = {
  positions?: TOfferPosition[];
  onStepEdit?: (step: number) => void;
};

export const OfferPositionsTable: FC<TOfferPositionsTableProps> = ({
  positions,
  onStepEdit
}) => {
  const { price, priceVAT } = useMemo(
    () => countOfferPositionsPrice(positions),
    [positions]
  );

  const positionsColumns: ColumnsType<TOfferPosition> = useMemo(
    () => [
      {
        title: "№",
        key: "index",
        render: (value, record, index) => index + 1,
        minWidth: 64
      },
      {
        title: "Товарная категория",
        dataIndex: "category_id",
        render: (value: number) =>
          applicationPositionCategoryIdMapper[value] || value,
        minWidth: 250
      },
      {
        title: "Наименование",
        dataIndex: "position_name",
        minWidth: 250
      },
      {
        title: "Аналог",
        dataIndex: "analogue_flag",
        render: (value: boolean) => (value ? "Да" : "Нет"),
        minWidth: 90
      },
      {
        title: "Кол-во",
        key: "unit_amount",
        render: (value: TOfferPosition) =>
          [
            value?.unit_amount?.toString()?.replaceAll(".", ","),
            applicationPositionUnitTypeIdMapper[value?.unit_type_id]
              ?.shortName || value?.unit_type_id
          ].join(" "),
        minWidth: 90
      },
      {
        title: "Цена за единицу",
        dataIndex: "unit_price",
        render: (value: number) =>
          `${splitThousands(padFractionalZeros(value))} руб.`,
        minWidth: 150
      },
      {
        title: "Скидка",
        dataIndex: "discount",
        render: (value: number) => `${value}% `,
        minWidth: 90
      },
      {
        title: "Комментарий",
        dataIndex: "position_comment",
        minWidth: 300
      },
      {
        title: "НДС",
        dataIndex: "vat",
        render: (value: number) => `${value}%`,
        minWidth: 70
      },
      {
        title: "Цена",
        key: "total_price",
        render: ({ unit_amount, unit_price, discount, vat }: TOfferPosition) =>
          `${splitThousands(
            padFractionalZeros(
              countPrice(unit_amount, unit_price, discount, vat)
            )
          )} руб.`,
        minWidth: 150
      }
    ],
    []
  );
  return (
    <TableWrapper>
      <TypographyTitleNoMargin level={5}>
        {onStepEdit && (
          <Button icon={<EditOutlined onClick={() => onStepEdit?.(1)} />} />
        )}
        Позиции предложения
      </TypographyTitleNoMargin>

      {positions?.length ? (
        <TableScrollWrapper>
          <Table
            dataSource={positions}
            columns={positionsColumns}
            pagination={false}
            bordered
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    Итоговая стоимость без учета НДС
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    colSpan={positionsColumns?.length - 2}
                  >
                    {splitThousands(padFractionalZeros(price))} руб.
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={2} colSpan={2}>
                    Итоговая стоимость с учетом НДС
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    colSpan={positionsColumns?.length - 2}
                  >
                    {splitThousands(padFractionalZeros(priceVAT))} руб.
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </TableScrollWrapper>
      ) : (
        <Empty description="Пока нет ни одной позиции" />
      )}
    </TableWrapper>
  );
};
