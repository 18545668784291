import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";

export const ProfileBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media ${DEVICE.LG} {
    gap: 16px;
  }
  @media ${DEVICE.SM} {
    flex-direction: column;
  }
`;
