import { TAccount } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getAccountsByEntityId = (
  id: TByEntityIdAccountRequest
): RequestPromise<TByEntityIdAccountResponse> => {
  return apiRequest.get(`/accounts/entity/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TByEntityIdAccountRequest = number;

export type TByEntityIdAccountResponse = TAccount[];
