import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLicenseTypesGetState } from "./types";

import { RequestError, TLicenseType } from "types";

const initialState: TLicenseTypesGetState = {
  isLoading: false,
  error: null,
  licenseTypes: undefined
};

export const LicenseTypesGetSlice = createSlice({
  name: "license/type/get",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicenseTypes: (state, { payload }: PayloadAction<TLicenseType[]>) => {
      state.licenseTypes = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const licenseTypesGetActions = LicenseTypesGetSlice.actions;
export const licenseTypesGetReducer = LicenseTypesGetSlice.reducer;

export * as licenseTypesGetSelectors from "./selectors";
