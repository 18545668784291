import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TFileByIdState } from "./types";

import { RequestError } from "types";

const initialState: TFileByIdState = {
  isLoading: false,
  error: null,
  file: undefined
};

export const FileByIdSlice = createSlice({
  name: "files/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setFile: (state, { payload }: PayloadAction<Blob>) => {
      state.file = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const fileByIdActions = FileByIdSlice.actions;
export const fileByIdReducer = FileByIdSlice.reducer;

export * as fileByIdSelectors from "./selectors";
