import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getFileById = (
  id: TGetFileByIdRequest
): RequestPromise<TGetFileByIdResponse> => {
  return apiRequest.get(`/files/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    },
    responseType: "blob"
  });
};

export type TGetFileByIdRequest = number; //file_meta_id

export type TGetFileByIdResponse = Blob;
