import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationUpdateState } from "./types";

import { RequestError, TApplication } from "types";

const initialState: TApplicationUpdateState = {
  isLoading: false,
  error: null,
  application: undefined
};

export const ApplicationUpdateSlice = createSlice({
  name: "application/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApplication: (state, { payload }: PayloadAction<TApplication>) => {
      state.application = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const applicationUpdateActions = ApplicationUpdateSlice.actions;
export const applicationUpdateReducer = ApplicationUpdateSlice.reducer;

export * as applicationUpdateSelectors from "./selectors";
