import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const MessageBox = styled.div<{
  $isIncoming: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  border-radius: 8px;
  max-width: 80%;
  background: #ebf2ff;

  ${({ $isIncoming }) =>
    $isIncoming &&
    css`
      background: rgba(5, 5, 5, 0.06);
      align-self: flex-start;
    `}
`;
