import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationUpdateActions } from ".";
import * as api from "api/applications";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const updateApplication = createAsyncThunk<
  api.TUpdateApplicationResponse,
  api.TUpdateApplicationRequest
>("application/updateApplication", (payload, { dispatch, rejectWithValue }) => {
  dispatch(applicationUpdateActions.setLoading(true));
  dispatch(applicationUpdateActions.setError(null));
  return api
    .updateApplication(payload)
    .then(({ data }) => {
      dispatch(applicationUpdateActions.setApplication(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(applicationUpdateActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(applicationUpdateActions.setLoading(false));
    });
});
