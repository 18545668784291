import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAdminState } from "./types";

const initialState: TAdminState = {
  isAdminSeller: false,
  selectedCabinet: undefined
};

export const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    clearState: () => initialState,
    setIsAdminSeller: (state, { payload }: PayloadAction<boolean>) => {
      state.isAdminSeller = payload;
    },
    setSelectedCabinet: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.selectedCabinet = payload;
    }
  }
});

export const adminActions = AdminSlice.actions;
export const adminReducer = AdminSlice.reducer;

export * as adminSelectors from "./selectors";
