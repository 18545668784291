import React, { FC, ReactNode, useMemo } from "react";
import { TApplicationPosition } from "types";
import Table, { ColumnsType } from "antd/es/table";
import {
  applicationPositionCategoryIdMapper,
  applicationPositionUnitTypeIdMapper
} from "utils/mappers";
import {
  TableWrapper,
  TableScrollWrapper
} from "./ApplicationPositionsTable.styles";
import { Empty, TypographyTitleNoMargin } from "components/common/redesign";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";

type TApplicationPositionsTableProps = {
  positions?: TApplicationPosition[];
  onStepEdit?: (step: number) => void;
  icon?: ReactNode;
  onIconClick?: (position: TApplicationPosition) => void;
};

export const ApplicationPositionsTable: FC<TApplicationPositionsTableProps> = ({
  positions,
  onStepEdit,
  icon,
  onIconClick
}) => {
  const positionsColumns: ColumnsType<TApplicationPosition> = useMemo(
    () => [
      {
        title: "№",
        key: "index",
        render: (value, record, index) => index + 1,
        minWidth: 64
      },
      {
        title: "Товарная категория",
        dataIndex: "category_id",
        render: (value: number) =>
          applicationPositionCategoryIdMapper[value] || value,
        minWidth: 288
      },
      {
        title: "Наименование",
        dataIndex: "position_name",
        minWidth: 296
      },
      {
        title: "Кол-во",
        key: "unit_amount",
        render: (value: TApplicationPosition) =>
          [
            value?.unit_amount?.toString()?.replaceAll(".", ","),
            applicationPositionUnitTypeIdMapper[value?.unit_type_id]
              ?.shortName || value?.unit_type_id
          ].join(" "),
        minWidth: 136
      },
      {
        title: "Комментарий",
        dataIndex: "position_comment",
        minWidth: 165
      },
      ...(icon
        ? [
            {
              title: "",
              key: "icon",
              render: (value: TApplicationPosition) => (
                <Button icon={icon} onClick={() => onIconClick?.(value)} />
              ),
              minWidth: 64
            }
          ]
        : [])
    ],
    [icon, onIconClick]
  );
  return (
    <TableWrapper>
      <TypographyTitleNoMargin level={5}>
        {onStepEdit && (
          <Button icon={<EditOutlined onClick={() => onStepEdit?.(1)} />} />
        )}
        Позиции заявки
      </TypographyTitleNoMargin>

      {positions?.length ? (
        <TableScrollWrapper>
          <Table
            dataSource={positions}
            columns={positionsColumns}
            pagination={false}
            bordered
          />
        </TableScrollWrapper>
      ) : (
        <Empty description="Пока нет ни одной позиции" />
      )}
    </TableWrapper>
  );
};
