import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TProjectAddState } from "./types";

import { RequestError, TProject } from "types";

const initialState: TProjectAddState = {
  isLoading: false,
  error: null,
  project: undefined
};

export const ProjectAddSlice = createSlice({
  name: "project/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setProject: (state, { payload }: PayloadAction<TProject>) => {
      state.project = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const projectAddActions = ProjectAddSlice.actions;
export const projectAddReducer = ProjectAddSlice.reducer;

export * as projectAddSelectors from "./selectors";
