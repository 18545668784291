import styled from "@emotion/styled";

export const ApprovementsMobileContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;
export const ApprovementItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ApprovementHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
`;
export const ApprovementFooter = styled.div`
  flex: 1;
  display: flex;
  gap: 16px;
  width: 100%;
`;
