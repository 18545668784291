import { TFileMeta } from "types";

export const extansionIdMapper: Record<
  TFileMeta["file_extension"],
  { extension: string; type: string }
> = {
  136: {
    extension: "xlsx",
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  },
  137: {
    extension: "docx",
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  },
  138: { extension: "doc", type: "application/msword" },
  139: { extension: "xls", type: "application/vnd.ms-excel" },
  140: { extension: "pdf", type: "application/pdf" },
  141: { extension: "png", type: "image/png" },
  142: { extension: "jpeg", type: "image/jpeg" }
};
