import { createAsyncThunk } from "@reduxjs/toolkit";
import { cabinetAddActions } from ".";
import * as api from "api/cabinets";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const addCabinet = createAsyncThunk<
  api.TAddCabinetResponse,
  api.TAddCabinetRequest
>("cabinet/addCabinet", (payload, { dispatch, rejectWithValue }) => {
  dispatch(cabinetAddActions.setLoading(true));
  dispatch(cabinetAddActions.setError(null));
  return api
    .addCabinet(payload)
    .then(({ data }) => {
      dispatch(cabinetAddActions.setCabinet(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(cabinetAddActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(cabinetAddActions.setLoading(false));
    });
});
