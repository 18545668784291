import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import { TLicense } from "types";
import { Wrapper } from "./LicensesTab.styles";
import { LicenseEditForm, LicensesTable } from "components/common/old";
import { Button } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import { licensesGetSelectors } from "store/licenses/get";
import { licenseAddActions, licenseAddSelectors } from "store/licenses/add";
import {
  licenseUpdateActions,
  licenseUpdateSelectors
} from "store/licenses/update";
import {
  licenseDeleteActions,
  licenseDeleteSelectors
} from "store/licenses/delete";
import { getLicenses } from "store/licenses/get/thunk";
import { addLicense } from "store/licenses/add/thunk";
import { updateLicense } from "store/licenses/update/thunk";
import { deleteLicense } from "store/licenses/delete/thunk";
import { getLicenseTypes } from "store/licenses/type/get/thunk";
import { licenseTypesGetSelectors } from "store/licenses/type/get";

export const LicensesTab = () => {
  const dispatch = useAppDispatch();

  const { licenses } = useAppSelector(licensesGetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    licenseAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    licenseUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    licenseDeleteSelectors.getState
  );

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { licenseTypes } = useAppSelector(licenseTypesGetSelectors.getState);

  const [isAdding, setIsAdding] = useState(false);
  const [licenseInEdit, setLicenseInEdit] = useState<TLicense | undefined>(
    undefined
  );

  const getLicensesArr = useCallback(() => {
    dispatch(getLicenses());
  }, [dispatch]);

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const license = licenses?.find((license) => license?.license_id === id);
      setLicenseInEdit(license);
    },
    [licenses]
  );
  const onCancelEdit = useCallback(() => {
    setLicenseInEdit(undefined);
  }, []);

  const onAddLicense = useCallback(
    (values: TLicense) => {
      dispatch(addLicense(values))
        .unwrap()
        .then(() => {
          onCancelAdd();
          getLicensesArr();
        });
    },
    [dispatch, getLicensesArr, onCancelAdd]
  );
  const onEditLicense = useCallback(
    (values: TLicense) => {
      dispatch(updateLicense({ ...licenseInEdit, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          getLicensesArr();
        });
    },
    [dispatch, getLicensesArr, licenseInEdit, onCancelEdit]
  );
  const onDeleteLicense = useCallback(
    (id: number) => {
      dispatch(deleteLicense(id))
        .unwrap()
        .then(() => {
          getLicensesArr();
        });
    },
    [dispatch, getLicensesArr]
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const getLicenseTypesArr = useCallback(() => {
    dispatch(getLicenseTypes());
  }, [dispatch]);

  useEffect(() => {
    !licenses && getLicensesArr();
  }, [getLicensesArr, licenses]);

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  useEffect(() => {
    !licenseTypes && getLicenseTypesArr();
  }, [getLicenseTypesArr, licenseTypes]);

  useEffect(() => {
    return () => {
      dispatch(licenseAddActions.clearState());
      dispatch(licenseUpdateActions.clearState());
      dispatch(licenseDeleteActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {!licenseInEdit && (
        <Button
          icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAdding ? onCancelAdd : onAdd}
        >
          {isAdding ? "Отмена" : "Добавить"}
        </Button>
      )}

      {licenseInEdit ? (
        <>
          <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
            Отмена
          </Button>
          <LicenseEditForm
            license={licenseInEdit}
            licenseTypes={licenseTypes}
            cabinets={cabinets}
            onSubmit={onEditLicense}
            isLoading={updateIsLoading}
            error={updateError}
          />
        </>
      ) : isAdding ? (
        <LicenseEditForm
          licenseTypes={licenseTypes}
          cabinets={cabinets}
          onSubmit={onAddLicense}
          isLoading={addIsLoading}
          error={addError}
        />
      ) : (
        <LicensesTable
          licenses={licenses}
          licenseTypes={licenseTypes}
          cabinets={cabinets}
          onEdit={onEdit}
          onDelete={onDeleteLicense}
          isDeleting={deleteIsLoading}
        />
      )}
    </Wrapper>
  );
};
