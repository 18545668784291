import { Avatar } from "antd";
import React, { ComponentProps, FC } from "react";
import { StyledAvatar } from "./ProfileAvatar.styles";

type TProfileAvatarProps = ComponentProps<typeof Avatar> & {
  isSeller: boolean;
};

export const ProfileAvatar: FC<TProfileAvatarProps> = ({
  isSeller,
  ...props
}) => {
  return <StyledAvatar $isSeller={isSeller} {...props} />;
};
