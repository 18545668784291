import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { ErrorAlert } from "components/common/old";
import React, { FC } from "react";
import { RequestError } from "types";

type TConfirmDeleteProps = {
  title?: string;
  subtitle?: string;
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  isLoading?: boolean;
  error?: RequestError | null;
};

export const ConfirmDelete: FC<TConfirmDeleteProps> = ({
  title,
  subtitle,
  open,
  onClose,
  onDelete,
  isLoading,
  error
}) => {
  return (
    <Modal
      open={open}
      title={title}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Отмена
        </Button>,
        <Button
          icon={<DeleteFilled />}
          danger
          type="primary"
          onClick={onDelete}
          loading={isLoading}
        >
          Удалить
        </Button>
      ]}
    >
      {subtitle}
      <ErrorAlert error={error} />
    </Modal>
  );
};
