import React, { useCallback, useEffect, useMemo } from "react";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { LinksBlock, Wrapper } from "./ApplicationsOffersTab.styles";
import { Button, Radio, RadioChangeEvent, Select } from "antd";
import { adminActions, adminSelectors } from "store/admin";
import { Link, Route, Routes } from "react-router-dom";
import {
  Application,
  ApplicationForSeller,
  Applications,
  ApplicationsForSeller,
  OfferForBuyer,
  OfferForSeller,
  OffersForBuyer,
  OffersForSeller
} from "components/pages/old";
import { clearCabinetData } from "store/admin/thunk";
import { Panel } from "components/common/old";
import { FileTextOutlined, InboxOutlined } from "@ant-design/icons";

export const ApplicationsOffersTab = () => {
  const dispatch = useAppDispatch();

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { selectedCabinet, isAdminSeller } = useAppSelector(
    adminSelectors.getState
  );

  const cabinetsOptions = useMemo(
    () =>
      cabinets?.map((cabinet) => ({
        label: cabinet?.name,
        value: cabinet?.cabinet_id
      })),
    [cabinets]
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const onSelectCabinet = useCallback(
    (value: number | undefined) => {
      dispatch(clearCabinetData());
      dispatch(adminActions.setSelectedCabinet(value));
    },
    [dispatch]
  );

  const onChangeIsSeller = useCallback(
    (e: RadioChangeEvent) => {
      dispatch(clearCabinetData());
      dispatch(adminActions.setIsAdminSeller(e?.target?.value));
    },
    [dispatch]
  );

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  return (
    <Wrapper>
      <Select
        placeholder="Выберите кабинет"
        options={cabinetsOptions}
        onChange={onSelectCabinet}
        value={selectedCabinet}
        style={{ maxWidth: 300 }}
      />
      <Panel
        style={{
          display: "flex",
          width: "100%",
          flex: 1,
          gap: "24px",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <LinksBlock>
          <Link to={"applications"}>
            <Button type="link" icon={<FileTextOutlined />}>
              Заявки
            </Button>
          </Link>
          <Link to={"offers"}>
            <Button type="link" icon={<InboxOutlined />}>
              Предложения
            </Button>
          </Link>
        </LinksBlock>
        <Radio.Group value={isAdminSeller} onChange={onChangeIsSeller}>
          <Radio value={true}>Поставщик</Radio>
          <Radio value={false}>Покупатель</Radio>
        </Radio.Group>
      </Panel>

      <Routes>
        <Route index element={null} />

        <Route path="applications">
          <Route index element={<Applications />} />
          <Route path=":application_id" element={<Application />} />
        </Route>

        <Route path="availableapplications">
          <Route index element={<ApplicationsForSeller />} />
          <Route path=":application_id" element={<ApplicationForSeller />} />
        </Route>

        <Route path="myoffers">
          <Route index element={<OffersForSeller />} />
          <Route path=":offer_id" element={<OfferForSeller />} />
        </Route>

        <Route path="offers">
          <Route index element={<OffersForBuyer />} />
          <Route path=":offer_id" element={<OfferForBuyer />} />
        </Route>

        <Route path="*" element={null} />
      </Routes>
    </Wrapper>
  );
};
