import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 24px;

  @media ${DEVICE.XXL} {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  @media ${DEVICE.LG} {
    gap: 8px;
  }
  @media ${DEVICE.MD} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${DEVICE.SM} {
    grid-template-columns: repeat(1, 1fr);
  }

  .ant-card {
    display: flex;
    flex-direction: column;
  }
  .ant-card-body {
    flex: 1;
  }
  .ant-card-actions {
    min-height: 56px;
    max-height: 56px;
  }
`;
