import React, { CSSProperties, FC, ReactNode } from "react";
import { theme } from "antd";

type TPanelProps = {
  children?: ReactNode;
  style?: CSSProperties;
  darkened?: boolean;
  onClick?: () => void;
  className?: string;
  isSmall?: boolean;
};

export const Panel: FC<TPanelProps> = ({
  children,
  style,
  darkened,
  onClick,
  className,
  isSmall
}) => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorBgLayout }
  } = theme.useToken();

  return (
    <div
      style={{
        padding: isSmall ? undefined : "24px",
        background: darkened ? colorBgLayout : colorBgContainer,
        borderRadius: borderRadiusLG,
        gap: "16px",
        cursor: onClick ? "pointer" : "unset",
        ...style
      }}
      className={className}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
