import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding-top: 8px;

  @media ${DEVICE.LG} {
    padding-top: 0;
    gap: 16px;
  }

  @media ${DEVICE.SM} {
    gap: 24px;
  }
`;
export const Header = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${DEVICE.SM} {
    flex-direction: column;
  }
`;
export const Footer = styled(Header)`
  flex-direction: row-reverse;
`;
