import { createAsyncThunk } from "@reduxjs/toolkit";
import { accountByCabinetIdActions } from "store/accounts/byCabinet";
import { colleaguesByCabinetActions } from "store/colleagues/byCabinet";
import { entitiesByCabinetIdActions } from "store/entities/byCabinetId";
import { projectsByCabinetActions } from "store/projects/byCabinet";

export const clearCabinetData = createAsyncThunk<void, void>(
  "admin/clearCabinetData",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(accountByCabinetIdActions.clearState());
    dispatch(colleaguesByCabinetActions.clearState());
    dispatch(entitiesByCabinetIdActions.clearState());
    dispatch(projectsByCabinetActions.clearState());
  }
);
