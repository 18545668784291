import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TColleagueUpdateState } from "./types";

import { RequestError, TPerson } from "types";

const initialState: TColleagueUpdateState = {
  isLoading: false,
  error: null,
  user: undefined
};

export const ColleagueUpdateSlice = createSlice({
  name: "colleagues/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setColleague: (state, { payload }: PayloadAction<TPerson>) => {
      state.user = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const colleagueUpdateActions = ColleagueUpdateSlice.actions;
export const colleagueUpdateReducer = ColleagueUpdateSlice.reducer;

export * as colleagueUpdateSelectors from "./selectors";
