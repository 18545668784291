import styled from "@emotion/styled";
import { Card, Typography } from "antd";
import { DEVICE } from "constants/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;

  @media ${DEVICE.XXL} {
    gap: 16px;
  }
  .ant-card-head-title {
    white-space: pre-line;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  flex: 1;

  @media ${DEVICE.XXL} {
    gap: 16px;
  }
`;

export const HeaderCard = styled(Card)`
  .ant-card-head {
    background: #f5f5f5;
  }
  .ant-card-body {
    display: none;
  }
`;
export const Title = styled(Typography.Title)`
  margin: 0 !important;
  font-weight: 500 !important;
`;
export const ContentCard = styled(Card)`
  .ant-card-head {
    padding: 16px 24px;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &::before,
    ::after {
      display: none;
    }
  }
`;
export const CalendarWrapper = styled.div`
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  height: fit-content;

  .ant-picker-calendar-header {
    background: #f5f5f5;
  }

  @media ${DEVICE.LG} {
    display: none;
  }
`;
