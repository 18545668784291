import { TChat } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export * from "./application";
export * from "./get";
export * from "./refresh";
export * from "./send";

export const getChats = (): RequestPromise<TGetChatsResponse> => {
  return apiRequest.get(`/chat`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetChatsRequest = void;

export type TGetChatsResponse = TChat[];
