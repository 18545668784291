import { Button, Form, FormProps, Input, Space } from "antd";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getChat } from "store/chat/get/thunk";
import { refreshChat } from "store/chat/refresh/thunk";
import { chatSendMessageSelectors } from "store/chat/send";
import { sendChatMessage } from "store/chat/send/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { ChatBox, chatFooterStyles, ChatHeader, Wrapper } from "./Chat.styles";
import { CloseOutlined, SendOutlined } from "@ant-design/icons";
import { Message } from "../Message/Message";
import {
  chatsByApplicationIdActions,
  chatsByApplicationIdSelectors
} from "store/chat/byApplicationId";
import { TypographyTitleNoMargin } from "components/common/redesign";
import { chatsActions, chatsStateSelectors } from "store/chat";

type TSendMessageForm = {
  message: string;
};

type TChatProps = {
  fromApplication?: boolean;
  forSeller?: boolean;
};

export const Chat: FC<TChatProps> = ({ fromApplication, forSeller }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm<TSendMessageForm>();
  const message = Form.useWatch("message", form);

  const { application_id_chat, seller_cabinet_id } = useParams<{
    application_id_chat: string;
    seller_cabinet_id: string;
  }>();

  const { chats, success } = useAppSelector(
    (fromApplication ? chatsByApplicationIdSelectors : chatsStateSelectors)
      .getState
  );
  const { isLoading: sendIsLoading } = useAppSelector(
    chatSendMessageSelectors.getState
  );

  const chat = useMemo(
    () =>
      chats?.find(
        (chat) =>
          chat?.applicationId === Number(application_id_chat) &&
          chat?.sellerCabinetId === Number(seller_cabinet_id)
      ),
    [application_id_chat, chats, seller_cabinet_id]
  );

  const getChatData = useCallback(
    (applicationId: number, sellerCabinetId: number) => {
      dispatch(
        getChat({
          applicationId,
          sellerCabinetId,
          byApplication: fromApplication
        })
      );
    },
    [dispatch, fromApplication]
  );
  const refreshMessages = useCallback(() => {
    chat &&
      dispatch(
        refreshChat({
          chatId: chat?.id,
          lastMessageId: chat?.messages[0]?.id,
          byApplication: fromApplication
        })
      );
  }, [chat, dispatch, fromApplication]);

  const onSendMessage: FormProps<TSendMessageForm>["onFinish"] = useCallback(
    (values: TSendMessageForm) => {
      chat &&
        dispatch(
          sendChatMessage({
            ...values,
            chatId: chat?.id
          })
        )
          .unwrap()
          .then(() => {
            form.resetFields();
            refreshMessages();
          });
    },
    [chat, dispatch, form, refreshMessages]
  );

  const onGoBack = useCallback(() => {
    navigate("../");
  }, [navigate]);

  useEffect(() => {
    (chat?.messages?.length || 0) <= 1 &&
      success &&
      getChatData(Number(application_id_chat), Number(seller_cabinet_id));
  }, [
    application_id_chat,
    chat?.messages?.length,
    getChatData,
    seller_cabinet_id,
    success
  ]);
  useEffect(() => {
    dispatch(
      (fromApplication
        ? chatsByApplicationIdActions
        : chatsActions
      ).setCurChatId(chat?.id)
    );

    return () => {
      form.resetFields();
      dispatch(
        (fromApplication
          ? chatsByApplicationIdActions
          : chatsActions
        ).setCurChatId(undefined)
      );
    };
  }, [chat?.id, dispatch, form, fromApplication]);

  useEffect(() => {
    const timer = setInterval(refreshMessages, 5000);
    return () => clearInterval(timer);
  }, [refreshMessages]);

  return (
    <Wrapper>
      <ChatHeader>
        {forSeller ? (
          <Link to={`../../../applications/${chat?.applicationId}`}>
            <Button type="link" size="large">
              Заявка № {chat?.applicationId}
            </Button>
          </Link>
        ) : fromApplication ? (
          <TypographyTitleNoMargin level={5} removeWeight={false}>
            Поставщик № {chat?.sellerCabinetId}
          </TypographyTitleNoMargin>
        ) : (
          <>
            <TypographyTitleNoMargin level={5} removeWeight={false}>
              Поставщик № {chat?.sellerCabinetId}
            </TypographyTitleNoMargin>
            <Link to={`../../../applications/${chat?.applicationId}`}>
              <Button type="link" size="large">
                Заявка № {chat?.applicationId}
              </Button>
            </Link>
          </>
        )}
        <Button
          icon={<CloseOutlined />}
          onClick={onGoBack}
          type="text"
          size="large"
        />
      </ChatHeader>

      <ChatBox>
        {chat?.messages?.map((message, index, self) => (
          <Message
            {...message}
            key={message?.id}
            // {...(message?.senderId === self[index + 1]?.senderId ? { senderName: undefined } : {})}
          />
        ))}
      </ChatBox>

      <Form<TSendMessageForm>
        layout="vertical"
        name="setpassword"
        onFinish={onSendMessage}
        style={chatFooterStyles}
        form={form}
      >
        <Form.Item<TSendMessageForm> name="message">
          <Space.Compact style={{ width: "100%" }}>
            <Input autoFocus />
            <Button
              type="primary"
              htmlType="submit"
              loading={sendIsLoading}
              icon={<SendOutlined />}
              disabled={!message}
            />
          </Space.Compact>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};
