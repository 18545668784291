import { combineReducers } from "@reduxjs/toolkit";
import { offerForBuyerByIdReducer } from "./byId";
import { offersForBuyerGetReducer } from "./get";
import { offerForBuyerUpdateStatusReducer } from "./update_status";
import { offersForBuyerByApplicationIdReducer } from "./byApplicationId";

export const offersForBuyerReducer = combineReducers({
  byId: offerForBuyerByIdReducer,
  byApplicationId: offersForBuyerByApplicationIdReducer,
  get: offersForBuyerGetReducer,
  updateStatus: offerForBuyerUpdateStatusReducer
});
