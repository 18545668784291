import React, { FC, useMemo } from "react";
import { TEntity, TProject } from "types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

type TProjectsTableProps = {
  projects?: TProject[];
  entities?: TEntity[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  isDeleting?: boolean;
};

export const ProjectsTable: FC<TProjectsTableProps> = ({
  projects,
  entities,
  onDelete,
  onEdit,
  isDeleting
}) => {
  const columns: ColumnsType<TProject> = useMemo(
    () => [
      {
        title: "Название",
        dataIndex: "project_name"
      },
      {
        title: "Юр. лицо",
        dataIndex: "entity_id",
        render: (value: number) =>
          entities?.find((entity) => entity?.entity_id === value)?.short_name ||
          value
      },
      {
        key: "edit",
        render: (value: TProject) => (
          <Button
            onClick={() => onEdit?.(value?.project_id)}
            icon={<EditOutlined />}
          >
            Редактировать
          </Button>
        )
      },
      {
        key: "delete",
        render: (value: TProject) => (
          <Button
            onClick={() => onDelete?.(value?.project_id)}
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
          >
            Удалить
          </Button>
        )
      }
    ],
    [entities, isDeleting, onDelete, onEdit]
  );

  return (
    <Table
      dataSource={projects}
      columns={columns}
      pagination={false}
      scroll={{ x: "1000px" }}
    />
  );
};
