import styled from "@emotion/styled";
import { Form } from "antd";

export const EntityRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  margin-bottom: 8px;
`;

export const EntityFormItemStyled = styled(Form.Item)`
  margin-bottom: 0;
  flex: 5;
  width: 100%;
  max-width: min(200px, calc((100% - 8px * 2 - 32px) * 5 / 9));
`;
export const RoleFormItemStyled = styled(Form.Item)`
  margin-bottom: 0;
  flex: 4;
  width: 100%;
  max-width: 200px;
  max-width: min(200px, calc((100% - 8px * 2 - 32px) * 4 / 9));
`;
