import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";

import { Wrapper } from "./Applications.styles";
import { Typography } from "antd";
import {
  applicationsForSellerGetActions,
  applicationsForSellerGetSelectors
} from "store/applicationsForSeller/get";
import { ApplicationsTable } from "components/common/old";
import { userSelectors } from "store/user";
import { useNavigate } from "react-router-dom";
import { SearchForm } from "./compoents";
import { getApplicationsForSeller } from "store/applicationsForSeller/get/thunk";
import { PAGE_SIZES } from "constants/pagination";
import { adminSelectors } from "store/admin";

export const ApplicationsForSeller = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);
  const {
    applications,
    totalElements,
    page,
    size,
    isLoading: applicationsLoading
  } = useAppSelector(applicationsForSellerGetSelectors.getState);
  const { isAdminSeller } = useAppSelector(adminSelectors.getState);

  const getApplicationsArr = useCallback(() => {
    dispatch(getApplicationsForSeller());
  }, [dispatch]);

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(applicationsForSellerGetActions.setPage(1));
      dispatch(applicationsForSellerGetActions.setSearch(search));
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(applicationsForSellerGetActions.setPage(page));
      } else {
        dispatch(applicationsForSellerGetActions.setPage(1));
        dispatch(applicationsForSellerGetActions.setSize(pageSize));
      }
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr, size]
  );

  useEffect(() => {
    if (user?.is_admin ? !isAdminSeller : !user?.cabinet?.is_seller) {
      navigate("../applications", { replace: true, relative: "path" });
    }
  }, [isAdminSeller, navigate, user]);

  useEffect(() => {
    getApplicationsArr();
  }, [getApplicationsArr]);

  useEffect(() => {
    return () => {
      dispatch(applicationsForSellerGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Заявки для поставщиков
      </Typography.Title>

      <SearchForm onSearch={onSearch} isLoading={applicationsLoading} />

      <ApplicationsTable
        applications={applications}
        forSeller
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
      />
    </Wrapper>
  );
};
