import React, { FC, ReactNode } from "react";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { Navigate, useLocation } from "react-router-dom";

type TProtectedRouteProps = {
  children?: ReactNode;
  authRequired?: boolean;
};

const ProtectedRoute: FC<TProtectedRouteProps> = ({
  children,
  authRequired = false
}) => {
  const { user } = useAppSelector(userSelectors.getState);
  const location = useLocation();

  if (user && !authRequired) {
    const from = location.state?.from || { pathname: "/" };
    return <Navigate to={from} replace />;
  }

  if (!user && authRequired) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
