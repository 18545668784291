import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  DownloadOutlined,
  InboxOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from "@ant-design/icons";
import {
  Image,
  message,
  Space,
  Typography,
  Upload,
  UploadFile,
  UploadProps
} from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { useAppDispatch, useAppSelector } from "store/store";
import { addFile } from "store/files/add/thunk";
import { EFileType, TFileMeta } from "types";
import { fileAddActions, fileAddSelectors } from "store/files/add";
import { extansionIdMapper } from "utils/mappers";
import { deleteFile } from "store/files/delete/thunk";
import { bytesToSize } from "utils/numbers/bytesToSize";
import { fromIsoDate, toDisplayDateTime } from "utils/dates";
import { getFileById } from "store/files/byId/thunk";
import { fileByIdActions } from "store/files/byId";
import { fileDeleteActions } from "store/files/delete";
import { saveAs } from "file-saver";
import { Wrapper } from "./Files.styles";
import { TypographyTitleNoMargin } from "../TypographyTitleNoMargin/TypographyTitleNoMargin";
import { Empty } from "../Empty/Empty";

type TFilesProps = {
  id?: number;
  type: EFileType;
  files?: TFileMeta[];
  onFinish?: () => void;
  canEdit?: boolean;
};

export const Files: FC<TFilesProps> = ({
  id,
  type,
  files,
  onFinish,
  canEdit
}) => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector(fileAddSelectors.getState);

  const [progress, setProgress] = useState(0);
  const [fileUpload, setFileUpload] = useState<File | undefined>(undefined);
  const [fileDelete, setFileDelete] = useState<UploadFile | undefined>(
    undefined
  );

  const [previewImage, setPreviewImage] = useState<string | undefined>(
    undefined
  );
  const [previewOpen, setPreviewOpen] = useState(false);
  const [downloadImage, setDownloadImage] = useState<(() => void) | undefined>(
    undefined
  );

  const defaultFileList: UploadFile[] = useMemo(
    () =>
      (files || [])?.map((file) => {
        const uid = file?.file_meta_id?.toString();
        const meta = extansionIdMapper[file?.file_extension];
        return {
          name: `${file?.file_name}.${meta.extension}`,
          type: meta?.type,
          uid,
          size: file?.file_size,
          url: "#",
          status: fileDelete?.uid === uid ? "uploading" : "done",
          lastModifiedDate: fromIsoDate(file?.created_dt)?.toDate()
        };
      }),
    [fileDelete?.uid, files]
  );

  const fileList: UploadFile[] = useMemo(
    () => [
      ...defaultFileList,
      ...(fileUpload
        ? [
            error
              ? ({
                  name: fileUpload?.name,
                  status: "error",
                  uid: "newFile"
                } as UploadFile)
              : ({
                  name: fileUpload?.name,
                  status: "uploading",
                  uid: "newFile",
                  percent: progress,
                  size: fileUpload?.size
                } as UploadFile)
          ]
        : [])
    ],
    [defaultFileList, error, fileUpload, progress]
  );

  const showUploadList: UploadProps["showUploadList"] = useMemo(
    () => ({
      extra: ({ size = 0, lastModifiedDate }) => (
        <Typography.Text type="secondary" style={{ marginLeft: "4px" }}>
          {[`(${bytesToSize(size, 1)})`, toDisplayDateTime(lastModifiedDate)]
            .filter((i) => !!i)
            .join(" ")}
        </Typography.Text>
      ),
      showRemoveIcon: canEdit
    }),
    [canEdit]
  );

  const beforeUpload: UploadProps["beforeUpload"] = useCallback(
    (file: File) => {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error("Файл не должен превышать 10 МБ");
      }
      return isLt10M;
    },
    []
  );

  const customRequest: UploadProps["customRequest"] = useCallback(
    ({ onSuccess, onError, file, onProgress }: UploadRequestOption) => {
      if (id) {
        setFileUpload(file as File);
        dispatch(
          addFile({
            fileData: {
              file_category_id: 132,
              linked_object_id: id,
              linked_object_type_id: type,
              file_data: file as File
            },
            onUploadProgress: (event) => {
              const percent = Math.floor(
                (event.loaded / (event.total || 1)) * 100
              );
              setProgress(percent);
              if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
              }
              onProgress?.({
                percent: (event.loaded / (event.total || 1)) * 100
              });
            }
          })
        )
          .unwrap()
          .then(() => {
            setFileUpload(undefined);
            onFinish?.();
          });
      }
    },
    [dispatch, id, onFinish, type]
  );

  const onRemoveHandler = useCallback(
    (file: UploadFile) => {
      setFileDelete(file);
      dispatch(deleteFile(Number(file?.uid)))
        .unwrap()
        .then(() => {
          onFinish?.();
        })
        .catch(() => {
          message.error("Не удалось удалить файл");
        })
        .finally(() => {
          setFileDelete(undefined);
        });
    },
    [dispatch, onFinish]
  );

  const onPreviewHandler = useCallback(
    (file: UploadFile) => {
      dispatch(getFileById(Number(file?.uid)))
        .unwrap()
        .then((data) => {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: file.type })
          );

          if (file?.type?.includes("image")) {
            setDownloadImage(() => () => saveAs(data, file?.name));
            setPreviewImage(fileURL);
            setPreviewOpen(true);
          } else if (file?.type?.includes("pdf")) {
            const tab = window.open();
            if (tab) {
              tab.location.href = fileURL;
            }
          } else {
            saveAs(data, file?.name);
          }
        });
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(fileAddActions.clearState());
      dispatch(fileDeleteActions.clearState());
      dispatch(fileByIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <>
      <Wrapper>
        <TypographyTitleNoMargin level={5}>Файлы</TypographyTitleNoMargin>
        {canEdit ? (
          <Upload.Dragger
            customRequest={customRequest}
            maxCount={1}
            fileList={fileList}
            accept={".xlsx, .docx, .doc, .xls, .pdf, .jpeg, .png"}
            showUploadList={showUploadList}
            onPreview={onPreviewHandler}
            onRemove={onRemoveHandler}
            beforeUpload={beforeUpload}
          >
            {/* TODO иконка */}
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Нажмите или перетащите файл в это поле для загрузки
            </p>
            <p className="ant-upload-hint">
              Поддерживается загрузка файла до 10 МВ
            </p>
          </Upload.Dragger>
        ) : fileList?.length ? (
          <Upload
            fileList={fileList}
            showUploadList={showUploadList}
            onPreview={onPreviewHandler}
          />
        ) : (
          <Empty description="Пока нет ни одного файла" />
        )}
      </Wrapper>

      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => {
              !visible && setPreviewImage(undefined);
              !visible && setDownloadImage(undefined);
            },
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                  onReset
                }
              }
            ) => (
              <Space direction="vertical" size={0} className="toolbar-wrapper">
                <Space size={12}>
                  {downloadImage && (
                    <DownloadOutlined onClick={downloadImage} />
                  )}
                  <SwapOutlined rotate={90} onClick={onFlipY} />
                  <SwapOutlined onClick={onFlipX} />
                  <UndoOutlined onClick={onReset} />
                </Space>
                <Space size={12}>
                  <RotateLeftOutlined onClick={onRotateLeft} />
                  <RotateRightOutlined onClick={onRotateRight} />
                  <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                  <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                </Space>
              </Space>
            )
          }}
          src={previewImage}
        />
      )}
    </>
  );
};
