import { createAsyncThunk } from "@reduxjs/toolkit";
import { offersForBuyerByApplicationIdActions } from ".";
import * as api from "api/offers_for_buyers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getOfferForBuyerByApplicationId = createAsyncThunk<
  api.TGetOffersForBuyerByApplicationIdResponse,
  api.TGetOffersForBuyerByApplicationIdRequest
>(
  "offersForBuyers/getOfferForBuyerByApplicationId",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(offersForBuyerByApplicationIdActions.setLoading(true));
    dispatch(offersForBuyerByApplicationIdActions.setError(null));
    return api
      .getOffersForBuyerByApplicationId(payload)
      .then(({ data }) => {
        dispatch(offersForBuyerByApplicationIdActions.setOffersForBuyer(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offersForBuyerByApplicationIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offersForBuyerByApplicationIdActions.setLoading(false));
      });
  }
);
