import React, { FC, ReactNode, useMemo } from "react";
import { TApplicationPosition } from "types";
import Table, { ColumnsType } from "antd/es/table";
import {
  applicationPositionCategoryIdMapper,
  applicationPositionUnitTypeIdMapper
} from "utils/mappers";
import { Button } from "antd";

type TApplicationPositionsTableProps = {
  positions?: TApplicationPosition[];
  icon?: ReactNode;
  onIconClick?: (position: TApplicationPosition) => void;
};

export const ApplicationPositionsTable: FC<TApplicationPositionsTableProps> = ({
  positions,
  icon,
  onIconClick
}) => {
  const positionsColumns: ColumnsType<TApplicationPosition> = useMemo(
    () => [
      ...(icon
        ? [
            {
              title: "",
              key: "icon",
              render: (value: TApplicationPosition) => (
                <Button icon={icon} onClick={() => onIconClick?.(value)} />
              )
            }
          ]
        : []),
      {
        title: "Наименование",
        dataIndex: "position_name"
      },
      {
        title: "Количество",
        key: "unit_amount",
        render: (value: TApplicationPosition) =>
          [
            value?.unit_amount?.toString()?.replaceAll(".", ","),
            applicationPositionUnitTypeIdMapper[value?.unit_type_id]
              ?.shortName || value?.unit_type_id
          ].join(" ")
      },
      {
        title: "Товарная категория",
        dataIndex: "category_id",
        render: (value: number) =>
          applicationPositionCategoryIdMapper[value] || value
      },
      {
        title: "Комментарий",
        dataIndex: "position_comment"
      }
    ],
    [icon, onIconClick]
  );
  return (
    <Table
      dataSource={positions}
      columns={positionsColumns}
      pagination={false}
      scroll={{ x: "800px" }}
    />
  );
};
