import React, { FC, useMemo } from "react";
import { TApplication, TApplicationForSeller, TEntity, TProject } from "types";
import { Pagination, Table, TableColumnsType } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import { toDisplayDateTime } from "utils/dates";
import { Link } from "react-router-dom";
import { TableScrollWrapper, TableWrapper } from "./ApplicationsTable.styles";
import { Empty } from "../Empty/Empty";
import { ApplicationStatusTag } from "../ApplicationStatusTag/ApplicationStatusTag";

type TApplicationForTable = {
  application_id: number;
  application_name: string;
  application_status_id: number;
  buyer_entity_name?: string;
  project_name?: string;
  creator_name: string;
  responsible_name: string;
  positions_length: number;
  application_delivery_due_datetime?: string;
  application_comment?: string;
};

type TApplicationsTableProps = {
  applications?: TApplication[] | TApplicationForSeller[];
  pagination?: TablePaginationConfig | false;
  projects?: TProject[];
  entities?: TEntity[];
  forSeller?: boolean;
  fromOffer?: boolean;
};

export const ApplicationsTable: FC<TApplicationsTableProps> = ({
  applications,
  projects,
  pagination,
  entities,
  forSeller,
  fromOffer
}) => {
  const applicationsForTable: TApplicationForTable[] = useMemo(
    () =>
      (applications || []).map((application) => {
        const project = projects?.find(
          (project) =>
            project?.project_id === (application as TApplication)?.project_id
        );
        const entity = entities?.find(
          (entity) => entity?.entity_id === project?.entity_id
        );
        return {
          application_id: application?.application_id,
          application_name: application?.application_name,
          application_status_id: application?.application_status_id,
          buyer_entity_name: forSeller
            ? (application as TApplicationForSeller)?.buyer_entity?.full_name
            : entity?.full_name,
          project_name: project?.project_name,
          creator_name: (application as TApplication)?.creator?.full_name,
          responsible_name: application?.responsible?.full_name,
          positions_length: application?.positions?.length || 0,
          application_delivery_due_datetime: toDisplayDateTime(
            application?.application_delivery_due_datetime
          ),
          application_comment: application?.application_comment
        };
      }),
    [applications, entities, forSeller, projects]
  );

  const columns: TableColumnsType<TApplicationForTable> = useMemo(
    () => [
      {
        title: "Наименование",
        key: "application_name",
        render: (value: TApplicationForTable) => (
          <Link
            to={
              fromOffer
                ? `${"../../applications"}/${value?.application_id}`
                : `${value?.application_id}`
            }
          >
            {[value?.application_id, value?.application_name].join(" - ")}
          </Link>
        ),
        minWidth: 213
      },
      {
        title: "Статус",
        dataIndex: "application_status_id",
        render: (value: number) => {
          return <ApplicationStatusTag status_id={value} />;
        },
        minWidth: 104
      },
      {
        title: "Юр. лицо",
        dataIndex: "buyer_entity_name",
        minWidth: 168
      },
      ...(forSeller
        ? []
        : [
            {
              title: "Проект",
              dataIndex: "project_name",
              minWidth: 155
            }
          ]),
      ...(forSeller
        ? []
        : [
            {
              title: "Создатель",
              dataIndex: "creator_name",
              minWidth: 148
            }
          ]),
      {
        title: "Ответственный",
        dataIndex: "responsible_name",
        minWidth: 148
      },
      {
        title: "Позиций",
        dataIndex: "positions_length",
        minWidth: 76
      },
      {
        title: "Дата поставки",
        dataIndex: "application_delivery_due_datetime",
        minWidth: 108
      },
      {
        title: "Комментарий",
        dataIndex: "application_comment",
        minWidth: 285
      }
    ],
    [forSeller, fromOffer]
  );

  return (
    <TableWrapper>
      {applicationsForTable?.length ? (
        <>
          <TableScrollWrapper>
            <Table
              dataSource={applicationsForTable}
              columns={columns}
              pagination={false}
              rowKey={(row) => row?.application_id}
            />
          </TableScrollWrapper>
          {pagination && <Pagination {...pagination} align="end" />}
        </>
      ) : (
        <Empty description="Пока нет ни одной заявки" style={{ flex: 1 }} />
      )}
    </TableWrapper>
  );
};
