import React, { FC, useCallback, useMemo } from "react";
import { TOfferEditFormTabProps } from "../../OfferEditForm";
import { CTABlock, NavButton } from "../../OfferEditForm.styles";
import { ErrorAlert } from "components/common/old";
import { OfferData } from "components/common/redesign";
import { TOfferForSeller } from "types";
import { Button, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

export const PreviewTab: FC<TOfferEditFormTabProps> = ({
  formData,
  application,
  entitiesForOffer,
  accounts,
  isLoading,
  error,
  onSubmitForm,
  onPrevStep,
  onStepEdit
}) => {
  const entity = useMemo(
    () =>
      entitiesForOffer?.find(
        (entity) => entity?.entity_id === formData?.seller_entity
      ),
    [entitiesForOffer, formData?.seller_entity]
  );
  const account = useMemo(
    () =>
      accounts?.find(
        (account) => account?.account_id === formData?.seller_account
      ),
    [accounts, formData?.seller_account]
  );

  const offer = useMemo(
    () => ({ ...formData, seller_entity: entity, seller_account: account }),
    [account, entity, formData]
  );

  const onSubmitFormHanlder = useCallback(
    (status: number) => {
      onSubmitForm(status);
    },
    [onSubmitForm]
  );

  return (
    <>
      <OfferData
        offer={offer as TOfferForSeller}
        application={application}
        previewOnly
        onStepEdit={onStepEdit}
        forSeller
      />

      <ErrorAlert error={error} />

      <CTABlock>
        <Space.Compact>
          <NavButton
            type="primary"
            onClick={() => onSubmitFormHanlder(123)}
            loading={isLoading}
          >
            Завершить
          </NavButton>

          <Dropdown
            menu={{
              items: [
                {
                  key: 99,
                  label: <NavButton>Сохранить как черновик</NavButton>
                },
                {
                  key: 103,
                  label: (
                    <NavButton danger>
                      Отправить в тендер без согласования
                    </NavButton>
                  )
                }
              ],
              onClick: ({ key }: { key: string }) =>
                onSubmitFormHanlder(Number(key))
            }}
          >
            <Button loading={isLoading} type="primary">
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space.Compact>

        <NavButton onClick={onPrevStep}>Назад</NavButton>
      </CTABlock>
    </>
  );
};
