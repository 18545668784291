import { createAsyncThunk } from "@reduxjs/toolkit";
import { licensesGetActions } from ".";
import * as api from "api/license";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";

export const getLicenses = createAsyncThunk<
  api.TGetLicensesResponse,
  void,
  { state: RootState }
>("license/getLicenses", (payload, { dispatch, rejectWithValue, getState }) => {
  dispatch(licensesGetActions.setLoading(true));
  dispatch(licensesGetActions.setError(null));

  return api
    .getLicenses()
    .then(({ data }) => {
      dispatch(licensesGetActions.setLicenses(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(licensesGetActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(licensesGetActions.setLoading(false));
    });
});
