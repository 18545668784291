import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateApplicationStatus = (
  data: TUpdateApplicationStatusRequest
): RequestPromise<TUpdateApplicationStatusResponse> => {
  return apiRequest.post(`/applications/update_status`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateApplicationStatusRequest = {
  application_id: number;
  new_status_id: number;
};

export type TUpdateApplicationStatusResponse = {
  application_id: number;
  new_status_id: number;
};
