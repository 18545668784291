import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateOfferForBuyerStatus = (
  data: TUpdateOfferForBuyerStatusRequest
): RequestPromise<TUpdateOfferForBuyerStatusResponse> => {
  return apiRequest.post(`/offers-for-buyers/update_status`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateOfferForBuyerStatusRequest = {
  offer_id: number;
  new_status_id: number;
};

export type TUpdateOfferForBuyerStatusResponse = {
  offer_id: number;
  new_status_id: number;
};
