import React, { FC, useMemo } from "react";
import { ChatTitle, TitleCol, Wrapper } from "./ChatsList.styles";
import { TChat } from "types";
import { Avatar, List, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { toDisplayMessageDateTime } from "utils/dates";

type TChatsListProps = {
  chats?: TChat[];
  curChatId?: number;
  forSeller?: boolean;
  fromApplication?: boolean;
};

export const ChatsList: FC<TChatsListProps> = ({
  chats,
  curChatId,
  fromApplication,
  forSeller
}) => {
  const navigate = useNavigate();

  const sortedChats = useMemo(
    () =>
      [...(chats || [])]?.sort((a, b) => {
        const messageA = a?.messages?.[0];
        const messageB = b?.messages?.[0];

        if (messageA && !messageB) {
          return -1;
        } else if (!messageA && messageB) {
          return 1;
        } else if (messageA && messageB) {
          const dateMessageA = dayjs(messageA?.sentAt);
          const dateMessageB = dayjs(messageB?.sentAt);

          return dateMessageB.diff(dateMessageA);
        } else {
          const dateCrA = dayjs(a?.created);
          const dateCrB = dayjs(b?.created);

          return dateCrB.diff(dateCrA);
        }
      }),
    [chats]
  );

  return (
    <Wrapper>
      {sortedChats && (
        <List
          itemLayout="horizontal"
          dataSource={sortedChats}
          renderItem={(item) => (
            <List.Item
              className={item?.id === curChatId ? "active" : undefined}
              key={item?.id}
              onClick={() =>
                navigate(`${item?.applicationId}/${item?.sellerCabinetId}`)
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar>
                    {forSeller
                      ? item?.applicationId
                      : fromApplication
                        ? item?.sellerCabinetId
                        : `${item?.applicationId}/${item?.sellerCabinetId}`}
                  </Avatar>
                }
                title={
                  <ChatTitle>
                    {forSeller ? (
                      <Typography.Text>
                        Заявка № {item?.applicationId}
                      </Typography.Text>
                    ) : fromApplication ? (
                      <Typography.Text>
                        Поставщик № {item?.sellerCabinetId}
                      </Typography.Text>
                    ) : (
                      <TitleCol>
                        <Typography.Text>
                          Заявка № {item?.applicationId}
                        </Typography.Text>
                        <Typography.Text>
                          Поставщик № {item?.sellerCabinetId}
                        </Typography.Text>
                      </TitleCol>
                    )}

                    <Typography.Text type="secondary">
                      <small>
                        {toDisplayMessageDateTime(item?.messages[0]?.sentAt)}
                      </small>
                    </Typography.Text>
                  </ChatTitle>
                }
                description={
                  item?.messages[0] &&
                  `${item?.messages[0]?.senderName?.split(" ")[0]}: ${item?.messages[0]?.message}`
                }
              />
            </List.Item>
          )}
        />
      )}
    </Wrapper>
  );
};
