import React, { FC, useCallback, useMemo } from "react";
import { Panel } from "..";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Typography
} from "antd";
import {
  RequestError,
  TAccount,
  TApplicationForSeller,
  TEntity,
  TOfferForSeller,
  TOfferForSellerRequest,
  TOfferPosition
} from "types";
import dayjs, { Dayjs } from "dayjs";
import { DATE_TIME_FORMAT, ISO_DATE_TIME } from "constants/dates";
import { toSendDateTime } from "utils/dates";
import { ErrorAlert } from "..";
import { PositionsSelector } from "./components";
import { padFractionalZeros, splitThousands } from "utils/numbers";
import { countOfferPositionsPrice } from "utils/countOfferPositionsPrice";

export type TOfferPositionFromValue = Omit<
  TOfferPosition,
  "unit_amount" | "unit_price" | "discount" | "vat"
> & {
  unit_amount: string;
  unit_price: string;
  discount: string;
  vat: string;
};

export type TOfferFormValues = Omit<
  TOfferForSellerRequest,
  "delivary_plan_dt" | "positions"
> & {
  delivary_plan_dt?: Dayjs;
  positions?: TOfferPositionFromValue[];
};

type TOfferEditFormProps = {
  application?: TApplicationForSeller;
  offer?: TOfferForSeller;
  entitiesForOffer?: TEntity[];
  accounts?: TAccount[];
  onSubmit: (values: TOfferForSellerRequest) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const OfferEditForm: FC<TOfferEditFormProps> = ({
  application,
  offer,
  entitiesForOffer,
  accounts,
  onSubmit,
  isLoading,
  error
}) => {
  const [form] = Form.useForm<TOfferFormValues>();

  const sellerEntity = Form.useWatch("seller_entity", form);
  const positions = Form.useWatch("positions", form);

  const entitiesOptions = useMemo(
    () =>
      (entitiesForOffer || [])?.map((entity) => ({
        label: entity?.short_name,
        value: entity?.entity_id
      })),
    [entitiesForOffer]
  );
  const accountsOptions = useMemo(
    () =>
      (accounts || [])
        .filter((account) => account?.entity_id === sellerEntity)
        .map((account) => ({
          label: account?.name,
          value: account?.account_id
        })),
    [accounts, sellerEntity]
  );

  const initialValues: TOfferFormValues | undefined = useMemo(
    () =>
      offer
        ? {
            ...offer,
            delivary_plan_dt: offer?.delivary_plan_dt
              ? dayjs(offer?.delivary_plan_dt, ISO_DATE_TIME)
              : undefined,
            seller_entity: offer?.seller_entity?.entity_id,
            seller_account: offer?.seller_account?.account_id,
            positions: offer?.positions?.map((position) => ({
              ...position,
              unit_amount: position?.unit_amount
                ?.toString()
                ?.replaceAll(".", ","),
              unit_price: position?.unit_price
                ?.toString()
                ?.replaceAll(".", ","),
              discount: position?.discount?.toString()?.replaceAll(".", ","),
              vat: position?.vat?.toString()?.replaceAll(".", ",")
            }))
          }
        : undefined,
    [offer]
  );

  const { price, priceVAT } = useMemo(
    () => countOfferPositionsPrice(positions),
    [positions]
  );

  const onSubmitHandler = useCallback(
    (status?: number) => {
      form.validateFields().then(() => {
        const offerValues = form.getFieldsValue();

        if (offerValues) {
          onSubmit({
            ...offerValues,
            delivary_plan_dt: toSendDateTime(offerValues?.delivary_plan_dt),
            positions: offerValues?.positions?.map((position) => ({
              ...position,
              unit_amount: Number(
                position?.unit_amount?.toString()?.replaceAll(",", ".")
              ),
              unit_price: Number(
                position?.unit_price?.toString()?.replaceAll(",", ".")
              ),
              discount: Number(
                position?.discount?.toString()?.replaceAll(",", ".")
              ),
              vat: Number(position?.vat?.toString()?.replaceAll(",", "."))
            })) as TOfferPosition[],
            status_id: (offerValues?.status_id || status) as number,
            creator_id: offer?.creator?.user_id as number,
            application_id: offer?.application_id as number,
            offer_id: offer?.offer_id as number
          });
        }
      });
    },
    [form, offer, onSubmit]
  );

  const onEntityChange = useCallback(() => {
    form.setFieldValue("seller_account", undefined);
  }, [form]);

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        // onFinish={onSubmitHandler}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={initialValues}
      >
        <Typography.Title level={4}>Параметры предложения</Typography.Title>
        <Form.Item<TOfferFormValues>
          label="Ожидаемая дата поставки"
          name="delivary_plan_dt"
        >
          <DatePicker format={DATE_TIME_FORMAT} showTime minDate={dayjs()} />
        </Form.Item>
        <Form.Item<TOfferFormValues>
          label="Юр. лицо поставщика"
          name="seller_entity"
          rules={[
            {
              required: true,
              message: "Выберите юр. лицо поставщика"
            }
          ]}
        >
          <Select
            options={entitiesOptions}
            disabled={!entitiesOptions?.length}
            onChange={onEntityChange}
          />
        </Form.Item>
        <Form.Item<TOfferFormValues>
          label="Счёт поставщика"
          name="seller_account"
          rules={[
            {
              required: true,
              message: "Выберите счёт поставщика"
            }
          ]}
        >
          <Select
            options={accountsOptions}
            disabled={!accountsOptions?.length}
          />
        </Form.Item>
        <Form.Item<TOfferFormValues>
          label="Комментарий к предложению"
          name="offer_comment"
        >
          <Input />
        </Form.Item>

        <PositionsSelector
          form={form}
          applicationPositions={application?.positions}
        />

        <Alert
          message={`Стоимость: ${splitThousands(padFractionalZeros(priceVAT))}`}
          description={`Стоимость без НДС: ${splitThousands(padFractionalZeros(price))}`}
          type="info"
          style={{ marginBottom: "24px" }}
        />

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="button"
            loading={isLoading}
            onClick={() => onSubmitHandler(123)}
          >
            Отправить на согласование
          </Button>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="button"
            loading={isLoading}
            onClick={() => onSubmitHandler(99)}
            ghost
          >
            Сохранить как черновик
          </Button>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="button"
            loading={isLoading}
            onClick={() => onSubmitHandler(103)}
            danger
            ghost
          >
            Отправить в тендер без согласования
          </Button>
        </Form.Item>
        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
