import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatSendMessageActions } from ".";
import * as api from "api/chat";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const sendChatMessage = createAsyncThunk<
  api.TSendChatMessageResponse,
  api.TSendChatMessageRequest
>(
  "chat/sendChatMessage",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(chatSendMessageActions.setLoading(true));
    dispatch(chatSendMessageActions.setError(null));
    return api
      .sendChatMessage(payload)
      .then(({ data }) => {
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(chatSendMessageActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(chatSendMessageActions.setLoading(false));
      });
  }
);
