import { createAsyncThunk } from "@reduxjs/toolkit";
import { offerForSellerAddActions } from ".";
import * as api from "api/offers_for_sellers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const addOfferForSeller = createAsyncThunk<
  api.TAddOfferForSellerResponse,
  api.TAddOfferForSellerRequest
>(
  "offersForSeller/addOfferForSeller",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(offerForSellerAddActions.setLoading(true));
    dispatch(offerForSellerAddActions.setError(null));
    return api
      .addOfferForSeller(payload)
      .then(({ data }) => {
        dispatch(offerForSellerAddActions.setOfferForSeller(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offerForSellerAddActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offerForSellerAddActions.setLoading(false));
      });
  }
);
