import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLoginState } from "./types";
import { RequestError } from "types";

const initialState: TLoginState = {
  isLoading: false,
  error: null
};

export const LoginSlice = createSlice({
  name: "user/login",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const loginActions = LoginSlice.actions;
export const loginReducer = LoginSlice.reducer;

export * as loginSelectors from "./selectors";
