import React, { FC, useMemo } from "react";
import { ErrorAlert, Panel } from "..";
import { Button, Form, Input, Select } from "antd";
import { RequestError, TAccount, TEntity } from "types";
import { accountStatusOptions } from "constants/options";

type TAccountEditFormProps = {
  account?: TAccount;
  onSubmit: (values: TAccount) => void;
  isLoading: boolean;
  error: RequestError | null;
  withEntity?: boolean;
  entities?: TEntity[];
};

export const AccountEditForm: FC<TAccountEditFormProps> = ({
  account,
  onSubmit,
  isLoading,
  error,
  withEntity,
  entities
}) => {
  const entitiesOptions = useMemo(
    () =>
      (entities || []).map((entity) => ({
        label: entity?.short_name,
        value: entity?.entity_id
      })),
    [entities]
  );

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={account}
      >
        {withEntity && (
          <Form.Item<TAccount>
            label="Юр. лицо"
            name="entity_id"
            rules={[
              {
                required: true,
                message: "Введите юр. лицо"
              }
            ]}
          >
            <Select options={entitiesOptions} />
          </Form.Item>
        )}
        <Form.Item<TAccount>
          label="Статус"
          name="status"
          rules={[
            {
              required: true,
              message: "Введите статус"
            }
          ]}
        >
          <Select options={accountStatusOptions} />
        </Form.Item>
        <Form.Item<TAccount>
          label="Название счета"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите название счета"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="Банк"
          name="bank_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите название банка"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="Расчетный счет"
          name="number"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "Расчетный счет должен состоять из цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите расчетный счет"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="Кор. счет"
          name="correspondent"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "Кор. счет должен состоять из цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите кор, счет"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="БИК"
          name="bik"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "БИК должен состоять из цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите БИК"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="ОКАТО"
          name="okato"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "ОКАТО должен состоять из цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите ОКАТО"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
