import { createAsyncThunk } from "@reduxjs/toolkit";
import { licenseTypeAddActions } from ".";
import * as api from "api/license";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const addLicenseType = createAsyncThunk<
  api.TAddLicenseTypeResponse,
  api.TAddLicenseTypeRequest
>("license/type/addLicenseType", (payload, { dispatch, rejectWithValue }) => {
  dispatch(licenseTypeAddActions.setLoading(true));
  dispatch(licenseTypeAddActions.setError(null));
  return api
    .addLicenseType(payload)
    .then(({ data }) => {
      dispatch(licenseTypeAddActions.setLicenseType(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(licenseTypeAddActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(licenseTypeAddActions.setLoading(false));
    });
});
