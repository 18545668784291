import { TCabinet } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const deleteCabinet = (
  id: TDeleteCabinetRequest
): RequestPromise<TDeleteCabinetResponse> => {
  return apiRequest.post(`/cabinets/delete/${id}`, undefined, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TDeleteCabinetRequest = number;

export type TDeleteCabinetResponse = TCabinet;
