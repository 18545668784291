import styled from "@emotion/styled";
import { CSSProperties } from "react";

export const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const ChatHeader = styled.div`
  padding: 0 12px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;
export const ChatBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  gap: 8px;
  overflow: auto;
  padding: 12px;
`;

export const chatFooterStyles: CSSProperties = {
  padding: "8px 12px",
  borderTop: "1px solid rgba(5, 5, 5, 0.06)",
  display: "flex",
  flexDirection: "column"
};
