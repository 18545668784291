import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApprovementsByUserIdState } from "./types";

import { RequestError, TApplicationApprovement } from "types";

const initialState: TApprovementsByUserIdState = {
  isLoading: false,
  error: null,
  approvements: undefined
};

export const ApprovementsByUserIdSlice = createSlice({
  name: "approvements/byUserId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApprovements: (
      state,
      { payload }: PayloadAction<TApplicationApprovement[]>
    ) => {
      state.approvements = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const approvementsByUserIdActions = ApprovementsByUserIdSlice.actions;
export const approvementsByUserIdReducer = ApprovementsByUserIdSlice.reducer;

export * as approvementsByUserIdSelectors from "./selectors";
