import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TCabinetsGetState } from "./types";

import { RequestError, TCabinet } from "types";

const initialState: TCabinetsGetState = {
  isLoading: false,
  error: null,
  cabinets: undefined
};

export const CabinetsGetSlice = createSlice({
  name: "cabinet/get",
  initialState,
  reducers: {
    clearState: () => initialState,
    setCabinets: (state, { payload }: PayloadAction<TCabinet[]>) => {
      state.cabinets = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const cabinetsGetActions = CabinetsGetSlice.actions;
export const cabinetsGetReducer = CabinetsGetSlice.reducer;

export * as cabinetsGetSelectors from "./selectors";
