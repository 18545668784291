import React, { FC, useMemo } from "react";
import {
  TEntity,
  TOfferForBuyer,
  TOfferForSeller,
  TOfferPosition,
  TPerson,
  TPersonEntity
} from "types";
import { Table, Tag, Typography } from "antd";
import { offerStatusIdMapper } from "utils/mappers";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { toDisplayDate } from "utils/dates";
import { Link } from "react-router-dom";
import { OffersTableExpandableRow } from "./components";
import { countOfferPositionsPrice } from "utils/countOfferPositionsPrice";
import { padFractionalZeros, splitThousands } from "utils/numbers";

type TOffersTableProps = {
  offers?: TOfferForSeller[] | TOfferForBuyer[];
  pagination?: TablePaginationConfig | false;
  withoutApplication?: boolean;
  entities?: TPersonEntity;
  fromApplication?: boolean;
};

export const OffersTable: FC<TOffersTableProps> = ({
  offers,
  pagination,
  withoutApplication,
  fromApplication
}) => {
  const columns: ColumnsType<TOfferForSeller | TOfferForBuyer> = useMemo(
    () => [
      {
        title: "Предложение",
        key: "offer_id",
        render: (value: TOfferForSeller | TOfferForBuyer) => (
          <Link
            to={
              fromApplication
                ? `../../offers/${value?.offer_id}`
                : `${value?.offer_id}`
            }
          >
            #{value?.offer_id}{" "}
            {value?.create_dt && `от ${toDisplayDate(value?.create_dt)}`}
          </Link>
        )
      },
      {
        title: "Статус",
        dataIndex: "status_id",
        render: (value: number) => {
          const statusData = offerStatusIdMapper[value];
          return statusData ? (
            <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
          ) : (
            value
          );
        }
      },
      // ...(forSeller
      //   ? [
      //       {
      //         title: "Юр. лицо покупателя",
      //         key: "buyer_entity",
      //         render: (value: TOfferForBuyer | TOfferForSeller) => {
      //           return (value as TOfferForSeller)?.buyer_entity?.short_name;
      //         }
      //       }
      //     ]
      //   : []),
      {
        title: "Юр. лицо поставщика",
        dataIndex: "seller_entity",
        render: (value: TEntity) => value?.short_name
      },
      ...(!withoutApplication
        ? [
            {
              title: "Заявка",
              dataIndex: "application_id",
              render: (value: number) => (
                <Link to={`../applications/${value}`}>#{value}</Link>
              )
            }
          ]
        : []),
      {
        title: "Создатель",
        dataIndex: "creator",
        render: (value: TPerson) => value?.full_name
      },
      {
        title: "Кол-во позиций",
        dataIndex: "positions",
        render: (value: TOfferPosition[]) => value?.length
      },
      {
        title: "Стоимость",
        key: "price",
        render: (value: TOfferForSeller | TOfferForBuyer) => {
          const { price, priceVAT } = countOfferPositionsPrice(
            value?.positions
          );
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>
                {splitThousands(padFractionalZeros(priceVAT))}
              </Typography.Text>
              <Typography.Text type="secondary">
                ({splitThousands(padFractionalZeros(price))} без НДС)
              </Typography.Text>
            </div>
          );
        }
      }
    ],
    [fromApplication, withoutApplication]
  );

  return (
    <Table
      dataSource={offers}
      columns={columns}
      pagination={pagination}
      scroll={{ x: "1400px" }}
      expandable={{
        expandedRowRender: (row) => <OffersTableExpandableRow offer={row} />
      }}
      rowKey={(row) => row?.offer_id}
    />
  );
};
