import React, { useCallback, useEffect, useMemo } from "react";
import { AlertWrapper, OfferWrapper } from "./OfferForBuyer.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import { useNavigate, useParams } from "react-router-dom";
import { entitiesByCabinetIdSelectors } from "store/entities/byCabinetId";
import {
  filesByObjectActions,
  filesByObjectSelectors
} from "store/files/byObject";
import { userSelectors } from "store/user";
import { Alert, Button } from "antd";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import { EFileType } from "types";
import { getFilesByObject } from "store/files/byObject/thunk";
import { OfferData } from "components/common/redesign";
import {
  offerForBuyerByIdActions,
  offerForBuyerByIdSelectors
} from "store/offersForBuyer/byId";
import {
  offerForBuyerUpdateStatusActions,
  offerForBuyerUpdateStatusSelectors
} from "store/offersForBuyer/update_status";
import {
  ApplicationByIdActions,
  applicationByIdSelectors
} from "store/applications/byId";
import { projectsByCabinetSelectors } from "store/projects/byCabinet";
import { getProjectsByCabinet } from "store/projects/byCabinet/thunk";
import { getOfferForBuyerById } from "store/offersForBuyer/byId/thunk";
import { getApplicationById } from "store/applications/byId/thunk";
import { updateOfferForBuyerStatus } from "store/offersForBuyer/update_status/thunk";

export const OfferForBuyer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);
  const { offerForBuyer } = useAppSelector(offerForBuyerByIdSelectors.getState);

  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForBuyerUpdateStatusSelectors.getState
  );
  const { application } = useAppSelector(applicationByIdSelectors.getState);

  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { files } = useAppSelector(filesByObjectSelectors.getState);

  const { offer_id } = useParams<{ offer_id: string }>();

  const project = useMemo(
    () =>
      projects?.find(
        (project) => project?.project_id === application?.project_id
      ),
    [application, projects]
  );

  const entity = useMemo(
    () =>
      user?.entities?.find(
        (entity) => entity?.entity?.entity_id === project?.entity_id
      ),
    [user, project]
  );

  const canChangeStatus = useMemo(() => {
    return (
      application?.creator?.user_id === user?.user_id ||
      application?.responsible?.user_id === user?.user_id ||
      (entity?.role_id && entity?.role_id >= 3) ||
      (entity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
        user?.cabinet_role_id === 2) ||
      user?.is_admin
    );
  }, [application, entity, user]);

  const getOffer = useCallback(
    (offer_id: number) => {
      dispatch(getOfferForBuyerById(offer_id));
    },
    [dispatch]
  );
  const getApplication = useCallback(
    (application_id: number) => {
      dispatch(getApplicationById(application_id));
    },
    [dispatch]
  );
  const getFiles = useCallback(
    (offer_id: number) => {
      dispatch(
        getFilesByObject({
          id: offer_id,
          type: EFileType.OFFER
        })
      );
    },
    [dispatch]
  );

  const getEntities = useCallback(
    (cabinet_id: number) => {
      dispatch(getEntitiesByCabinetId(cabinet_id));
    },
    [dispatch]
  );

  const getProjects = useCallback(
    (cabinet_id: number) => {
      dispatch(getProjectsByCabinet(cabinet_id));
    },
    [dispatch]
  );

  const onUpdateStatus = useCallback(
    ({ key }: { key: string }) => {
      offerForBuyer &&
        dispatch(
          updateOfferForBuyerStatus({
            offer_id: offerForBuyer?.offer_id,
            new_status_id: Number(key)
          })
        )
          .unwrap()
          .then(() => {
            getOffer(Number(offer_id));
          });
    },
    [dispatch, getOffer, offerForBuyer, offer_id]
  );

  const onGoBack = useCallback(() => {
    navigate("../");
  }, [navigate]);

  useEffect(() => {
    offer_id && getOffer(Number(offer_id));
  }, [getOffer, offer_id]);
  useEffect(() => {
    offerForBuyer && getApplication(offerForBuyer?.application_id);
  }, [getApplication, offerForBuyer]);
  useEffect(() => {
    offerForBuyer && getFiles(offerForBuyer?.offer_id);
  }, [getFiles, offerForBuyer]);
  useEffect(() => {
    !entities && user && getEntities(user?.cabinet?.cabinet_id);
  }, [dispatch, entities, getEntities, user]);
  useEffect(() => {
    !projects && user && getProjects(user?.cabinet?.cabinet_id);
  }, [dispatch, getProjects, projects, user]);

  useEffect(() => {
    return () => {
      dispatch(offerForBuyerUpdateStatusActions.clearState());
      dispatch(offerForBuyerByIdActions.clearState());
      dispatch(ApplicationByIdActions.clearState());
      dispatch(filesByObjectActions.clearState());
    };
  }, [dispatch]);

  return offerForBuyer ? (
    <OfferWrapper>
      <OfferData
        offer={offerForBuyer}
        application={application}
        buyerEntity={entity?.entity}
        files={files}
        getFiles={() => getFiles(offerForBuyer?.offer_id)}
        onGoBack={onGoBack}
        withStatusChange={canChangeStatus}
        onUpdateStatus={onUpdateStatus}
        statusIsLoading={statusIsLoading}
        statusError={statusError}
      />
    </OfferWrapper>
  ) : (
    <AlertWrapper>
      <Alert message="Данные по предложению отсутствуют" showIcon />

      <Button onClick={onGoBack}>Назад</Button>
    </AlertWrapper>
  );
};
