import React, { FC, useMemo } from "react";
import { DescriptionsProps } from "antd";
import { DescriptionsCustom } from "components/common/redesign/DescriptionsCustom/DescriptionsCustom";
import { TPerson } from "types";

type TOfferProcessProps = {
  creator?: TPerson;
};

export const OfferProcess: FC<TOfferProcessProps> = ({ creator }) => {
  const processData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "creator",
        label: "Создатель предложения",
        children: `${creator?.last_name} ${creator?.first_name}`
      }
    ],
    [creator]
  );

  return (
    <>
      <DescriptionsCustom items={processData} title={"Процесс предложения"} />
    </>
  );
};
