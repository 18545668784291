import React, { useEffect } from "react";
import { Button, Empty, Typography } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

export const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/404") {
      navigate("/404", { replace: true, relative: "path" });
    }
  }, [location.pathname, navigate]);

  return (
    <Empty
      description={
        <>
          <Typography.Title>404</Typography.Title>
          <Typography.Text>Кажется вы заблудились</Typography.Text>
          <Link to="/" style={{ display: "block" }}>
            <Button
              color="primary"
              variant="link"
              icon={<RightOutlined />}
              iconPosition="end"
            >
              Домой
            </Button>
          </Link>
        </>
      }
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    />
  );
};
