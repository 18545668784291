import { TFileMeta, TFileRequest } from "types";
import { RequestConfig, RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addFile = ({
  fileData,
  onUploadProgress
}: TAddFileRequest): RequestPromise<TAddFileResponse> => {
  return apiRequest.post(`/files/add`, fileData, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
      "content-type": "multipart/form-data"
    },
    onUploadProgress
  });
};

export type TAddFileRequest = {
  fileData: FormData;
  onUploadProgress: RequestConfig<FormData>["onUploadProgress"];
};

export type TAddFileRequestThunk = {
  fileData: TFileRequest;
  onUploadProgress: RequestConfig<FormData>["onUploadProgress"];
};

export type TAddFileResponse = TFileMeta;
