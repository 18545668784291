import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { userReducer } from "./user";
import { colleaguesReducer } from "./colleagues";
import { entitiesReducer } from "./entities";
import { accountsReducer } from "./accounts";
import { projectsReducer } from "./projects";
import { applicationsReducer } from "./applications";
import { approvementsReducer } from "./approvements";
import { applicationsForSellerReducer } from "./applicationsForSeller";
import { offersForSellerReducer } from "./offersForSeller";
import { offersForBuyerReducer } from "./offersForBuyer";
import { filesReducer } from "./files";
import { cabinetsReducer } from "./cabinets";
import { licensesReducer } from "./licenses";
import { adminReducer } from "./admin";
import { chatReducer } from "./chat";

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    user: userReducer,
    colleagues: colleaguesReducer,
    entities: entitiesReducer,
    accounts: accountsReducer,
    projects: projectsReducer,
    applications: applicationsReducer,
    approvements: approvementsReducer,
    applicationsForSeller: applicationsForSellerReducer,
    offersForSeller: offersForSellerReducer,
    offersForBuyer: offersForBuyerReducer,
    files: filesReducer,
    cabinets: cabinetsReducer,
    licenses: licensesReducer,
    chat: chatReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
