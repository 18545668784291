import { PageableResponse, TOfferForBuyer } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getOffersForBuyer = (
  data: TGetOffersForBuyerRequest
): RequestPromise<TGetOffersForBuyerResponse> => {
  return apiRequest.get(`/offers-for-buyers/get`, {
    params: data,
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetOffersForBuyerRequest = {
  search?: string;
  size: number;
  page: number;
};

export type TGetOffersForBuyerResponse = PageableResponse<TOfferForBuyer>;
