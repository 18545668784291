import { createAsyncThunk } from "@reduxjs/toolkit";
import { offerForBuyerByIdActions } from ".";
import * as api from "api/offers_for_buyers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getOfferForBuyerById = createAsyncThunk<
  api.TGetOfferForBuyerByIdResponse,
  api.TGetOfferForBuyerByIdRequest
>(
  "offersForBuyers/getOfferForBuyerById",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(offerForBuyerByIdActions.setLoading(true));
    dispatch(offerForBuyerByIdActions.setError(null));
    return api
      .getOfferForBuyerById(payload)
      .then(({ data }) => {
        dispatch(offerForBuyerByIdActions.setOfferForBuyer(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offerForBuyerByIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offerForBuyerByIdActions.setLoading(false));
      });
  }
);
