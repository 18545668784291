import { createAsyncThunk } from "@reduxjs/toolkit";
import { offerForSellerByIdActions } from ".";
import * as api from "api/offers_for_sellers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getOfferForSellerById = createAsyncThunk<
  api.TGetOfferForSellerByIdResponse,
  api.TGetOfferForSellerByIdRequest
>(
  "offersForSellers/getOfferForSellerById",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(offerForSellerByIdActions.setLoading(true));
    dispatch(offerForSellerByIdActions.setError(null));
    return api
      .getOfferForSellerById(payload)
      .then(({ data }) => {
        dispatch(offerForSellerByIdActions.setOfferForSeller(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offerForSellerByIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offerForSellerByIdActions.setLoading(false));
      });
  }
);
