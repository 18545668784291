import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, Form, Input, Radio, Select } from "antd";
import { RequestError, TAccount, TEntity } from "types";
import { ErrorAlert } from "../../old";
import { CTABlock } from "./AccountEditForm.styles";
import { DeleteFilled, LeftOutlined } from "@ant-design/icons";
import { ConfirmDelete } from "../ConfirmDelete/ConfirmDelete";
import { BasicTitle } from "../BasicTitle/BasicTitle";

type TAccountEditFormProps = {
  account?: TAccount;
  entities?: TEntity[];
  onCancelEdit: () => void;
  onSubmit: (values: TAccount) => void;
  submitIsLoading: boolean;
  submitError: RequestError | null;
  onDelete?: () => Promise<void> | undefined;
  deleteIsLoading?: boolean;
  deleteError?: RequestError | null;
};

export const AccountEditForm: FC<TAccountEditFormProps> = ({
  account,
  entities,
  onCancelEdit,
  onSubmit,
  submitIsLoading,
  submitError,
  onDelete,
  deleteIsLoading,
  deleteError
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [form] = Form.useForm<TAccount>();

  const name = Form.useWatch("name", form);

  const entitiesOptions = useMemo(
    () =>
      (entities || []).map((entity) => ({
        label: entity?.full_name,
        value: entity?.entity_id
      })),
    [entities]
  );

  const onDeleteHandler = useCallback(() => {
    onDelete?.()?.then(() => {
      closeModal();
    });
  }, [onDelete]);

  const showModal = useCallback(() => {
    setOpenDeleteModal(true);
  }, []);

  const closeModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <BasicTitle>
        <Button icon={<LeftOutlined />} onClick={onCancelEdit} type="text" />
        {account ? account?.name : name}
      </BasicTitle>

      <Form
        name="account_edit"
        form={form}
        layout="vertical"
        requiredMark={false}
        style={{ maxWidth: "400px" }}
        onFinish={onSubmit}
        autoComplete="off"
        initialValues={account}
      >
        <Form.Item<TAccount>
          label="Название"
          name="name"
          rules={[
            { required: true, whitespace: true, message: "Введите название" }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TAccount>
          label="Юр. лицо"
          name="entity_id"
          rules={[
            {
              required: true,
              message: "Введите юр. лицо"
            }
          ]}
        >
          <Select options={entitiesOptions} />
        </Form.Item>

        <Form.Item<TAccount>
          label="Банк"
          name="bank_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите название банка"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TAccount>
          label="Расчетный счет"
          name="number"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "Расчетный счет должен состоять из цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите расчетный счет"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TAccount>
          label="Кор. счет"
          name="correspondent"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "Кор. счет должен состоять из цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите кор, счет"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TAccount>
          label="БИК"
          name="bik"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "БИК должен состоять из цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите БИК"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TAccount>
          label="ОКАТО"
          name="okato"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "ОКАТО должен состоять из цифр"
            },
            {
              required: true,
              whitespace: true,
              message: "Введите ОКАТО"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TAccount>
          label="Активен"
          name="status"
          rules={[
            {
              required: true,
              message: "Выберите одну из опций"
            }
          ]}
        >
          <Radio.Group>
            <Radio value={1}>Да</Radio>
            <Radio value={0}>Нет</Radio>
          </Radio.Group>
        </Form.Item>

        <ErrorAlert error={submitError} />
      </Form>
      <CTABlock>
        <Button
          type="primary"
          loading={submitIsLoading}
          form="account_edit"
          htmlType="submit"
        >
          Сохранить
        </Button>

        {account && (
          <Button
            icon={<DeleteFilled />}
            danger
            onClick={showModal}
            loading={deleteIsLoading}
          >
            Удалить счет
          </Button>
        )}
      </CTABlock>
      <ConfirmDelete
        open={openDeleteModal}
        onClose={closeModal}
        onDelete={onDeleteHandler}
        isLoading={deleteIsLoading}
        error={deleteError}
        title={`Удалить счет ${account?.name}?`}
        subtitle={
          "Вы уверены, что хотите удалить этот счет из вашего аккаунта? Все данные, связанные с этим счетом, будут безвозвратно удалены, и доступ к ним будет потерян. Подтвердить удаление?"
        }
      />
    </>
  );
};
