import { createAsyncThunk } from "@reduxjs/toolkit";
import { cabinetsGetActions } from ".";
import * as api from "api/cabinets";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";

export const getCabinets = createAsyncThunk<
  api.TGetCabinetsResponse,
  void,
  { state: RootState }
>("cabinet/getCabinets", (payload, { dispatch, rejectWithValue, getState }) => {
  dispatch(cabinetsGetActions.setLoading(true));
  dispatch(cabinetsGetActions.setError(null));

  return api
    .getCabinets()
    .then(({ data }) => {
      dispatch(cabinetsGetActions.setCabinets(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(cabinetsGetActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(cabinetsGetActions.setLoading(false));
    });
});
