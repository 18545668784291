import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import React, { ComponentProps, FC, useMemo } from "react";
import { TOfferForBuyer, TOfferForSeller } from "types";
import { offerStatusIdMapper } from "utils/mappers";

type TOfferStatusTagProps = ComponentProps<typeof Tag> & {
  status_id: (TOfferForBuyer | TOfferForSeller)["status_id"];
};

export const OfferStatusTag: FC<TOfferStatusTagProps> = ({
  status_id,
  ...props
}) => {
  const statusData = useMemo(() => offerStatusIdMapper[status_id], [status_id]);

  return (
    <Tag
      color={statusData?.color}
      icon={
        status_id === 127 ? (
          <CheckOutlined />
        ) : status_id === 107 || status_id === 128 ? (
          <CloseOutlined />
        ) : undefined
      }
      {...props}
    >
      {statusData?.shortName || status_id}
    </Tag>
  );
};
