export const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 1080,
  XL: 1280,
  XXL: 1600
};

export const DEVICE = {
  SM: `(max-width: ${BREAKPOINTS.SM}px)`,
  MD: `(max-width: ${BREAKPOINTS.MD}px)`,
  LG: `(max-width: ${BREAKPOINTS.LG}px)`,
  XL: `(max-width: ${BREAKPOINTS.XL}px)`,
  XXL: `(max-width: ${BREAKPOINTS.XXL}px)`,
  NOT_SM: `(min-width: ${BREAKPOINTS.SM + 1}px)`,
  NOT_MD: `(min-width: ${BREAKPOINTS.MD + 1}px)`,
  NOT_LG: `(min-width: ${BREAKPOINTS.LG + 1}px)`,
  NOT_XL: `(min-width: ${BREAKPOINTS.XL + 1}px)`,
  NOT_XXL: `(min-width: ${BREAKPOINTS.XXL + 1}px)`
};
