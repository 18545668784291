import { TLicense } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateLicense = (
  data: TUpdateLicenseRequest
): RequestPromise<TUpdateLicenseResponse> => {
  return apiRequest.post(`/license/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateLicenseRequest = TLicense;

export type TUpdateLicenseResponse = TLicense;
