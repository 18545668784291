import React, { FC, useCallback, useState } from "react";
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popover,
  Row,
  Select
} from "antd";
import { offerStatusIdOptions } from "constants/options";
import { Dayjs } from "dayjs";
import { DATE_FORMAT } from "constants/dates";
import {
  FiltersBlock,
  FiltersButton,
  FiltersContainer,
  IdInput,
  SearchButton,
  SearchRow
} from "./SearchForm.styles";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { TypographyTitleNoMargin } from "components/common/redesign";
import { useMediaQuery } from "react-responsive";
import { DEVICE } from "constants/breakpoints";

type TSearchFormProps = {
  onSearch: (value?: string) => void;
  isLoading: boolean;
};

type TSearchFormValues = {
  statusId: number;
  deliveryPlanDt: [Dayjs, Dayjs];
  applicationId: number;
};

export const SearchForm: FC<TSearchFormProps> = ({ onSearch, isLoading }) => {
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isSM = useMediaQuery({ query: DEVICE.SM });

  const [form] = Form.useForm<TSearchFormValues>();

  const [id, setId] = useState<string | undefined>(undefined);

  const hasFilters = !!Object.values(form.getFieldsValue())?.filter((i) => i)
    ?.length;

  const onIdChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setId(e?.currentTarget?.value);
  }, []);

  const onSearchHandler = useCallback(() => {
    const { statusId, deliveryPlanDt, applicationId } = form.getFieldsValue();

    onSearch(
      [
        statusId ? `status.id:${statusId}` : undefined,
        deliveryPlanDt
          ? [
              `deliveryPlanDt>${deliveryPlanDt[0].startOf("day").valueOf()}`,
              `deliveryPlanDt<${deliveryPlanDt[1].endOf("day").valueOf()}`
            ].join(",")
          : undefined,
        applicationId ? `application.id:${applicationId}` : undefined,
        id ? `id:${id}` : undefined
      ]
        .filter((item) => !!item)
        .join(",")
    );
  }, [form, id, onSearch]);

  const onClearForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  return (
    <>
      <SearchRow>
        <IdInput
          value={id}
          onChange={onIdChange}
          allowClear
          placeholder="ID предложения"
          prefix={<SearchOutlined />}
          size="large"
        />
        <FiltersBlock>
          <SearchButton
            loading={isLoading}
            form="offers_for_seller_search"
            onClick={onSearchHandler}
            size="large"
          >
            Поиск
          </SearchButton>
          <Popover
            trigger="click"
            placement="bottomRight"
            arrow={false}
            content={
              <FiltersContainer>
                <TypographyTitleNoMargin level={5}>
                  Фильтры
                </TypographyTitleNoMargin>
                <Form
                  name="offers_for_seller_search"
                  form={form}
                  // labelCol={{ span: 8 }}
                  // wrapperCol={{ span: 28 }}
                  // onFinish={onSearchHandler}
                  // onFinishFailed={onFinishFailed}
                  // autoComplete="off"
                  labelWrap
                  // colon={false}
                  layout="vertical"
                  className="withRows"
                  size="large"
                >
                  <Row gutter={isSM ? 12 : 24} justify="space-between">
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Статус"
                        name="statusId"
                        layout="vertical"
                      >
                        <Select options={offerStatusIdOptions} allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="ID заявки"
                        name="applicationId"
                        rules={[
                          {
                            pattern: new RegExp(/^\d{1,}$/),
                            message: "Номер заявки должен состоять из цифр"
                          }
                        ]}
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Дата поставки"
                        name="deliveryPlanDt"
                      >
                        <DatePicker.RangePicker
                          format={DATE_FORMAT}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12} />

                    <Col span={isSM ? 24 : undefined}>
                      <Form.Item>
                        <Button
                          type="text"
                          style={{ color: "#BFBFBF", width: "100%" }}
                          loading={isLoading}
                          form="offers_for_seller_search"
                          onClick={onClearForm}
                        >
                          Очистить все
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : 8}>
                      <Form.Item>
                        <Button
                          type="primary"
                          style={{ width: "100%" }}
                          loading={isLoading}
                          form="offers_for_seller_search"
                          onClick={onSearchHandler}
                        >
                          Применить
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </FiltersContainer>
            }
          >
            <Badge dot={hasFilters}>
              <FiltersButton icon={<FilterOutlined />} size="large">
                {isLG ? undefined : "Фильтры"}
              </FiltersButton>
            </Badge>
          </Popover>
        </FiltersBlock>
      </SearchRow>
    </>
  );
};
