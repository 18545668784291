import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApplicationByIdActions } from ".";
import * as api from "api/applications";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getApplicationById = createAsyncThunk<
  api.TGetApplicationByIdResponse,
  api.TGetApplicationByIdRequest
>(
  "applications/getApplicationById",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(ApplicationByIdActions.setLoading(true));
    dispatch(ApplicationByIdActions.setError(null));
    return api
      .getApplicationById(payload)
      .then(({ data }) => {
        dispatch(ApplicationByIdActions.setApplication(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(ApplicationByIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(ApplicationByIdActions.setLoading(false));
      });
  }
);
