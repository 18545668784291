import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationAddState } from "./types";

import { RequestError, TApplication } from "types";

const initialState: TApplicationAddState = {
  isLoading: false,
  error: null,
  application: undefined
};

export const ApplicationAddSlice = createSlice({
  name: "application/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApplication: (state, { payload }: PayloadAction<TApplication>) => {
      state.application = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const applicationAddActions = ApplicationAddSlice.actions;
export const applicationAddReducer = ApplicationAddSlice.reducer;

export * as applicationAddSelectors from "./selectors";
