import React, { useCallback, useEffect, useMemo } from "react";
import { ErrorAlert } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import {
  approvementsByUserIdActions,
  approvementsByUserIdSelectors
} from "store/approvements/byUserId";
import {
  approvementUpdateActions,
  approvementUpdateSelectors
} from "store/approvements/update";
import { getApprovementsByUserId } from "store/approvements/byUserId/thunk";
import { updateApprovement } from "store/approvements/update/thunk";
import { Wrapper } from "../../Approvements.styles";
import {
  ApprovementsTable,
  BasicTitle,
  TShortenedApprovement
} from "components/common/redesign";
import { applicationApprovementTypeIdMapper } from "utils/mappers";

export const GeneralApprovements = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { approvements } = useAppSelector(
    approvementsByUserIdSelectors.getState
  );
  const { isLoading, error } = useAppSelector(
    approvementUpdateSelectors.getState
  );

  const shortenedApprovements: TShortenedApprovement[] = useMemo(
    () =>
      (approvements || []).map((approvement) => ({
        name: [
          applicationApprovementTypeIdMapper[approvement?.approvement_type_id]
            ?.shortName,
          [`№ ${approvement?.parent_id}`, approvement?.parent_name].join(" - ")
        ].join(" "),
        approvement_id: approvement?.approvement_id,
        type:
          approvement?.approvement_type_id === 119 ? "applications" : "offers",
        parent_id: approvement?.parent_id
      })),
    [approvements]
  );

  const getApprovements = useCallback(() => {
    user && dispatch(getApprovementsByUserId(user?.user_id));
  }, [dispatch, user]);

  const onUpdate = useCallback(
    (approvement_id: number, approvement_status_id: number) => {
      dispatch(updateApprovement({ approvement_id, approvement_status_id }))
        .unwrap()
        .then(() => {
          getApprovements();
        });
    },
    [dispatch, getApprovements]
  );

  useEffect(() => {
    getApprovements();
  }, [dispatch, getApprovements]);

  useEffect(() => {
    return () => {
      dispatch(approvementsByUserIdActions.clearState());
      dispatch(approvementUpdateActions.clearState());
    };
  }, [dispatch]);
  return (
    <Wrapper>
      <BasicTitle>Согласования</BasicTitle>

      <ErrorAlert error={error} />

      <ApprovementsTable
        approvements={shortenedApprovements}
        onUpdate={onUpdate}
        isLoading={isLoading}
        okCode={109}
        cancelCode={110}
        pagination={false}
      />
    </Wrapper>
  );
};
