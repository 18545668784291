import { createAsyncThunk } from "@reduxjs/toolkit";
import { fileByIdActions } from ".";
import * as api from "api/files";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getFileById = createAsyncThunk<
  api.TGetFileByIdResponse,
  api.TGetFileByIdRequest
>("files/getFileById", (payload, { dispatch, rejectWithValue }) => {
  dispatch(fileByIdActions.setLoading(true));
  dispatch(fileByIdActions.setError(null));

  return api
    .getFileById(payload)
    .then(({ data }) => {
      dispatch(fileByIdActions.setFile(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(fileByIdActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(fileByIdActions.setLoading(false));
    });
});
