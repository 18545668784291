import React, { FC, useMemo } from "react";
import { TOfferForBuyer, TOfferForSeller } from "types";
import { Pagination, Table, TableColumnsType, Typography } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import { toDisplayDate } from "utils/dates";
import { Link } from "react-router-dom";
import {
  PriceBlock,
  TableScrollWrapper,
  TableWrapper
} from "./OffersTable.styles";
import { Empty } from "../Empty/Empty";
import { TypographyTitleNoMargin } from "../TypographyTitleNoMargin/TypographyTitleNoMargin";
import { countOfferPositionsPrice } from "utils/countOfferPositionsPrice";
import { padFractionalZeros, splitThousands } from "utils/numbers";
import { OfferStatusTag } from "../OfferStatusTag/OfferStatusTag";

type TOfferForTable = {
  offer_id: number;
  create_dt: string;
  status_id: number;
  creator_name: string;
  positions_length: number;
  offer_comment?: string;
  seller_entity_name: string;
  price: {
    price: number;
    priceVAT: number;
  };
};

type TOffersTableProps = {
  offers?: TOfferForBuyer[] | TOfferForSeller[];
  pagination?: TablePaginationConfig | false;
  // forSeller?: boolean;
  fromApplication?: boolean;
};

export const OffersTable: FC<TOffersTableProps> = ({
  offers,
  pagination,
  // forSeller,
  fromApplication
}) => {
  const offersForTable: TOfferForTable[] = useMemo(
    () =>
      (offers || []).map((offer) => {
        return {
          offer_id: offer?.offer_id,
          application_id: offer?.application_id,
          create_dt: offer?.create_dt,
          status_id: offer?.status_id,
          creator_name: offer?.creator?.full_name,
          positions_length: offer?.positions?.length || 0,
          offer_comment: offer?.offer_comment,
          seller_entity_name: offer?.seller_entity?.full_name,
          price: countOfferPositionsPrice(offer?.positions)
        };
      }),
    [offers]
  );

  const columns: TableColumnsType<TOfferForTable> = useMemo(
    () => [
      {
        title: "Наименование",
        key: "offer_name",
        render: (value: TOfferForTable) => (
          <Link
            to={
              fromApplication
                ? `${"../../offers"}/${value?.offer_id}`
                : `${value?.offer_id}`
            }
          >
            {value?.offer_id}{" "}
            {value?.create_dt && `от ${toDisplayDate(value?.create_dt)}`}
          </Link>
        ),
        minWidth: 170
      },
      ...(!fromApplication
        ? [
            {
              title: "Заявка",
              dataIndex: "application_id",
              render: (value: number) => (
                <Link to={`../applications/${value}`}>{value}</Link>
              ),
              minWidth: 100
            }
          ]
        : []),
      {
        title: "Статус",
        dataIndex: "status_id",
        render: (value: number) => {
          return <OfferStatusTag status_id={value} />;
        },
        minWidth: 224
      },
      {
        title: "Юр. лицо",
        dataIndex: "seller_entity_name",
        minWidth: 218
      },
      {
        title: "Создатель",
        dataIndex: "creator_name",
        minWidth: 160
      },
      {
        title: "Позиций",
        dataIndex: "positions_length",
        minWidth: 112
      },
      {
        title: "Стоимость",
        dataIndex: "price",
        minWidth: 240,
        render: (value: TOfferForTable["price"]) => (
          <PriceBlock>
            <Typography.Text>
              {splitThousands(padFractionalZeros(value?.priceVAT))} руб.
            </Typography.Text>
            <Typography.Text type="secondary">
              без НДС {splitThousands(padFractionalZeros(value?.price))} руб.
            </Typography.Text>
          </PriceBlock>
        )
      },
      {
        title: "Комментарий",
        dataIndex: "offer_comment",
        minWidth: 278
      }
    ],
    [fromApplication]
  );

  return (
    <TableWrapper>
      {fromApplication && (
        <TypographyTitleNoMargin level={5}>Предложения</TypographyTitleNoMargin>
      )}

      {offersForTable?.length ? (
        <>
          <TableScrollWrapper>
            <Table
              dataSource={offersForTable}
              columns={columns}
              pagination={false}
              rowKey={(row) => row?.offer_id}
            />
          </TableScrollWrapper>
          {pagination && <Pagination {...pagination} align="end" />}
        </>
      ) : (
        <Empty
          description="Пока нет ни одного предложения"
          style={{ flex: 1 }}
        />
      )}
    </TableWrapper>
  );
};
