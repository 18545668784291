import React, { FC, useMemo } from "react";
import { Panel } from "..";
import { Button, Checkbox, Form, Input } from "antd";
import { RequestError, TLicenseType } from "types";
import { ErrorAlert } from "..";

type TLicenseTypeEditFormProps = {
  licenseType?: TLicenseType;
  onSubmit: (values: TLicenseType) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const LicenseTypeEditForm: FC<TLicenseTypeEditFormProps> = ({
  licenseType,
  onSubmit,
  isLoading,
  error
}) => {
  const initialValues: TLicenseType | undefined = useMemo(
    () =>
      licenseType ||
      ({
        is_active: false
      } as TLicenseType),
    [licenseType]
  );

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={initialValues}
      >
        <Form.Item<TLicenseType>
          label="Тип лицензии"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите тип заявки"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TLicenseType>
          label="Описание"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите описание"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TLicenseType>
          label="Действующая"
          name="is_active"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
