export const offerUpdateForSellerStatusOptions: Record<number, number[]> = {
  99: [123, 107],
  123: [101, 107, 99],
  101: [103, 107, 99],
  103: [107, 99],
  102: [],
  105: [107, 99, 126, 125],
  124: [107],
  125: [127, 126, 128],
  126: [127, 125, 128],
  107: [],
  127: [],
  128: []
};

export const offerUpdateForBuyerStatusOptions: Record<number, number[]> = {
  99: [],
  123: [],
  101: [],
  103: [124, 102],
  102: [],
  105: [],
  124: [105, 102],
  125: [],
  126: [],
  107: [],
  127: [],
  128: []
};
