import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import {
  ApplicationForBuyer,
  ApplicationForSeller,
  ApplicationsForBuyer,
  ApplicationsForSeller
} from "./components";
import { NotFound } from "components/pages/old";
import { Messages } from "../Messages/Messages";

export const Applications = () => {
  const { user } = useAppSelector(userSelectors.getState);

  return (
    <Routes>
      <Route
        index
        element={
          user?.cabinet?.is_seller ? (
            <ApplicationsForSeller />
          ) : (
            <ApplicationsForBuyer />
          )
        }
      />
      <Route path=":application_id">
        <Route
          index
          element={
            user?.cabinet?.is_seller ? (
              <ApplicationForSeller />
            ) : (
              <ApplicationForBuyer />
            )
          }
        />
        {!user?.cabinet?.is_seller && (
          <Route path="messages/*" element={<Messages fromApplication />} />
        )}
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
