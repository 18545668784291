import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatRefreshActions } from ".";
import * as api from "api/chat";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { chatsByApplicationIdActions } from "../byApplicationId";
import { chatsActions } from "..";

export const refreshChat = createAsyncThunk<
  api.TRefreshChatResponse,
  api.TRefreshChatRequest & {
    byApplication?: boolean;
  }
>("chat/refreshChat", (payload, { dispatch, rejectWithValue, getState }) => {
  dispatch(chatRefreshActions.setLoading(true));
  dispatch(chatRefreshActions.setError(null));

  const { byApplication, ...rest } = payload;

  return api
    .refreshChat(rest)
    .then(({ data }) => {
      dispatch(
        (byApplication
          ? chatsByApplicationIdActions
          : chatsActions
        ).setMessages({
          messages: data,
          id: payload?.chatId
        })
      );
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(chatRefreshActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(chatRefreshActions.setLoading(false));
    });
});
