import { TFileMeta } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const deleteFile = (
  id: TDeleteFileRequest
): RequestPromise<TDeleteFileResponse> => {
  return apiRequest.post(`/files/delete/${id}`, undefined, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TDeleteFileRequest = number; //file_meta_id

export type TDeleteFileResponse = TFileMeta;
