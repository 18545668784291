import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TFileAddState } from "./types";

import { RequestError, TFileMeta } from "types";

const initialState: TFileAddState = {
  isLoading: false,
  error: null,
  file: undefined
};

export const FileAddSlice = createSlice({
  name: "files/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setFile: (state, { payload }: PayloadAction<TFileMeta>) => {
      state.file = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const fileAddActions = FileAddSlice.actions;
export const fileAddReducer = FileAddSlice.reducer;

export * as fileAddSelectors from "./selectors";
