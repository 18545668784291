import axios, { AxiosPromise, AxiosRequestConfig } from "axios";

const apiEndpoint =
  process.env.NODE_ENV === "development"
    ? "/api"
    : process.env.REACT_APP_API_URL;

export const apiRequest = {
  get: (url: string, config?: AxiosRequestConfig): AxiosPromise =>
    axios.get(`${apiEndpoint}${url}`, config),
  post: (
    url: string,
    body?: Record<string, unknown> | FormData,
    config?: AxiosRequestConfig
  ): AxiosPromise => axios.post(`${apiEndpoint}${url}`, body, config)
};

export type RequestPromise<T> = AxiosPromise<T>;
export type RequestConfig<T> = AxiosRequestConfig<T>;
