import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AlertWrapper,
  ApplicationWrapper
} from "./ApplicationForSeller.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import { useNavigate, useParams } from "react-router-dom";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import { entitiesByCabinetIdSelectors } from "store/entities/byCabinetId";
import {
  filesByObjectActions,
  filesByObjectSelectors
} from "store/files/byObject";
import { userSelectors } from "store/user";
import { Alert, Button } from "antd";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { EFileType, TOfferForSellerRequest } from "types";
import { getFilesByObject } from "store/files/byObject/thunk";
import { ApplicationData, OfferEditForm } from "components/common/redesign";
import {
  ApplicationForSellerByIdActions,
  applicationForSellerByIdSelectors
} from "store/applicationsForSeller/byId";
import {
  offersForSellerByApplicationIdActions,
  offersForSellerByApplicationIdSelectors
} from "store/offersForSeller/byApplicationId";
import { offerForSellerAddSelectors } from "store/offersForSeller/add";
import { getApplicationForSellerById } from "store/applicationsForSeller/byId/thunk";
import { getOfferForSellerByApplicationId } from "store/offersForSeller/byApplicationId/thunk";
import { addOfferForSeller } from "store/offersForSeller/add/thunk";

export const ApplicationForSeller = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);
  const { application } = useAppSelector(
    applicationForSellerByIdSelectors.getState
  );
  const { offersForSeller } = useAppSelector(
    offersForSellerByApplicationIdSelectors.getState
  );
  const { files } = useAppSelector(filesByObjectSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);

  const { isLoading: offerAddIsLoading, error: offerAddError } = useAppSelector(
    offerForSellerAddSelectors.getState
  );

  const [isAddingOffer, setIsAddingOffer] = useState(false);

  const { application_id } = useParams<{ application_id: string }>();

  const canAddOffer = useMemo(
    () =>
      user?.entities?.some(
        (entity) =>
          entity?.role_id >= 2 ||
          (entity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
            user?.cabinet_role_id === 2)
      ) || user?.is_admin,
    [user]
  );

  const entitiesForOffer = useMemo(
    () =>
      user?.is_admin
        ? entities
        : user?.entities
            ?.filter(
              (entity) =>
                entity?.role_id >= 2 ||
                (entity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
                  user?.cabinet_role_id === 2)
            )
            .map((entity) => entity?.entity),
    [entities, user]
  );

  const getApplication = useCallback(
    (application_id: number) => {
      dispatch(getApplicationForSellerById(application_id));
    },
    [dispatch]
  );
  const getOffers = useCallback(
    (application_id: number) => {
      dispatch(getOfferForSellerByApplicationId(application_id));
    },
    [dispatch]
  );
  const getFiles = useCallback(
    (application_id: number) => {
      dispatch(
        getFilesByObject({
          id: application_id,
          type: EFileType.APPLICATION
        })
      );
    },
    [dispatch]
  );
  const getEntities = useCallback(
    (cabinet_id: number) => {
      dispatch(getEntitiesByCabinetId(cabinet_id));
    },
    [dispatch]
  );
  const getAccounts = useCallback(
    (cabinet_id: number) => {
      dispatch(getAccountsByCabinetId(cabinet_id));
    },
    [dispatch]
  );

  const onAddOffer = useCallback(() => {
    setIsAddingOffer(true);
  }, []);
  const onCancelAddOffer = useCallback(() => {
    setIsAddingOffer(false);
  }, []);

  const onAddOfferToApplication = useCallback(
    (values: TOfferForSellerRequest) => {
      dispatch(addOfferForSeller(values))
        .unwrap()
        .then((offer) => {
          onCancelAddOffer();
          navigate(`../../offers/${offer?.offer_id}`);
        });
    },
    [dispatch, navigate, onCancelAddOffer]
  );

  const onGoBack = useCallback(() => {
    navigate("../");
  }, [navigate]);

  useEffect(() => {
    application_id && getApplication(Number(application_id));
  }, [application_id, getApplication]);
  useEffect(() => {
    application && getOffers(application?.application_id);
  }, [application, getOffers]);
  useEffect(() => {
    application && getFiles(application?.application_id);
  }, [application, getFiles]);
  useEffect(() => {
    !entities && user && getEntities(user?.cabinet?.cabinet_id);
  }, [dispatch, entities, getEntities, user]);
  useEffect(() => {
    !accounts && user && getAccounts(user?.cabinet?.cabinet_id);
  }, [accounts, dispatch, getAccounts, user]);

  useEffect(() => {
    return () => {
      dispatch(ApplicationForSellerByIdActions.clearState());
      dispatch(offersForSellerByApplicationIdActions.clearState());
      dispatch(filesByObjectActions.clearState());
    };
  }, [dispatch]);

  return application ? (
    isAddingOffer ? (
      <OfferEditForm
        application={application}
        entitiesForOffer={entitiesForOffer}
        user={user}
        accounts={accounts}
        onSubmit={onAddOfferToApplication}
        isLoading={offerAddIsLoading}
        error={offerAddError}
        onCancel={onCancelAddOffer}
      />
    ) : (
      <ApplicationWrapper>
        <ApplicationData
          application={application}
          files={files}
          getFiles={() => getFiles(application?.application_id)}
          offers={offersForSeller}
          entity={application?.buyer_entity}
          offersLength={offersForSeller?.length}
          onAddOffer={onAddOffer}
          onGoBack={onGoBack}
          forSeller
          withOffer={canAddOffer}
          withMessages={canAddOffer}
        />
      </ApplicationWrapper>
    )
  ) : (
    <AlertWrapper>
      <Alert message="Данные по заявке отсутствуют" showIcon />

      <Button onClick={onGoBack}>Назад</Button>
    </AlertWrapper>
  );
};
