import React from "react";
import { userSelectors } from "store/user";
import { useAppSelector } from "store/store";
import { Tabs, TabsProps } from "antd";
import {
  GeneralApprovements,
  OfferApprovementsForBuyer,
  OfferApprovementsForSeller
} from "./components";
import { useNavigate, useParams } from "react-router-dom";
import { ShopFilled, TagsFilled } from "@ant-design/icons";

export const Approvements = () => {
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);

  const { approvements_tab } = useParams<{ approvements_tab: string }>();

  const tabs: TabsProps["items"] = [
    ...(!user?.cabinet?.is_seller
      ? [
          {
            key: "applications",
            label: "Заявки",
            icon: <ShopFilled />,
            children: <GeneralApprovements />
          }
        ]
      : []),
    {
      key: "offers",
      label: "Предложения",
      icon: <TagsFilled />,
      children: user?.cabinet?.is_seller ? (
        <OfferApprovementsForSeller />
      ) : (
        <OfferApprovementsForBuyer />
      )
    }
  ];

  return (
    <>
      <Tabs
        items={tabs}
        destroyInactiveTabPane
        activeKey={approvements_tab}
        onChange={(key) => {
          navigate(`../${key}`);
        }}
        size="small"
        style={{ marginTop: "-16px" }}
      />
    </>
  );
};
