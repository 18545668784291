import React, { FC } from "react";
import { RequestError } from "types";
import { Alert } from "antd";

type TErrorAlertProps = {
  error?: RequestError | null;
};

export const ErrorAlert: FC<TErrorAlertProps> = ({ error }) => {
  return error ? (
    <Alert message={error?.response?.data?.message} type="error" showIcon />
  ) : null;
};
