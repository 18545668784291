import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLicenseTypeDeleteState } from "./types";

import { RequestError, TLicenseType } from "types";

const initialState: TLicenseTypeDeleteState = {
  isLoading: false,
  error: null,
  licenseType: undefined
};

export const LicenseTypeDeleteSlice = createSlice({
  name: "license/type/delete",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicenseType: (state, { payload }: PayloadAction<TLicenseType>) => {
      state.licenseType = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const licenseTypeDeleteActions = LicenseTypeDeleteSlice.actions;
export const licenseTypeDeleteReducer = LicenseTypeDeleteSlice.reducer;

export * as licenseTypeDeleteSelectors from "./selectors";
