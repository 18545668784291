import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { licenseTypesGetSelectors } from "store/licenses/type/get";
import {
  licenseTypeAddActions,
  licenseTypeAddSelectors
} from "store/licenses/type/add";
import {
  licenseTypeUpdateActions,
  licenseTypeUpdateSelectors
} from "store/licenses/type/update";
import {
  licenseTypeDeleteActions,
  licenseTypeDeleteSelectors
} from "store/licenses/type/delete";
import { TLicenseType } from "types";
import { getLicenseTypes } from "store/licenses/type/get/thunk";
import { addLicenseType } from "store/licenses/type/add/thunk";
import { updateLicenseType } from "store/licenses/type/update/thunk";
import { deleteLicenseType } from "store/licenses/type/delete/thunk";
import { Wrapper } from "./LicenseTypesTab.styles";
import { Button } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { LicenseTypeEditForm, LicenseTypesTable } from "components/common/old";

export const LicenseTypesTab = () => {
  const dispatch = useAppDispatch();

  const { licenseTypes } = useAppSelector(licenseTypesGetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    licenseTypeAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    licenseTypeUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    licenseTypeDeleteSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);
  const [licenseTypeInEdit, setLicenseTypeInEdit] = useState<
    TLicenseType | undefined
  >(undefined);

  const getLicenseTypesArr = useCallback(() => {
    dispatch(getLicenseTypes());
  }, [dispatch]);

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const licenseType = licenseTypes?.find(
        (licenseType) => licenseType?.license_type_id === id
      );
      setLicenseTypeInEdit(licenseType);
    },
    [licenseTypes]
  );
  const onCancelEdit = useCallback(() => {
    setLicenseTypeInEdit(undefined);
  }, []);

  const onAddLicenseType = useCallback(
    (values: TLicenseType) => {
      dispatch(addLicenseType(values))
        .unwrap()
        .then(() => {
          onCancelAdd();
          getLicenseTypesArr();
        });
    },
    [dispatch, getLicenseTypesArr, onCancelAdd]
  );
  const onEditLicenseType = useCallback(
    (values: TLicenseType) => {
      dispatch(updateLicenseType({ ...licenseTypeInEdit, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          getLicenseTypesArr();
        });
    },
    [dispatch, getLicenseTypesArr, licenseTypeInEdit, onCancelEdit]
  );
  const onDeleteLicenseType = useCallback(
    (id: number) => {
      dispatch(deleteLicenseType(id))
        .unwrap()
        .then(() => {
          getLicenseTypesArr();
        });
    },
    [dispatch, getLicenseTypesArr]
  );

  useEffect(() => {
    !licenseTypes && getLicenseTypesArr();
  }, [getLicenseTypesArr, licenseTypes]);

  useEffect(() => {
    return () => {
      dispatch(licenseTypeAddActions.clearState());
      dispatch(licenseTypeUpdateActions.clearState());
      dispatch(licenseTypeDeleteActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {!licenseTypeInEdit && (
        <Button
          icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAdding ? onCancelAdd : onAdd}
        >
          {isAdding ? "Отмена" : "Добавить"}
        </Button>
      )}

      {licenseTypeInEdit ? (
        <>
          <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
            Отмена
          </Button>
          <LicenseTypeEditForm
            licenseType={licenseTypeInEdit}
            onSubmit={onEditLicenseType}
            isLoading={updateIsLoading}
            error={updateError}
          />
        </>
      ) : isAdding ? (
        <LicenseTypeEditForm
          onSubmit={onAddLicenseType}
          isLoading={addIsLoading}
          error={addError}
        />
      ) : (
        <LicenseTypesTable
          licenseTypes={licenseTypes}
          onEdit={onEdit}
          onDelete={onDeleteLicenseType}
          isDeleting={deleteIsLoading}
        />
      )}
    </Wrapper>
  );
};
