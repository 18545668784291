import { TOfferForSeller, TOfferForSellerRequest } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateOfferForSeller = (
  data: TUpdateOfferForSellerRequest
): RequestPromise<TUpdateOfferForSellerResponse> => {
  return apiRequest.post(`/offers-for-sellers/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateOfferForSellerRequest = TOfferForSellerRequest;

export type TUpdateOfferForSellerResponse = TOfferForSeller;
