import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TChatGetState } from "./types";

import { RequestError } from "types";

const initialState: TChatGetState = {
  isLoading: false,
  error: null
};

export const ChatGetSlice = createSlice({
  name: "chat/Get",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const chatGetActions = ChatGetSlice.actions;
export const chatGetReducer = ChatGetSlice.reducer;

export * as chatGetSelectors from "./selectors";
