import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import {
  OffersForSeller,
  OffersForBuyer,
  OfferForSeller,
  OfferForBuyer
} from "./components";

export const Offers = () => {
  const { user } = useAppSelector(userSelectors.getState);

  return (
    <Routes>
      <Route
        index
        element={
          user?.cabinet?.is_seller ? <OffersForSeller /> : <OffersForBuyer />
        }
      />
      <Route
        path=":offer_id"
        element={
          user?.cabinet?.is_seller ? <OfferForSeller /> : <OfferForBuyer />
        }
      />
    </Routes>
  );
};
