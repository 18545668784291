import React, { FC, useMemo } from "react";
import { TOfferPosition } from "types";
import Table, { ColumnsType } from "antd/es/table";
import {
  applicationPositionCategoryIdMapper,
  applicationPositionUnitTypeIdMapper
} from "utils/mappers";
import { padFractionalZeros, splitThousands } from "utils/numbers";
import { countPrice } from "utils/countPrice";

type TOfferPositionsTableProps = {
  positions?: TOfferPosition[];
};

export const OfferPositionsTable: FC<TOfferPositionsTableProps> = ({
  positions
}) => {
  const positionsColumns: ColumnsType<TOfferPosition> = useMemo(
    () => [
      {
        title: "Наименование",
        dataIndex: "position_name"
      },
      {
        title: "Аналог",
        dataIndex: "analogue_flag",
        render: (value: boolean) => (value ? "Да" : "Нет")
      },
      {
        title: "Количество",
        key: "unit_amount",
        render: (value: TOfferPosition) =>
          [
            value?.unit_amount?.toString()?.replaceAll(".", ","),
            applicationPositionUnitTypeIdMapper[value?.unit_type_id]
              ?.shortName || value?.unit_type_id
          ].join(" ")
      },
      {
        title: "Товарная категория",
        dataIndex: "category_id",
        render: (value: number) =>
          applicationPositionCategoryIdMapper[value] || value
      },
      {
        title: "Цена за единицу",
        dataIndex: "unit_price",
        render: (value: number) => splitThousands(padFractionalZeros(value))
      },
      {
        title: "Скидка на позицию",
        dataIndex: "discount",
        render: (value: number) => splitThousands(padFractionalZeros(value))
      },
      {
        title: "НДС",
        dataIndex: "vat",
        render: (value: number) => `${value}%`
      },
      {
        title: "Общая стоимость позиции",
        key: "total_price",
        render: ({ unit_amount, unit_price, discount, vat }: TOfferPosition) =>
          splitThousands(
            padFractionalZeros(
              countPrice(unit_amount, unit_price, discount, vat)
            )
          )
      },
      {
        title: "Комментарий",
        dataIndex: "position_comment"
      }
    ],
    []
  );
  return (
    <Table
      dataSource={positions}
      columns={positionsColumns}
      pagination={false}
      scroll={{ x: "1400px" }}
    />
  );
};
