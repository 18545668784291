import { TProject } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const deleteProject = (
  id: TDeleteProjectRequest
): RequestPromise<TDeleteProjectResponse> => {
  return apiRequest.post(`/project/delete/${id}`, undefined, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TDeleteProjectRequest = number;

export type TDeleteProjectResponse = TProject;
