import { createAsyncThunk } from "@reduxjs/toolkit";
import { offerForBuyerByIdActions } from "./byId";
import { offersForBuyerGetActions } from "./get";
import { offerForBuyerUpdateStatusActions } from "./update_status";
import { offersForBuyerByApplicationIdActions } from "./byApplicationId";

export const clearOffersForBuyer = createAsyncThunk<void, void>(
  "offersForBuyer/clearOffersForBuyer",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(offerForBuyerByIdActions.clearState());
    dispatch(offersForBuyerGetActions.clearState());
    dispatch(offerForBuyerUpdateStatusActions.clearState());
    dispatch(offersForBuyerByApplicationIdActions.clearState());
  }
);
