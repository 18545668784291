import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/root/App/App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ConfigProvider } from "antd";

import locale from "antd/es/locale/ru_RU";
import "dayjs/locale/ru";
import { BREAKPOINTS } from "constants/breakpoints";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ConfigProvider
    locale={locale}
    theme={{
      token: {
        screenXL: BREAKPOINTS.XL,
        screenXLMin: BREAKPOINTS.XL,
        screenLGMax: BREAKPOINTS.XL - 1,
        screenLG: BREAKPOINTS.LG,
        screenLGMin: BREAKPOINTS.LG,
        screenMDMax: BREAKPOINTS.LG - 1
      }
    }}
  >
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </ConfigProvider>
  // </React.StrictMode>
);
