// import { MOCKED_USER } from "./mock";
import { TPerson } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getUser = (): RequestPromise<TGetUserResponse> => {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({ data: MOCKED_USER });
  //   }, 1000);
  // }) as never;

  return apiRequest.get(`/users/get`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetUserResponse = TPerson;
