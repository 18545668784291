import { createAsyncThunk } from "@reduxjs/toolkit";
import { accountByEntityIdActions } from ".";
import * as api from "api/accounts";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getAccountsByEntityId = createAsyncThunk<
  api.TByEntityIdAccountResponse,
  api.TByEntityIdAccountRequest
>("account/getAccountByEntityId", (payload, { dispatch, rejectWithValue }) => {
  dispatch(accountByEntityIdActions.setLoading(true));
  dispatch(accountByEntityIdActions.setError(null));
  return api
    .getAccountsByEntityId(payload)
    .then(({ data }) => {
      dispatch(
        accountByEntityIdActions.setAccounts({
          entity_id: payload,
          accounts: data
        })
      );
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(accountByEntityIdActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(accountByEntityIdActions.setLoading(false));
    });
});
