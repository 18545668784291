import { createAsyncThunk } from "@reduxjs/toolkit";
import { projectUpdateActions } from ".";
import * as api from "api/project";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const updateProject = createAsyncThunk<
  api.TUpdateProjectResponse,
  api.TUpdateProjectRequest
>("project/updateProject", (payload, { dispatch, rejectWithValue }) => {
  dispatch(projectUpdateActions.setLoading(true));
  dispatch(projectUpdateActions.setError(null));
  return api
    .updateProject(payload)
    .then(({ data }) => {
      dispatch(projectUpdateActions.setProject(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(projectUpdateActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(projectUpdateActions.setLoading(false));
    });
});
