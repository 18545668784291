import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationsGetState } from "./types";

import { RequestError, TApplication } from "types";
import { DEFAULT_PAGE_SIZE } from "constants/pagination";

const initialState: TApplicationsGetState = {
  isLoading: false,
  error: null,
  applications: undefined,
  totalElements: undefined,
  page: 1,
  size: DEFAULT_PAGE_SIZE,
  search: undefined
};

export const ApplicationsGetSlice = createSlice({
  name: "application/get",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApplications: (state, { payload }: PayloadAction<TApplication[]>) => {
      state.applications = payload;
    },
    setTotalElements: (state, { payload }: PayloadAction<number>) => {
      state.totalElements = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string | undefined>) => {
      state.search = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const applicationsGetActions = ApplicationsGetSlice.actions;
export const applicationsGetReducer = ApplicationsGetSlice.reducer;

export * as applicationsGetSelectors from "./selectors";
