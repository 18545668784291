import { TOfferForSeller } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getOffersForSellerByApplicationId = (
  id: TGetOffersForSellerByApplicationIdRequest
): RequestPromise<TGetOffersForSellerByApplicationIdResponse> => {
  return apiRequest.get(`/offers-for-sellers/applications/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetOffersForSellerByApplicationIdRequest = number;

export type TGetOffersForSellerByApplicationIdResponse = TOfferForSeller[];
