import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLicenseUpdateState } from "./types";

import { RequestError, TLicense } from "types";

const initialState: TLicenseUpdateState = {
  isLoading: false,
  error: null,
  license: undefined
};

export const LicenseUpdateSlice = createSlice({
  name: "license/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicense: (state, { payload }: PayloadAction<TLicense>) => {
      state.license = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const licenseUpdateActions = LicenseUpdateSlice.actions;
export const licenseUpdateReducer = LicenseUpdateSlice.reducer;

export * as licenseUpdateSelectors from "./selectors";
