import { TEntity } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateEntity = (
  data: TUpdateEntityRequest
): RequestPromise<TUpdateEntityResponse> => {
  return apiRequest.post(`/entities/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateEntityRequest = TEntity;

export type TUpdateEntityResponse = TEntity;
