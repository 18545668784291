import { BasicTitle, Empty } from "components/common/redesign";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { ChatsContainer, Wrapper } from "./Messages.styles";
import { userSelectors } from "store/user";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";

import { Chat, ChatsList } from "./components";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import {
  chatsByApplicationIdActions,
  chatsByApplicationIdSelectors
} from "store/chat/byApplicationId";
import { getChatsByApplicationId } from "store/chat/byApplicationId/thunk";
import { chatsActions, chatsStateSelectors } from "store/chat";
import { getChats } from "store/chat/thunk";

type TMessagesProps = {
  fromApplication?: boolean;
};

export const Messages: FC<TMessagesProps> = ({ fromApplication }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { application_id } = useParams<{ application_id: string }>();

  const { user } = useAppSelector(userSelectors.getState);

  const forSeller = useMemo(() => user?.cabinet?.is_seller, [user]);

  const { chats, curChatId } = useAppSelector(
    (fromApplication ? chatsByApplicationIdSelectors : chatsStateSelectors)
      .getState
  );

  const getChatsArr = useCallback(
    (id?: number) => {
      if (fromApplication) {
        dispatch(getChatsByApplicationId(id));
      } else {
        dispatch(getChats());
      }
    },
    [dispatch, fromApplication]
  );

  const onGoBack = useCallback(() => {
    navigate("../");
  }, [navigate]);

  useEffect(() => {
    if (fromApplication) {
      getChatsArr(application_id ? Number(application_id) : undefined);
    } else {
      getChatsArr();
    }
  }, [application_id, fromApplication, getChatsArr, user]);

  useEffect(() => {
    dispatch(chatsActions.clearState());
    dispatch(chatsByApplicationIdActions.clearState());
  }, [dispatch]);

  return (
    <Wrapper>
      <BasicTitle>
        {fromApplication && (
          <Button icon={<LeftOutlined />} onClick={onGoBack} type="text" />
        )}
        Сообщения{" "}
        {fromApplication && (
          <BasicTitle type="secondary"> - Заявка № {application_id}</BasicTitle>
        )}
      </BasicTitle>

      <ChatsContainer>
        <ChatsList
          chats={chats}
          curChatId={curChatId}
          forSeller={forSeller}
          fromApplication={fromApplication}
        />
        <Routes>
          <Route
            index
            element={
              <Empty
                description="Выберите чат для общения"
                style={{ flex: 1 }}
              />
            }
          />
          <Route
            path=":application_id_chat/:seller_cabinet_id"
            element={
              <Chat forSeller={forSeller} fromApplication={fromApplication} />
            }
          />
          <Route path="*" element={<Navigate to="" replace />} />
        </Routes>
      </ChatsContainer>
    </Wrapper>
  );
};
