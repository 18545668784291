import { createAsyncThunk } from "@reduxjs/toolkit";
import { colleaguesByCabinetActions } from ".";
import * as api from "api/users";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getColleaguesByCabinet = createAsyncThunk<
  api.TGetUsersByCabinetResponse,
  api.TGetUsersByCabinetRequest
>(
  "colleagues/getColleaguesByCabinet",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(colleaguesByCabinetActions.setLoading(true));
    dispatch(colleaguesByCabinetActions.setError(null));
    return api
      .getUsersByCabinet(payload)
      .then(({ data }) => {
        dispatch(colleaguesByCabinetActions.setUsers(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(colleaguesByCabinetActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(colleaguesByCabinetActions.setLoading(false));
      });
  }
);
