import { ComponentProps } from "react";
import { TOfferForSeller } from "types";
import { Tag } from "antd";

export const offerStatusIdMapper: Record<
  TOfferForSeller["status_id"],
  {
    shortName: string;
    color: ComponentProps<typeof Tag>["color"];
    priority: number;
  }
> = {
  99: { shortName: "Черновик", color: "default", priority: 1 },
  123: {
    shortName: "На согласовании у менеджера",
    color: "orange",
    priority: 2
  },
  101: { shortName: "Согласовано", color: "green", priority: 3 },
  107: { shortName: "Отменено", color: "red", priority: 3 },
  103: { shortName: "Принимает участие в тендере", color: "blue", priority: 4 },
  124: {
    shortName: "На согласовании у покупателя",
    color: "purple",
    priority: 5
  },
  105: { shortName: "Принято", color: "green", priority: 6 },
  102: { shortName: "Отклонено", color: "red", priority: 6 },
  125: { shortName: "В доставке", color: "gold", priority: 7 },
  126: { shortName: "Ожидание оплаты", color: "magenta", priority: 8 },
  127: { shortName: "Завершено", color: "lime", priority: 9 },
  128: { shortName: "Завершено неуспешно", color: "red", priority: 9 }
};
