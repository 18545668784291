import { TLicenseType } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addLicenseType = (
  data: TAddLicenseTypeRequest
): RequestPromise<TAddLicenseTypeResponse> => {
  return apiRequest.post(`/license/type/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TAddLicenseTypeRequest = Omit<TLicenseType, "license_type_id">;

export type TAddLicenseTypeResponse = TLicenseType;
