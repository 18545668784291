import React, { FC } from "react";
import { TChatMessage } from "types";
import { MessageBox } from "./Message.styles";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { Typography } from "antd";
import { toDisplayMessageDateTime } from "utils/dates";

type TMessageProps = TChatMessage;

export const Message: FC<TMessageProps> = ({
  applicationId,
  chatId,
  id,
  message,
  sellerCabinetId,
  senderId,
  senderName,
  sentAt
}) => {
  const { user } = useAppSelector(userSelectors.getState);

  return (
    <MessageBox $isIncoming={senderId !== user?.user_id}>
      {senderName && <Typography.Text strong>{senderName}</Typography.Text>}

      <Typography.Text>{message}</Typography.Text>

      <Typography.Text type="secondary" style={{ alignSelf: "flex-end" }}>
        <small>{toDisplayMessageDateTime(sentAt)}</small>
      </Typography.Text>
    </MessageBox>
  );
};
