import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAccountByEntityIdState } from "./types";

import { RequestError, TAccount } from "types";

const initialState: TAccountByEntityIdState = {
  isLoading: false,
  error: null,
  accounts: {}
};

export const AccountByEntityIdSlice = createSlice({
  name: "account/byEntityId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setAccounts: (
      state,
      { payload }: PayloadAction<{ entity_id: number; accounts: TAccount[] }>
    ) => {
      state.accounts[payload.entity_id] = payload.accounts;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const accountByEntityIdActions = AccountByEntityIdSlice.actions;
export const accountByEntityIdReducer = AccountByEntityIdSlice.reducer;

export * as accountsByEntityIdSelectors from "./selectors";
