import { TPositionFromDict } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getPosition = (
  data: TGetPositionRequest
): RequestPromise<TGetPositionResponse> => {
  return apiRequest.get(`/position`, {
    params: data,
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetPositionRequest = {
  search: string;
};

export type TGetPositionResponse = TPositionFromDict[];
