import { applicationPositionUnitTypeIdMapper } from "utils/mappers";

export const applicationPositionUnitTypeIdOptions = Object.keys(
  applicationPositionUnitTypeIdMapper
)
  .map(Number)
  .map((item) => ({
    value: item,
    label: applicationPositionUnitTypeIdMapper[item]?.shortName
  }));
