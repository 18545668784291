import { createAsyncThunk } from "@reduxjs/toolkit";
import { projectDeleteActions } from ".";
import * as api from "api/project";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const deleteProject = createAsyncThunk<
  api.TDeleteProjectResponse,
  api.TDeleteProjectRequest
>("project/deleteProject", (payload, { dispatch, rejectWithValue }) => {
  dispatch(projectDeleteActions.setLoading(true));
  dispatch(projectDeleteActions.setError(null));
  return api
    .deleteProject(payload)
    .then(({ data }) => {
      dispatch(projectDeleteActions.setProject(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(projectDeleteActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(projectDeleteActions.setLoading(false));
    });
});
