import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TColleaguesByCabinetState } from "./types";

import { RequestError, TPerson } from "types";

const initialState: TColleaguesByCabinetState = {
  isLoading: false,
  error: null,
  users: undefined
};

export const ColleaguesByCabinetSlice = createSlice({
  name: "colleagues/byCabinet",
  initialState,
  reducers: {
    clearState: () => initialState,
    setUsers: (state, { payload }: PayloadAction<TPerson[]>) => {
      state.users = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const colleaguesByCabinetActions = ColleaguesByCabinetSlice.actions;
export const colleaguesByCabinetReducer = ColleaguesByCabinetSlice.reducer;

export * as colleaguesByCabinetSelectors from "./selectors";
