import React, { ComponentProps, FC } from "react";
import { Divider as DividerANTD } from "antd";
import { DividerStyled } from "./Divider.styles";

export const Divider: FC<ComponentProps<typeof DividerANTD>> = ({
  type,
  ...props
}) => {
  return (
    <DividerStyled $isVertical={type === "vertical"} type={type} {...props} />
  );
};
