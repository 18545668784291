import React, { useCallback, useEffect, useState } from "react";
import { ErrorAlert } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { Button, Form, FormProps, Grid, Input } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setPassword } from "store/user/setPassword/thunk";
import {
  setPasswordActions,
  setPasswordSelectors
} from "store/user/setPassword";
import { UnauthorizedFormTitle } from "components/common/redesign";

type TSetPasswordForm = {
  password: string;
  passwordConfirm: string;
};

export const EmailPass = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const screens = Grid.useBreakpoint();

  let [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const { isLoading, error } = useAppSelector(setPasswordSelectors.getState);

  const onSetPassword: FormProps<TSetPasswordForm>["onFinish"] = useCallback(
    (values: TSetPasswordForm) => {
      dispatch(
        setPassword({
          newpassword: values.password,
          email,
          token
        })
      )
        .unwrap()
        .then(() => {
          navigate("/login");
        });
    },
    [dispatch, email, navigate, token]
  );

  useEffect(() => {
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    if (!token || !email) {
      navigate("/login");
    } else {
      setToken(token);
      setEmail(email);
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    return () => {
      dispatch(setPasswordActions.clearState());
    };
  }, [dispatch]);

  return (
    <>
      <UnauthorizedFormTitle>Установите новый пароль</UnauthorizedFormTitle>

      <Form
        layout="vertical"
        name="setpassword"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        style={{ maxWidth: "320px" }}
        onFinish={onSetPassword}
        // onFinishFailed={onFinishFailed}
        // autoComplete="off"
        labelWrap
        // colon={false}
        requiredMark={false}
      >
        <Form.Item<TSetPasswordForm>
          label="Пароль"
          name="password"
          validateFirst
          extra="8 символов, наличие цифр, наличие заглавной и прописной латиницы"
          rules={[
            { required: true, message: "Введите пароль" },
            {
              pattern: /.{8,}$/,
              message: "Пароль должен содержать минимум 8 символов"
            },
            {
              pattern: /(?=.*[a-z])/,
              message: "Пароль должен содержать хотя бы одну строчную букву"
            },
            {
              pattern: /(?=.*[A-Z])/,
              message: "Пароль должен содержать хотя бы одну заглавную букву"
            },
            {
              pattern: /(?=.*\d)/,
              message: "Пароль должен содержать хотя бы одну цифру"
            }
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<TSetPasswordForm>
          label="Повторите пароль"
          name="passwordConfirm"
          rules={[
            { required: true, message: "Введите пароль повторно" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Введенные пароли не совпадают")
                );
              }
            })
          ]}
          style={{
            marginBottom: screens?.sm ? 32 : undefined
          }}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{ span: 24 }}
          style={{
            marginBottom: screens?.sm ? 32 : undefined
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            block
            size="large"
          >
            Готово
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </>
  );
};
