import { TApplicationForSeller } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getApplicationForSellerById = (
  id: TGetApplicationForSellerByIdRequest
): RequestPromise<TGetApplicationForSellerByIdResponse> => {
  return apiRequest.get(`/applications-for-sellers/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TGetApplicationForSellerByIdRequest = number;

export type TGetApplicationForSellerByIdResponse = TApplicationForSeller;
