import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationUpdateStatusActions } from ".";
import * as api from "api/applications";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const updateApplicationStatus = createAsyncThunk<
  api.TUpdateApplicationStatusResponse,
  api.TUpdateApplicationStatusRequest
>(
  "application/updateApplicationStatus",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(applicationUpdateStatusActions.setLoading(true));
    dispatch(applicationUpdateStatusActions.setError(null));
    return api
      .updateApplicationStatus(payload)
      .then(({ data }) => {
        dispatch(applicationUpdateStatusActions.setApplication(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(applicationUpdateStatusActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(applicationUpdateStatusActions.setLoading(false));
      });
  }
);
