import { TLicenseType } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateLicenseType = (
  data: TUpdateLicenseTypeRequest
): RequestPromise<TUpdateLicenseTypeResponse> => {
  return apiRequest.post(`/license/type/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateLicenseTypeRequest = TLicenseType;

export type TUpdateLicenseTypeResponse = TLicenseType;
