import styled from "@emotion/styled";

export const CTABlock = styled.div`
  display: flex;
  gap: 8px 24px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const RegisteredTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 320px;
`;
