import React, { FC, useCallback, useState } from "react";

import {
  EFileType,
  RequestError,
  TApplication,
  TApplicationForSeller,
  TEntity,
  TFileMeta,
  TOfferForBuyer,
  TOfferForSeller,
  TProject
} from "types";

import {
  ApplicationHeader,
  ApplicationPositionsTable,
  ApplicationProcess
} from "./components";
import { Divider } from "../Divider/Divider";
import { Files } from "../Files/Files";
import { OffersTable } from "../OffersTable/OffersTable";
import { Button, Drawer } from "antd";
import { AccountData } from "../AccountData/AccountData";
import { CompanyData } from "../CompanyData/CompanyData";
import { CopyOutlined } from "@ant-design/icons";

export type TApplicationDataProps = {
  application: TApplication | TApplicationForSeller;
  files?: TFileMeta[];
  offers?: TOfferForBuyer[] | TOfferForSeller[];
  getFiles?: () => void;
  canEditFiles?: boolean;
  // user?: TPerson;
  entity?: TEntity;
  project?: TProject;
  offersLength?: number;
  onEdit?: () => void;
  onGoBack?: () => void;
  withEdit?: boolean;
  onApprove?: (value: number) => void;
  hasApprovements?: boolean;
  approvementIsLoading?: boolean;
  approvementError?: RequestError | null;

  withStatusChange?: boolean;
  onUpdateStatus?: ({ key }: { key: string }) => void;
  statusIsLoading?: boolean;
  statusError?: RequestError | null;

  forSeller?: boolean;
  withOffer?: boolean;
  onAddOffer?: () => void;
  previewOnly?: boolean;
  onStepEdit?: (step: number) => void;

  withMessages?: boolean;
};

export const ApplicationData: FC<TApplicationDataProps> = ({
  files,
  offers,
  getFiles,
  canEditFiles,
  ...props
}) => {
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);

  const [accountDataForCopy, setAccountDataForCopy] = useState<
    string | undefined
  >(undefined);
  const [companyDataForCopy, setCompanyDataForCopy] = useState<
    string | undefined
  >(undefined);

  const openAccountModal = useCallback(() => {
    setIsAccountModalOpen(true);
  }, []);
  const closeAccountModal = useCallback(() => {
    setIsAccountModalOpen(false);
  }, []);
  const openEntityModal = useCallback(() => {
    setIsEntityModalOpen(true);
  }, []);
  const closeEntityModal = useCallback(() => {
    setIsEntityModalOpen(false);
  }, []);

  const onAccountCopy = useCallback(() => {
    accountDataForCopy && navigator.clipboard.writeText(accountDataForCopy);
  }, [accountDataForCopy]);
  const onCompanyCopy = useCallback(() => {
    companyDataForCopy && navigator.clipboard.writeText(companyDataForCopy);
  }, [companyDataForCopy]);

  return (
    <>
      <ApplicationHeader
        {...props}
        onBuyerCompanyClick={openEntityModal}
        onBuyerAccountClick={openAccountModal}
      />

      <Divider />

      <ApplicationPositionsTable
        positions={props?.application?.positions}
        onStepEdit={props?.previewOnly ? props?.onStepEdit : undefined}
      />

      <Divider />

      <ApplicationProcess
        creator={
          props?.forSeller
            ? undefined
            : (props?.application as TApplication)?.creator
        }
        responsible={props?.application?.responsible}
        onStepEdit={props?.previewOnly ? props?.onStepEdit : undefined}
      />

      {!props.previewOnly && (
        <>
          <Divider />

          <Files
            id={props?.application?.application_id}
            type={EFileType.APPLICATION}
            files={files}
            onFinish={getFiles}
            canEdit={canEditFiles}
          />

          <Divider />

          <OffersTable offers={offers} pagination={false} fromApplication />
        </>
      )}
      <Drawer
        title="Счет"
        open={isAccountModalOpen}
        onClose={closeAccountModal}
        extra={
          accountDataForCopy && (
            <Button
              icon={<CopyOutlined />}
              onClick={onAccountCopy}
              size="large"
            />
          )
        }
      >
        <AccountData
          account={props?.application?.buyer_account}
          entity={props?.entity}
          vertical
          setDataForCopy={setAccountDataForCopy}
        />
      </Drawer>
      <Drawer
        title="Юр. лицо"
        open={isEntityModalOpen}
        onClose={closeEntityModal}
        extra={
          companyDataForCopy && (
            <Button
              icon={<CopyOutlined />}
              onClick={onCompanyCopy}
              size="large"
            />
          )
        }
      >
        {props?.entity && (
          <CompanyData
            entity={props?.entity}
            vertical
            setDataForCopy={setCompanyDataForCopy}
          />
        )}
      </Drawer>
    </>
  );
};
