import React from "react";
import { Route, Routes } from "react-router-dom";
import { Colleague, Colleagues } from "./components";

export const ColleaguesTab = () => {
  return (
    <Routes>
      <Route index element={<Colleagues />} />
      <Route path=":user_id" element={<Colleague />} />
    </Routes>
  );
};
