import React, { ComponentProps, FC } from "react";
import { Typography } from "antd";
import { TypographyTitleStyled } from "./TypographyTitleNoMargin.styles";

type TTypographyTitleNoMarginProps = ComponentProps<typeof Typography.Title> & {
  removeWeight?: boolean;
};

export const TypographyTitleNoMargin: FC<TTypographyTitleNoMarginProps> = ({
  removeWeight = true,
  ...props
}) => {
  return <TypographyTitleStyled {...props} $removeWeight={removeWeight} />;
};
