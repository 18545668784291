import { TApplicationPosition } from "types";

export const applicationPositionUnitTypeIdMapper: Record<
  TApplicationPosition["unit_type_id"],
  { shortName: string; full_name: string; code: string }
> = {
  111: {
    shortName: "шт.",
    full_name: "штука",
    code: "796"
  },
  112: {
    shortName: "л",
    full_name: "литр",
    code: "112"
  },
  116: {
    shortName: "кг",
    full_name: "килограмм",
    code: "166"
  },
  117: {
    shortName: "т",
    full_name: "тонна (1000 кг)",
    code: "168"
  },
  115: {
    shortName: "м3",
    full_name: "кубический метр",
    code: "113"
  },
  114: {
    shortName: "м2",
    full_name: "квадратный метр",
    code: "055"
  },
  113: {
    shortName: "м",
    full_name: "метр",
    code: "018"
  },
  118: {
    shortName: "кВт-ч",
    full_name: "киловатт-час",
    code: "-"
  }
};
