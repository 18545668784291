import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TCabinetByIdState } from "./types";

import { RequestError, TCabinet } from "types";

const initialState: TCabinetByIdState = {
  isLoading: false,
  error: null,
  cabinet: undefined
};

export const CabinetByIdSlice = createSlice({
  name: "cabinets/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setCabinet: (state, { payload }: PayloadAction<TCabinet>) => {
      state.cabinet = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const cabinetByIdActions = CabinetByIdSlice.actions;
export const cabinetByIdReducer = CabinetByIdSlice.reducer;

export * as cabinetByIdSelectors from "./selectors";
