import React, { FC, useCallback, useMemo } from "react";

import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  Typography
} from "antd";
import {
  ArrowDownOutlined,
  MinusCircleOutlined,
  PercentageOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {
  applicationPositionCategoryIdOptions,
  applicationPositionUnitTypeIdOptions,
  vatOptions
} from "constants/options";
import { TOfferFormValues, TOfferPositionFromValue } from "../../OfferEditForm";
import { TApplicationPosition } from "types";
import { ApplicationPositionsTable } from "../../../ApplicationData/components";
import { countPrice } from "utils/countPrice";

type TPositionsSelectorProps = {
  form: FormInstance<TOfferFormValues>;
  applicationPositions?: TApplicationPosition[];
};

export const PositionsSelector: FC<TPositionsSelectorProps> = ({
  form,
  applicationPositions
}) => {
  const positionsOptions = useMemo(
    () =>
      (applicationPositions || []).map((position) => ({
        label: position?.position_name,
        value: position?.position_id
      })),

    [applicationPositions]
  );

  const onClearAuthentity = useCallback(
    (key: number) => {
      form.setFieldValue(["positions", key, "resource_code_id"], undefined);
      form.setFieldValue(["positions", key, "analogue_flag"], true);
    },
    [form]
  );
  const onDividerFieldChange = useCallback(
    (value: string, key: number, field: keyof TOfferPositionFromValue) => {
      form.setFieldValue(
        ["positions", key, field],
        value?.replaceAll(".", ",")
      );
    },
    [form]
  );
  const onPositionFromApplicationSelect = useCallback(
    (value: string, key: number) => {
      const position = applicationPositions?.find(
        (item) => item?.position_id === Number(value)
      );
      form.setFieldValue(
        ["positions", key, "position_name"],
        position?.position_name
      );
      form.setFieldValue(
        ["positions", key, "unit_type_id"],
        position?.unit_type_id
      );
      form.setFieldValue(
        ["positions", key, "category_id"],
        position?.category_id
      );
      form.setFieldValue(
        ["positions", key, "resource_code_id"],
        position?.resource_code_id
      );
    },
    [applicationPositions, form]
  );

  return (
    <>
      <Typography.Title level={4}>Позиции предложения</Typography.Title>
      <Form.List
        name="positions"
        rules={[
          {
            validator: async (_, positions) => {
              if (!positions || !positions.length) {
                return Promise.reject(new Error("Добавьте позиции"));
              }
            }
          }
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          const onAddPositionFromApplication = (
            position: TApplicationPosition
          ) => {
            add({
              application_position_id: position?.position_id,
              position_name: position?.position_name,
              unit_amount: position?.unit_amount,
              unit_type_id: position?.unit_type_id,
              category_id: position?.category_id,
              resource_code_id: position?.resource_code_id,
              analogue_flag: false,
              discount: 0,
              vat: 0,
              amount: 0,
              unit_price: 0
            });
          };
          const addAllPositions = () => {
            applicationPositions?.forEach(onAddPositionFromApplication);
          };
          const setVAT = ({ key }: { key: string }) => {
            fields.forEach((field) => {
              form.setFieldValue(["positions", field?.key, "vat"], Number(key));
            });
          };

          return (
            <>
              <ApplicationPositionsTable
                positions={applicationPositions}
                icon={<ArrowDownOutlined />}
                onIconClick={onAddPositionFromApplication}
              />

              <div
                style={{
                  overflow: "auto",
                  marginTop: "24px",
                  display: "flex",
                  gap: "12px"
                }}
              >
                <Button onClick={addAllPositions}>
                  Взять все позиции из заявки
                </Button>
                <Dropdown menu={{ items: vatOptions, onClick: setVAT }}>
                  <Button>
                    <Space>
                      Заполнить НДС
                      <PercentageOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
              <div style={{ overflow: "auto", marginTop: "24px" }}>
                {fields.map(({ key, name, ...restField }, index, self) => {
                  return (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "baseline",
                        marginBottom: 24,
                        width: "max-content"
                      }}
                    >
                      <Form.Item
                        {...restField}
                        label={!index ? "Наименование" : undefined}
                        name={[name, "position_name"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 250 }}
                        rules={[
                          {
                            required: true,
                            message: "Введите название"
                          }
                        ]}
                      >
                        <Input onChange={() => onClearAuthentity(key)} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "Аналог" : undefined}
                        name={[name, "analogue_flag"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 65 }}
                        valuePropName="checked"
                        shouldUpdate
                        dependencies={["position_name"]}
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "Позиция из заявки" : undefined}
                        name={[name, "application_position_id"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 250 }}
                      >
                        <Select
                          options={positionsOptions}
                          showSearch
                          allowClear
                          onChange={(value) =>
                            onPositionFromApplicationSelect(value, key)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "Товарная категория" : undefined}
                        name={[name, "category_id"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 250 }}
                        rules={[
                          {
                            required: true,
                            message: "Введите товарную категорию"
                          }
                        ]}
                      >
                        <Select
                          options={applicationPositionCategoryIdOptions}
                          showSearch
                          onChange={() => onClearAuthentity(key)}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "Кол-во" : undefined}
                        name={[name, "unit_amount"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 100 }}
                        rules={[
                          {
                            required: true,
                            message: "Введите кол-во"
                          },
                          {
                            pattern: new RegExp(/^\d*([.,]\d+)?$/),
                            message: "Введите корректное число"
                          }
                        ]}
                      >
                        <Input
                          onChange={(value) =>
                            onDividerFieldChange(
                              value?.target?.value,
                              key,
                              "unit_amount"
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "Ед. измерения" : undefined}
                        name={[name, "unit_type_id"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 100 }}
                        rules={[
                          {
                            required: true,
                            message: "Введите ед. изм."
                          }
                        ]}
                      >
                        <Select
                          options={applicationPositionUnitTypeIdOptions}
                          onChange={() => onClearAuthentity(key)}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "Стоимость единицы" : undefined}
                        name={[name, "unit_price"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 100 }}
                        rules={[
                          {
                            required: true,
                            message: "Введите стоимость"
                          },
                          {
                            pattern: new RegExp(/^\d*([.,]\d{1,2})?$/),
                            message: "Введите корректное число"
                          }
                        ]}
                      >
                        <Input
                          onChange={(value) =>
                            onDividerFieldChange(
                              value?.target?.value,
                              key,
                              "unit_price"
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "Скидка" : undefined}
                        name={[name, "discount"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 100 }}
                        rules={[
                          {
                            required: true,
                            message: "Введите скидку"
                          },
                          {
                            pattern: new RegExp(/^\d*([.,]\d+)?$/),
                            message: "Введите корректное число"
                          }
                        ]}
                      >
                        <Input
                          prefix="%"
                          onChange={(value) =>
                            onDividerFieldChange(
                              value?.target?.value,
                              key,
                              "discount"
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "НДС" : undefined}
                        name={[name, "vat"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 100 }}
                        rules={[
                          {
                            required: true,
                            message: "Введите НДС"
                          },
                          {
                            pattern: new RegExp(/^\d*([.,]\d{1,2})?$/),
                            message: "Введите корректное число"
                          }
                        ]}
                      >
                        <Input
                          prefix="%"
                          onChange={(value) =>
                            onDividerFieldChange(
                              value?.target?.value,
                              key,
                              "vat"
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={!index ? "Стоимость" : undefined}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 140 }}
                        shouldUpdate
                      >
                        {({ getFieldValue }) => {
                          const amount = getFieldValue([
                            "positions",
                            key,
                            "unit_amount"
                          ]);
                          const price = getFieldValue([
                            "positions",
                            key,
                            "unit_price"
                          ]);
                          const disc = getFieldValue([
                            "positions",
                            key,
                            "discount"
                          ]);
                          const tax = getFieldValue(["positions", key, "vat"]);

                          const total = countPrice(amount, price, disc, tax);
                          return (
                            <Input
                              value={total ? total.toFixed(2) : ""}
                              variant="borderless"
                            />
                          );
                        }}
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label={!index ? "Комментарий" : undefined}
                        name={[name, "position_comment"]}
                        labelCol={{ span: 24 }}
                        style={{ marginBottom: 0, width: 200 }}
                      >
                        <Input />
                      </Form.Item>

                      {self?.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ alignSelf: "flex-end", marginBottom: 9 }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      analogue_flag: true,
                      discount: 0,
                      vat: 0,
                      amount: 0,
                      unit_price: 0
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить позицию
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
