import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TFileDeleteState } from "./types";

import { RequestError, TFileMeta } from "types";

const initialState: TFileDeleteState = {
  isLoading: false,
  error: null,
  file: undefined
};

export const FileDeleteSlice = createSlice({
  name: "files/delete",
  initialState,
  reducers: {
    clearState: () => initialState,
    setFile: (state, { payload }: PayloadAction<TFileMeta>) => {
      state.file = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const fileDeleteActions = FileDeleteSlice.actions;
export const fileDeleteReducer = FileDeleteSlice.reducer;

export * as fileDeleteSelectors from "./selectors";
