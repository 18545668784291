import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationPositionsActions } from ".";
import * as api from "api/position";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getPositions = createAsyncThunk<
  api.TGetPositionResponse,
  api.TGetPositionRequest
>("application/getPositions", (payload, { dispatch, rejectWithValue }) => {
  dispatch(applicationPositionsActions.setLoading(true));
  dispatch(applicationPositionsActions.setError(null));
  return api
    .getPosition(payload)
    .then(({ data }) => {
      dispatch(applicationPositionsActions.setPositions(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(applicationPositionsActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(applicationPositionsActions.setLoading(false));
    });
});
