import { TLicense } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addLicense = (
  data: TAddLicenseRequest
): RequestPromise<TAddLicenseResponse> => {
  return apiRequest.post(`/license/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TAddLicenseRequest = Omit<TLicense, "license_id">;

export type TAddLicenseResponse = TLicense;
