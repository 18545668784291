import styled from "@emotion/styled";

export const CTABlock = styled.div`
  display: flex;
  gap: 8px 24px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
