import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLicenseByIdState } from "./types";

import { RequestError, TLicense } from "types";

const initialState: TLicenseByIdState = {
  isLoading: false,
  error: null,
  license: undefined
};

export const LicenseByIdSlice = createSlice({
  name: "licenses/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicense: (state, { payload }: PayloadAction<TLicense>) => {
      state.license = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const licenseByIdActions = LicenseByIdSlice.actions;
export const licenseByIdReducer = LicenseByIdSlice.reducer;

export * as licenseByIdSelectors from "./selectors";
