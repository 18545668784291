import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationAddActions } from "./add";
import { ApplicationByIdActions } from "./byId";
import { applicationsGetActions } from "./get";
import { applicationPositionsActions } from "./positions";
import { applicationUpdateActions } from "./update";
import { applicationUpdateStatusActions } from "./update_status";

export const clearApplications = createAsyncThunk<void, void>(
  "application/clearApplications",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(applicationAddActions.clearState());
    dispatch(ApplicationByIdActions.clearState());
    dispatch(applicationsGetActions.clearState());
    dispatch(applicationPositionsActions.clearState());
    dispatch(applicationUpdateActions.clearState());
    dispatch(applicationUpdateStatusActions.clearState());
  }
);
