import { combineReducers } from "@reduxjs/toolkit";
import { licenseByIdReducer } from "./byId";
import { licenseAddReducer } from "./add";
import { licenseUpdateReducer } from "./update";
import { licensesGetReducer } from "./get";
import { licenseDeleteReducer } from "./delete";
import { licenseTypesReducer } from "./type";

export const licensesReducer = combineReducers({
  byId: licenseByIdReducer,
  add: licenseAddReducer,
  update: licenseUpdateReducer,
  delete: licenseDeleteReducer,
  get: licensesGetReducer,
  types: licenseTypesReducer
});
