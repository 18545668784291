import React, { FC, useEffect, useMemo } from "react";
import { TAccount, TEntity } from "types";
import { DescriptionsProps } from "antd";
import { DescriptionsCustom } from "../DescriptionsCustom/DescriptionsCustom";

type TAccountDataProps = {
  account: TAccount;
  entity?: TEntity;
  vertical?: boolean;
  setDataForCopy?: (value: string) => void;
};

export const AccountData: FC<TAccountDataProps> = ({
  account,
  entity,
  vertical,
  setDataForCopy
}) => {
  const generalData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "name",
        label: "Название",
        children: account?.name
      },
      {
        key: "entity",
        label: "Юр. лицо",
        children: entity?.full_name
      },
      {
        key: "bank_name",
        label: "Банк",
        children: account?.bank_name
      },
      {
        key: "number",
        label: "Расчетный счет",
        children: account?.number
      },
      {
        key: "correspondent",
        label: "Кор. счёт",
        children: account?.correspondent
      },
      {
        key: "bik",
        label: "БИК",
        children: account?.bik
      },
      {
        key: "okato",
        label: "ОКАТО",
        children: account?.okato
      },
      {
        key: "status",
        label: "Активный",
        children: account?.status ? "Да" : "Нет"
      }
    ],
    [account, entity]
  );

  useEffect(() => {
    if (setDataForCopy) {
      setDataForCopy(
        generalData
          .map((item) => `${item?.label}: ${item?.children || "-"}`)
          .join("\n")
      );
    }
  }, [generalData, setDataForCopy]);

  return <DescriptionsCustom items={generalData} vertical={vertical} />;
};
