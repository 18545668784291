import { TOfferForSeller, TOfferForSellerRequest } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addOfferForSeller = (
  data: TAddOfferForSellerRequest
): RequestPromise<TAddOfferForSellerResponse> => {
  return apiRequest.post(`/offers-for-sellers/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TAddOfferForSellerRequest = TOfferForSellerRequest;

export type TAddOfferForSellerResponse = TOfferForSeller;
