import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TEntityAddState } from "./types";

import { RequestError, TEntity } from "types";

const initialState: TEntityAddState = {
  isLoading: false,
  error: null,
  entity: undefined
};

export const EntityAddSlice = createSlice({
  name: "entity/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setEntity: (state, { payload }: PayloadAction<TEntity>) => {
      state.entity = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const entityAddActions = EntityAddSlice.actions;
export const entityAddReducer = EntityAddSlice.reducer;

export * as entityAddSelectors from "./selectors";
