import React, { FC } from "react";
import { Tag as AntTag, TagProps } from "antd";

export const EnlargedTag: FC<TagProps> = (props) => {
  return (
    <AntTag
      {...props}
      style={{
        fontSize: "16px",
        lineHeight: "24px",
        padding: "2px 10px",
        fontWeight: "normal"
      }}
    />
  );
};
