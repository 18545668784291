import React, { useEffect } from "react";
import { MainRouter } from "../MainRouter/MainRouter";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { getUser } from "store/user/thunk";

const App = () => {
  const dispatch = useAppDispatch();
  const cookie = Cookies.get("token");

  const { user } = useAppSelector(userSelectors.getState);

  useEffect(() => {
    if (!user && cookie) {
      dispatch(getUser());
    }
  }, [cookie, dispatch, user]);

  return <MainRouter />;
};

export default App;
