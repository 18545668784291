import { TApplication } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addApplication = (
  data: TAddApplicationRequest
): RequestPromise<TAddApplicationResponse> => {
  return apiRequest.post(`/applications/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TAddApplicationRequest = Omit<TApplication, "application_id">;

export type TAddApplicationResponse = TApplication;
