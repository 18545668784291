import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOfferForSellerAddState } from "./types";

import { RequestError, TOfferForSeller } from "types";

const initialState: TOfferForSellerAddState = {
  isLoading: false,
  error: null,
  offerForSeller: undefined
};

export const OfferForSellerAddSlice = createSlice({
  name: "offersForSeller/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOfferForSeller: (state, { payload }: PayloadAction<TOfferForSeller>) => {
      state.offerForSeller = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const offerForSellerAddActions = OfferForSellerAddSlice.actions;
export const offerForSellerAddReducer = OfferForSellerAddSlice.reducer;

export * as offerForSellerAddSelectors from "./selectors";
