import styled from "@emotion/styled";
import { Button } from "antd";
import { DEVICE } from "constants/breakpoints";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;
export const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  width: 100%;

  @media ${DEVICE.LG} {
    gap: 16px;
  }
`;
export const CTABlock = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;

  @media ${DEVICE.LG} {
    gap: 16px;
  }
  @media ${DEVICE.SM} {
    flex-direction: column;
  }
`;
export const NavButton = styled(Button)`
  width: 200px;

  @media ${DEVICE.SM} {
    width: unset;
  }
`;
export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;
export const TableScrollWrapper = styled.div`
  width: 100%;
  overflow: auto;
`;
