import { combineReducers } from "@reduxjs/toolkit";
import { cabinetByIdReducer } from "./byId";
import { cabinetAddReducer } from "./add";
import { cabinetUpdateReducer } from "./update";
import { cabinetsGetReducer } from "./get";
import { cabinetDeleteReducer } from "./delete";

export const cabinetsReducer = combineReducers({
  byId: cabinetByIdReducer,
  add: cabinetAddReducer,
  update: cabinetUpdateReducer,
  delete: cabinetDeleteReducer,
  get: cabinetsGetReducer
});
