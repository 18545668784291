import { createAsyncThunk } from "@reduxjs/toolkit";
import { offersForSellerByApplicationIdActions } from ".";
import * as api from "api/offers_for_sellers";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getOfferForSellerByApplicationId = createAsyncThunk<
  api.TGetOffersForSellerByApplicationIdResponse,
  api.TGetOffersForSellerByApplicationIdRequest
>(
  "offersForSellers/getOfferForSellerByApplicationId",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(offersForSellerByApplicationIdActions.setLoading(true));
    dispatch(offersForSellerByApplicationIdActions.setError(null));
    return api
      .getOffersForSellerByApplicationId(payload)
      .then(({ data }) => {
        dispatch(
          offersForSellerByApplicationIdActions.setOffersForSeller(data)
        );
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offersForSellerByApplicationIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offersForSellerByApplicationIdActions.setLoading(false));
      });
  }
);
