import { createAsyncThunk } from "@reduxjs/toolkit";
import { licenseTypesGetActions } from ".";
import * as api from "api/license";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../../store";

export const getLicenseTypes = createAsyncThunk<
  api.TGetLicenseTypesResponse,
  void,
  { state: RootState }
>(
  "license/type/getLicenseTypes",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(licenseTypesGetActions.setLoading(true));
    dispatch(licenseTypesGetActions.setError(null));

    return api
      .getLicenseTypes()
      .then(({ data }) => {
        dispatch(licenseTypesGetActions.setLicenseTypes(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(licenseTypesGetActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(licenseTypesGetActions.setLoading(false));
      });
  }
);
