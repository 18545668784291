import React, { FC, useCallback, useMemo } from "react";
import {
  TApplicationEditFormTabProps,
  TApplicationFormValues
} from "../../ApplicationEditForm";
import { Form, Select, Typography } from "antd";
import { CTABlock, NavButton } from "../../ApplicationEditForm.styles";

export const ProcessTab: FC<TApplicationEditFormTabProps> = ({
  formData,
  users,
  onSubmitStep,
  onPrevStep
}) => {
  const usersOptions = useMemo(
    () =>
      users?.map((user) => ({
        label: user?.full_name,
        value: user?.user_id
      })),
    [users]
  );

  const initialValues: TApplicationFormValues = useMemo(() => {
    const { responsible } = formData;

    return {
      responsible: responsible?.user_id
    };
  }, [formData]);

  const onSubmitHandler = useCallback(
    (application: TApplicationFormValues) => {
      const { responsible } = application;
      onSubmitStep({
        responsible: users?.find((user) => user?.user_id === responsible)
      });
    },
    [onSubmitStep, users]
  );

  return (
    <>
      <Form
        name="application_edit_process"
        layout="vertical"
        requiredMark={false}
        style={{ maxWidth: "400px" }}
        onFinish={onSubmitHandler}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.Item label="Создатель заявки">
          <Typography.Text>{formData?.creator?.full_name}</Typography.Text>
        </Form.Item>

        <Form.Item
          label="Ответственный по заявке"
          name="responsible"
          rules={[
            {
              required: true,
              message: "Выберите ответственного по заявке"
            }
          ]}
        >
          <Select options={usersOptions} />
        </Form.Item>
      </Form>

      <CTABlock>
        <NavButton
          type="primary"
          form="application_edit_process"
          htmlType="submit"
        >
          Далее
        </NavButton>
        <NavButton onClick={onPrevStep}>Назад</NavButton>
      </CTABlock>
    </>
  );
};
