import { createAsyncThunk } from "@reduxjs/toolkit";
import { registerActions } from ".";
import * as api from "api/unauthorized";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const register = createAsyncThunk<
  api.TRegistrationResponse,
  api.TRegistrationRequest
>("user/register", (payload, { dispatch, rejectWithValue }) => {
  dispatch(registerActions.setLoading(true));
  dispatch(registerActions.setError(null));
  return api
    .register(payload)
    .then(({ data }) => {
      dispatch(registerActions.setEmail(data.email));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(registerActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(registerActions.setLoading(false));
    });
});
