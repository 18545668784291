import { createAsyncThunk } from "@reduxjs/toolkit";
import { approvementsByUserIdActions } from ".";
import * as api from "api/approvements";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getApprovementsByUserId = createAsyncThunk<
  api.TByUserIdApprovementsResponse,
  api.TByUserIdApprovementsRequest
>(
  "approvements/getApprovementsByUserId",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(approvementsByUserIdActions.setLoading(true));
    dispatch(approvementsByUserIdActions.setError(null));
    return api
      .getApprovementsByUserId(payload)
      .then(({ data }) => {
        dispatch(approvementsByUserIdActions.setApprovements(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(approvementsByUserIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(approvementsByUserIdActions.setLoading(false));
      });
  }
);
