import React, { FC, useMemo } from "react";
import {
  TApplication,
  TApplicationForSeller,
  TApplicationPosition,
  TEntity,
  TPerson,
  TProject
} from "types";
import { Table, Tag, Tooltip } from "antd";
import { applicationStatusIdMapper } from "utils/mappers";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { toDisplayDateTime } from "utils/dates";
import { Link } from "react-router-dom";
import { ApplicationsTableExpandableRow } from "./components";

type TApplicationsTableProps = {
  applications?: TApplication[] | TApplicationForSeller[];
  projects?: TProject[];
  pagination?: TablePaginationConfig | false;
  entities?: TEntity[];
  forSeller?: boolean;
  fromOffer?: boolean;
};

export const ApplicationsTable: FC<TApplicationsTableProps> = ({
  applications,
  projects,
  pagination,
  entities,
  forSeller,
  fromOffer
}) => {
  const columns: ColumnsType<TApplication | TApplicationForSeller> = useMemo(
    () => [
      {
        title: "Имя заявки",
        key: "application_name",
        render: (value: TApplication | TApplicationForSeller) => (
          <Link
            to={
              fromOffer
                ? `../../applications/${value?.application_id}`
                : `${value?.application_id}`
            }
          >
            {[value?.application_id, value?.application_name].join(" - ")}
          </Link>
        )
      },
      {
        title: "Статус",
        dataIndex: "application_status_id",
        render: (value: number) => {
          const statusData = applicationStatusIdMapper[value];
          return statusData ? (
            <Tooltip title={statusData?.description}>
              <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
            </Tooltip>
          ) : (
            value
          );
        }
      },
      {
        title: "Юр. лицо покупателя",
        key: "buyer_entity",
        render: (value: TApplication | TApplicationForSeller) => {
          if (forSeller) {
            return (value as TApplicationForSeller)?.buyer_entity?.short_name;
          } else {
            const project = projects?.find(
              (project) =>
                project?.project_id === (value as TApplication)?.project_id
            );
            return entities?.find(
              (entity) => entity?.entity_id === project?.entity_id
            )?.short_name;
          }
        }
      },
      ...(!forSeller
        ? [
            {
              title: "Проект",
              dataIndex: "project_id",
              render: (value: number) => {
                return (
                  projects?.find((project) => project?.project_id === value)
                    ?.project_name || value
                );
              }
            }
          ]
        : []),
      ...(!forSeller
        ? [
            {
              title: "Создатель",
              dataIndex: "creator",
              render: (value: TPerson) => value?.full_name
            }
          ]
        : []),
      {
        title: "Ответственный",
        dataIndex: "responsible",
        render: (value: TPerson) => value?.full_name
      },
      {
        title: "Кол-во позиций",
        dataIndex: "positions",
        render: (value: TApplicationPosition[]) => value?.length
      },
      {
        title: "Дата желаемой поставки",
        dataIndex: "application_delivery_due_datetime",
        render: toDisplayDateTime
      }
    ],
    [entities, forSeller, fromOffer, projects]
  );

  return (
    <Table
      dataSource={applications}
      columns={columns}
      pagination={pagination}
      scroll={{ x: "1400px" }}
      expandable={{
        expandedRowRender: (row) => (
          <ApplicationsTableExpandableRow application={row} />
        )
      }}
      rowKey={(row) => row?.application_id}
    />
  );
};
