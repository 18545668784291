import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TFilesByObjectState } from "./types";

import { RequestError, TFileMeta } from "types";

const initialState: TFilesByObjectState = {
  isLoading: false,
  error: null,
  files: undefined
};

export const FilesByObjectSlice = createSlice({
  name: "files/byObject",
  initialState,
  reducers: {
    clearState: () => initialState,
    setFile: (state, { payload }: PayloadAction<TFileMeta[]>) => {
      state.files = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const filesByObjectActions = FilesByObjectSlice.actions;
export const filesByObjectReducer = FilesByObjectSlice.reducer;

export * as filesByObjectSelectors from "./selectors";
