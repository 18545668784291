export const applicationUpdateStatusOptions: Record<number, number[]> = {
  92: [93, 122],
  93: [92],
  94: [92, 96],
  95: [92, 122],
  96: [98],
  97: [],
  98: [92],
  121: [97, 98],
  122: []
};
