import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Descriptions } from "antd";

export const StyledDescriptions = styled(Descriptions)<{
  $verticalStyled: boolean;
}>`
  ${({ $verticalStyled }) =>
    $verticalStyled
      ? css`
          .ant-descriptions-row:nth-child(2n + 1) .ant-descriptions-item {
            padding-bottom: 0 !important;
          }
        `
      : css`
          width: fit-content !important;
          * {
            background: none !important;
            border: none !important;
            line-height: 22px !important;
            padding: 0 !important;
          }
          .ant-descriptions-item-label {
            color: rgba(0, 0, 0, 0.45) !important;
            padding-right: 24px !important;
            width: 200px;
          }
          .ant-descriptions-row:not(:last-child) {
            .ant-descriptions-item-label,
            .ant-descriptions-item-content {
              padding-bottom: 10px !important;
            }
          }
        `}
`;
