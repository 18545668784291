import { TApplicationApprovement } from "types";

export const applicationApprovementTypeIdMapper: Record<
  TApplicationApprovement["approvement_type_id"],
  { shortName: string; full_name: string; description: string }
> = {
  119: {
    shortName: "Заявка",
    full_name: "Заявка",
    description: "Заявка"
  },
  120: {
    shortName: "Предложение",
    full_name: "Предложение",
    description: "Предложение"
  }
};
