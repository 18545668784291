import { TEntity } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getEntitiesByCabinetId = (
  id: TByCabinetIdEntityRequest
): RequestPromise<TByCabinetIdEntityResponse> => {
  return apiRequest.get(`/entities/cabinet/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TByCabinetIdEntityRequest = number;

export type TByCabinetIdEntityResponse = TEntity[];
