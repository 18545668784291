import { createAsyncThunk } from "@reduxjs/toolkit";
import { fileAddActions } from "./add";
import { filesByObjectActions } from "./byObject";
import { fileDeleteActions } from "./delete";
import { fileByIdActions } from "./byId";

export const clearFiles = createAsyncThunk<void, void>(
  "files/clearFiles",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(fileAddActions.clearState());
    dispatch(filesByObjectActions.clearState());
    dispatch(fileDeleteActions.clearState());
    dispatch(fileByIdActions.clearState());
  }
);
