import React, { FC, useMemo } from "react";
import { TLicense, TLicenseType } from "types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";

type TLicenseTypesTableProps = {
  licenseTypes?: TLicenseType[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  isDeleting?: boolean;
};

export const LicenseTypesTable: FC<TLicenseTypesTableProps> = ({
  licenseTypes,
  onDelete,
  onEdit,
  isDeleting
}) => {
  const columns: ColumnsType<TLicenseType> = useMemo(
    () => [
      {
        title: "Тип лицензии",
        dataIndex: "name"
      },
      {
        title: "Описание",
        dataIndex: "description"
      },
      {
        title: "Действующая",
        dataIndex: "is_active",
        render: (value: number) => (
          <>
            {value ? <CheckOutlined /> : <CloseOutlined />}{" "}
            {value ? "Действующая" : "Не действующая"}
          </>
        )
      },
      {
        key: "edit",
        render: (value: TLicense) => (
          <Button
            onClick={() => onEdit?.(value?.license_type_id)}
            icon={<EditOutlined />}
          >
            Редактировать
          </Button>
        )
      },
      {
        key: "delete",
        render: (value: TLicense) => (
          <Button
            onClick={() => onDelete?.(value?.license_type_id)}
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
          >
            Удалить
          </Button>
        )
      }
    ],
    [isDeleting, onDelete, onEdit]
  );

  return (
    <Table
      dataSource={licenseTypes}
      columns={columns}
      pagination={false}
      scroll={{ x: "1000px" }}
    />
  );
};
