import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Divider } from "antd";
import { DEVICE } from "constants/breakpoints";

export const DividerStyled = styled(Divider)<{ $isVertical: boolean }>`
  margin: 12px 0;

  @media ${DEVICE.LG} {
    margin: 8px 0;
  }
  ${({ $isVertical }) =>
    $isVertical &&
    css`
      margin: 0 12px;
      height: 100%;

      @media ${DEVICE.LG} {
        margin: 0 8px;
      }
    `}
`;
