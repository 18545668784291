import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLicenseTypeUpdateState } from "./types";

import { RequestError, TLicenseType } from "types";

const initialState: TLicenseTypeUpdateState = {
  isLoading: false,
  error: null,
  licenseType: undefined
};

export const LicenseTypeUpdateSlice = createSlice({
  name: "license/type/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLicenseType: (state, { payload }: PayloadAction<TLicenseType>) => {
      state.licenseType = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const licenseTypeUpdateActions = LicenseTypeUpdateSlice.actions;
export const licenseTypeUpdateReducer = LicenseTypeUpdateSlice.reducer;

export * as licenseTypeUpdateSelectors from "./selectors";
