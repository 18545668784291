import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";
import { TypographyTitleNoMargin } from "../TypographyTitleNoMargin/TypographyTitleNoMargin";

export const TitleStyled = styled(TypographyTitleNoMargin)`
  font-weight: 500 !important;

  @media ${DEVICE.XL} {
    font-size: 20px !important;
    line-height: 28px !important;
  }
`;
