import { createAsyncThunk } from "@reduxjs/toolkit";
import { licenseTypeUpdateActions } from ".";
import * as api from "api/license";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const updateLicenseType = createAsyncThunk<
  api.TUpdateLicenseTypeResponse,
  api.TUpdateLicenseTypeRequest
>(
  "license/type/updateLicenseType",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(licenseTypeUpdateActions.setLoading(true));
    dispatch(licenseTypeUpdateActions.setError(null));
    return api
      .updateLicenseType(payload)
      .then(({ data }) => {
        dispatch(licenseTypeUpdateActions.setLicenseType(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(licenseTypeUpdateActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(licenseTypeUpdateActions.setLoading(false));
      });
  }
);
