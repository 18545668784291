import React, { FC, useMemo } from "react";
import {
  AimOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { Timeline, TimelineProps } from "antd";
import { StyledStageText, timelineColor } from "./OfferTimeline.styles";
import { offerStatusIdMapper } from "utils/mappers";

type TOfferTimelineProps = {
  status_id: number;
  mode?: TimelineProps["mode"];
};

export const OfferTimeline: FC<TOfferTimelineProps> = ({ status_id, mode }) => {
  const curPriority = useMemo(
    () => offerStatusIdMapper[status_id]?.priority || 0,
    [status_id]
  );

  return (
    <Timeline
      style={{ minWidth: "252px" }}
      mode={mode}
      items={[
        {
          children: (
            <StyledStageText
              $color={
                curPriority === 1
                  ? timelineColor?.DEFAULT
                  : curPriority > 1
                    ? timelineColor.ACTIVE
                    : timelineColor.MUTED
              }
            >
              {offerStatusIdMapper[99]?.shortName}
            </StyledStageText>
          ),
          color: curPriority >= 1 ? timelineColor.ACTIVE : timelineColor.MUTED,
          dot:
            curPriority === 1 ? (
              <AimOutlined />
            ) : curPriority > 1 ? (
              <CheckOutlined />
            ) : (
              <ClockCircleOutlined />
            )
        },
        {
          children: (
            <StyledStageText
              $color={
                curPriority === 2
                  ? timelineColor?.DEFAULT
                  : curPriority > 2
                    ? timelineColor.ACTIVE
                    : timelineColor.MUTED
              }
            >
              {offerStatusIdMapper[123]?.shortName}
            </StyledStageText>
          ),
          color: curPriority >= 2 ? timelineColor.ACTIVE : timelineColor.MUTED,
          dot:
            curPriority === 2 ? (
              <AimOutlined />
            ) : curPriority > 2 ? (
              <CheckOutlined />
            ) : (
              <ClockCircleOutlined />
            )
        },
        ...(status_id === 107
          ? [
              {
                children: (
                  <StyledStageText $color={timelineColor.ERROR}>
                    {offerStatusIdMapper[107]?.shortName}
                  </StyledStageText>
                ),
                color: timelineColor.ERROR,
                dot: <CloseOutlined />
              }
            ]
          : [
              {
                children: (
                  <StyledStageText
                    $color={
                      curPriority === 3
                        ? timelineColor?.DEFAULT
                        : curPriority > 3
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED
                    }
                  >
                    {offerStatusIdMapper[101]?.shortName}
                  </StyledStageText>
                ),
                color:
                  curPriority >= 3 ? timelineColor.ACTIVE : timelineColor.MUTED,
                dot:
                  curPriority === 3 ? (
                    <AimOutlined />
                  ) : curPriority > 3 ? (
                    <CheckOutlined />
                  ) : (
                    <ClockCircleOutlined />
                  )
              }
            ]),
        ...(status_id === 107
          ? []
          : [
              {
                children: (
                  <StyledStageText
                    $color={
                      curPriority === 4
                        ? timelineColor?.DEFAULT
                        : curPriority > 4
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED
                    }
                  >
                    {offerStatusIdMapper[103]?.shortName}
                  </StyledStageText>
                ),
                color:
                  curPriority >= 4 ? timelineColor.ACTIVE : timelineColor.MUTED,
                dot:
                  curPriority === 4 ? (
                    <AimOutlined />
                  ) : curPriority > 4 ? (
                    <CheckOutlined />
                  ) : (
                    <ClockCircleOutlined />
                  )
              },
              {
                children: (
                  <StyledStageText
                    $color={
                      curPriority === 5
                        ? timelineColor?.DEFAULT
                        : curPriority > 5
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED
                    }
                  >
                    {offerStatusIdMapper[124]?.shortName}
                  </StyledStageText>
                ),
                color:
                  curPriority >= 5 ? timelineColor.ACTIVE : timelineColor.MUTED,
                dot:
                  curPriority === 5 ? (
                    <AimOutlined />
                  ) : curPriority > 5 ? (
                    <CheckOutlined />
                  ) : (
                    <ClockCircleOutlined />
                  )
              },
              ...(status_id === 102
                ? [
                    {
                      children: (
                        <StyledStageText $color={timelineColor.ERROR}>
                          {offerStatusIdMapper[102]?.shortName}
                        </StyledStageText>
                      ),
                      color: timelineColor.ERROR,
                      dot: <CloseOutlined />
                    }
                  ]
                : [
                    {
                      children: (
                        <StyledStageText
                          $color={
                            curPriority === 6
                              ? timelineColor?.DEFAULT
                              : curPriority > 6
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED
                          }
                        >
                          {offerStatusIdMapper[105]?.shortName}
                        </StyledStageText>
                      ),
                      color:
                        curPriority >= 6
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        curPriority === 6 ? (
                          <AimOutlined />
                        ) : curPriority > 6 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    }
                  ]),
              ...(status_id === 102
                ? []
                : [
                    {
                      children: (
                        <StyledStageText
                          $color={
                            curPriority === 7
                              ? timelineColor?.DEFAULT
                              : curPriority > 7
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED
                          }
                        >
                          {offerStatusIdMapper[125]?.shortName}
                        </StyledStageText>
                      ),
                      color:
                        curPriority >= 7
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        curPriority === 7 ? (
                          <AimOutlined />
                        ) : curPriority > 7 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    },
                    {
                      children: (
                        <StyledStageText
                          $color={
                            curPriority === 8
                              ? timelineColor?.DEFAULT
                              : curPriority > 8
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED
                          }
                        >
                          {offerStatusIdMapper[126]?.shortName}
                        </StyledStageText>
                      ),
                      color:
                        curPriority >= 8
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        curPriority === 8 ? (
                          <AimOutlined />
                        ) : curPriority > 8 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    },
                    ...(status_id === 128
                      ? [
                          {
                            children: (
                              <StyledStageText $color={timelineColor.ERROR}>
                                {offerStatusIdMapper[128]?.shortName}
                              </StyledStageText>
                            ),
                            color: timelineColor.ERROR,
                            dot: <CloseOutlined />
                          }
                        ]
                      : [
                          {
                            children: (
                              <StyledStageText
                                $color={
                                  curPriority === 9
                                    ? timelineColor.ACTIVE
                                    : timelineColor.MUTED
                                }
                              >
                                {offerStatusIdMapper[127]?.shortName}
                              </StyledStageText>
                            ),
                            color:
                              curPriority === 9
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED,
                            dot:
                              curPriority === 9 ? (
                                <CheckOutlined />
                              ) : (
                                <ClockCircleOutlined />
                              )
                          }
                        ])
                  ])
            ])
      ]}
    />
  );
};
