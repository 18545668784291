import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationForSellerByIdState } from "./types";

import { RequestError, TApplicationForSeller } from "types";

const initialState: TApplicationForSellerByIdState = {
  isLoading: false,
  error: null,
  application: undefined
};

export const ApplicationForSellerByIdSlice = createSlice({
  name: "applicationsForSeller/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApplication: (
      state,
      { payload }: PayloadAction<TApplicationForSeller>
    ) => {
      state.application = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const ApplicationForSellerByIdActions =
  ApplicationForSellerByIdSlice.actions;
export const ApplicationForSellerByIdReducer =
  ApplicationForSellerByIdSlice.reducer;

export * as applicationForSellerByIdSelectors from "./selectors";
