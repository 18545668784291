import styled from "@emotion/styled";
import { Panel } from "../Panel/Panel";
import { css } from "@emotion/react";

export const StyledPanel = styled(Panel)<{ gridColumn?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: span ${Math.min(3, gridColumn)};

      @media (max-width: 992px) {
        grid-column: span ${Math.min(2, gridColumn)};
      }

      @media (max-width: 576px) {
        grid-column: span ${Math.min(1, gridColumn)};
      }
    `}
`;
