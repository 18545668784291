import dayjs from "dayjs";
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORTENED,
  TIME_FORMAT_SHORTENED
} from "constants/dates";
import { fromIsoDateTime } from "./fromIsoDateTime";

export const toDisplayMessageDateTime = (date?: string | Date) => {
  if (!date) {
    return undefined;
  }

  const dDate = typeof date === "string" ? fromIsoDateTime(date) : dayjs(date);

  if (dayjs().isSame(dDate, "year")) {
    if (dayjs().isSame(dDate, "day")) {
      return dDate?.format(TIME_FORMAT_SHORTENED);
    } else {
      return dDate?.format(`${DATE_FORMAT_SHORTENED} ${TIME_FORMAT_SHORTENED}`);
    }
  } else {
    return dDate?.format(`${DATE_FORMAT} ${TIME_FORMAT_SHORTENED}`);
  }
};
