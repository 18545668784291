import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TChatRefreshState } from "./types";

import { RequestError } from "types";

const initialState: TChatRefreshState = {
  isLoading: false,
  error: null
};

export const ChatRefreshSlice = createSlice({
  name: "chat/Refresh",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const chatRefreshActions = ChatRefreshSlice.actions;
export const chatRefreshReducer = ChatRefreshSlice.reducer;

export * as chatRefreshSelectors from "./selectors";
