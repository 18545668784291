import React, { FC, useMemo } from "react";
import { TEntity } from "types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

type TCompaniesTableProps = {
  entities?: TEntity[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  isDeleting?: boolean;
};

export const CompaniesTable: FC<TCompaniesTableProps> = ({
  entities,
  onDelete,
  onEdit,
  isDeleting
}) => {
  const columns: ColumnsType<TEntity> = useMemo(
    () => [
      {
        title: "Название",
        dataIndex: "full_name"
      },
      {
        key: "edit",
        render: (value: TEntity) => (
          <Button
            onClick={() => onEdit?.(value?.entity_id)}
            icon={<EditOutlined />}
          >
            Редактировать
          </Button>
        )
      },
      {
        key: "delete",
        render: (value: TEntity) => (
          <Button
            onClick={() => onDelete?.(value?.entity_id)}
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
          >
            Удалить
          </Button>
        )
      }
    ],
    [isDeleting, onDelete, onEdit]
  );

  return (
    <Table
      dataSource={entities}
      columns={columns}
      pagination={false}
      scroll={{ x: "800px" }}
    />
  );
};
