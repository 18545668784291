import React, { FC, useCallback, useMemo, useState } from "react";
import {
  RequestError,
  TAccount,
  TApplication,
  TApplicationApprovement,
  TApplicationPosition,
  TEntity,
  TPerson,
  TProject
} from "types";
import { FormWrapper, Wrapper } from "./ApplicationEditForm.styles";
import { BasicTitle } from "../BasicTitle/BasicTitle";
import { Button, StepProps, Steps } from "antd";
import {
  InfoCircleOutlined,
  LeftOutlined,
  OrderedListOutlined,
  SmileOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { GeneralTab, PositionsTab, PreviewTab, ProcessTab } from "./components";
import { Dayjs } from "dayjs";
import { DEVICE } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";

export type TApplicationApprovementFormValue = Omit<
  TApplicationApprovement,
  "approver"
> & {
  approver?: number;
};
export type TApplicationPositionFromValue = Omit<
  TApplicationPosition,
  "unit_amount"
> & {
  unit_amount: string;
};
export type TApplicationFormValues = Partial<
  Omit<
    TApplication,
    | "application_delivery_due_datetime"
    | "buyer_account"
    | "responsible"
    | "approvements"
    | "positions"
  > & {
    application_delivery_due_datetime?: Dayjs;
    buyer_account?: number;
    buyer_entity?: number;
    responsible?: number;
    approvements?: TApplicationApprovementFormValue[];
    positions?: TApplicationPositionFromValue[];
  }
>;

export type TApplicationEditFormTabProps = Omit<
  TApplicationEditFormProps,
  "application" | "user" | "onCancel" | "onSubmit"
> & {
  formData: Partial<TApplication>;
  onSubmitStep: (values: Partial<TApplication>) => void;
  onSubmitForm: () => void;
  onPrevStep: () => void;
  onStepEdit: (step: number) => void;
};

type TApplicationEditFormProps = {
  application?: TApplication;
  user?: TPerson;
  users?: TPerson[];
  projects?: TProject[];
  accounts?: TAccount[];
  entities?: TEntity[];
  onSubmit: (values: TApplication) => void;
  isLoading: boolean;
  error: RequestError | null;
  onCancel: () => void;
};

export const ApplicationEditForm: FC<TApplicationEditFormProps> = ({
  application,
  user,
  onCancel,
  onSubmit,
  ...props
}) => {
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isMD = useMediaQuery({ query: DEVICE.MD });

  const [formData, setFormData] = useState<Partial<TApplication>>(
    application || {
      is_need_delivery: false,
      is_postpayment: false,
      creator: user,
      approvements: [
        {
          approvement_type_id: 119,
          approver: props?.users?.find((user) => user?.cabinet_role_id === 2)
        } as TApplicationApprovement
      ]
    }
  );
  const [step, setStep] = useState(0);

  const onNextStep = useCallback(() => {
    setStep((prev) => prev + 1);
  }, []);
  const onPrevStep = useCallback(() => {
    setStep((prev) => prev - 1);
  }, []);
  const onGoToStep = useCallback((step: number) => {
    setStep(step);
  }, []);

  const onSubmitStep = useCallback(
    (data: Partial<TApplication>) => {
      setFormData((prev) => ({ ...prev, ...data }));
      onNextStep();
    },
    [onNextStep]
  );
  const onSubmitForm = useCallback(() => {
    onSubmit(formData as TApplication);
  }, [formData, onSubmit]);

  const steps: (StepProps & {
    content: React.ComponentType<TApplicationEditFormTabProps>;
  })[] = useMemo(
    () => [
      {
        title: "Параметры заявки",
        icon: <InfoCircleOutlined />,
        content: GeneralTab
      },
      {
        title: "Позиции заявки",
        icon: <OrderedListOutlined />,
        content: PositionsTab
      },
      {
        title: "Процесс заявки",
        icon: <TeamOutlined />,
        content: ProcessTab
      },
      {
        title: "Завершение",
        icon: <SmileOutlined />,
        content: PreviewTab
      }
    ],
    []
  );

  const Tab = useMemo(() => steps[step].content, [step, steps]);

  return (
    <Wrapper>
      <BasicTitle>
        <Button icon={<LeftOutlined />} onClick={onCancel} type="text" />
        {application
          ? `Заявка № ${application?.application_id} - ${application?.application_name}`
          : "Создание заявки"}
      </BasicTitle>

      <Steps
        items={steps}
        current={step}
        size="small"
        progressDot={isLG}
        direction={isMD ? "vertical" : "horizontal"}
      />

      <FormWrapper>
        <Tab
          {...props}
          formData={formData}
          onSubmitForm={onSubmitForm}
          onPrevStep={onPrevStep}
          onSubmitStep={onSubmitStep}
          onStepEdit={onGoToStep}
        />
      </FormWrapper>
    </Wrapper>
  );
};
