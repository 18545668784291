import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import { TCabinet } from "types";
import { addCabinet } from "store/cabinets/add/thunk";
import { updateCabinet } from "store/cabinets/update/thunk";
import { deleteCabinet } from "store/cabinets/delete/thunk";
import { Wrapper } from "./CabinetsTab.styles";
import { CabinetEditForm, CabinetsTable } from "components/common/old";
import { Button } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { cabinetAddActions, cabinetAddSelectors } from "store/cabinets/add";
import {
  cabinetUpdateActions,
  cabinetUpdateSelectors
} from "store/cabinets/update";
import {
  cabinetDeleteActions,
  cabinetDeleteSelectors
} from "store/cabinets/delete";

export const CabinetsTab = () => {
  const dispatch = useAppDispatch();

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    cabinetAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    cabinetUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    cabinetDeleteSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);
  const [cabinetInEdit, setCabinetInEdit] = useState<TCabinet | undefined>(
    undefined
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const cabinet = cabinets?.find((cabinet) => cabinet?.cabinet_id === id);
      setCabinetInEdit(cabinet);
    },
    [cabinets]
  );
  const onCancelEdit = useCallback(() => {
    setCabinetInEdit(undefined);
  }, []);

  const onAddCabinet = useCallback(
    (values: TCabinet) => {
      dispatch(addCabinet(values))
        .unwrap()
        .then(() => {
          onCancelAdd();
          getCabinetsArr();
        });
    },
    [dispatch, getCabinetsArr, onCancelAdd]
  );
  const onEditCabinet = useCallback(
    (values: TCabinet) => {
      dispatch(updateCabinet({ ...cabinetInEdit, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          getCabinetsArr();
        });
    },
    [cabinetInEdit, dispatch, getCabinetsArr, onCancelEdit]
  );
  const onDeleteCabinet = useCallback(
    (id: number) => {
      dispatch(deleteCabinet(id))
        .unwrap()
        .then(() => {
          getCabinetsArr();
        });
    },
    [dispatch, getCabinetsArr]
  );

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  useEffect(() => {
    return () => {
      dispatch(cabinetAddActions.clearState());
      dispatch(cabinetUpdateActions.clearState());
      dispatch(cabinetDeleteActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {!cabinetInEdit && (
        <Button
          icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAdding ? onCancelAdd : onAdd}
        >
          {isAdding ? "Отмена" : "Добавить"}
        </Button>
      )}

      {cabinetInEdit ? (
        <>
          <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
            Отмена
          </Button>
          <CabinetEditForm
            cabinet={cabinetInEdit}
            onSubmit={onEditCabinet}
            isLoading={updateIsLoading}
            error={updateError}
          />
        </>
      ) : isAdding ? (
        <CabinetEditForm
          onSubmit={onAddCabinet}
          isLoading={addIsLoading}
          error={addError}
        />
      ) : (
        <CabinetsTable
          cabinets={cabinets}
          onEdit={onEdit}
          onDelete={onDeleteCabinet}
          isDeleting={deleteIsLoading}
        />
      )}
    </Wrapper>
  );
};
