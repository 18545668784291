import dayjs from "dayjs";
import { ISO_DATE_TIME } from "constants/dates";

export const fromIsoDateTime = (date?: string) => {
  if (!date) {
    return undefined;
  }

  return dayjs(date, ISO_DATE_TIME);
};
