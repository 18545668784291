import React, { FC } from "react";
import {
  AimOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { Timeline, TimelineProps } from "antd";
import { StyledStageText, timelineColor } from "./ApplicationTimeline.styles";
import { applicationStatusIdMapper } from "utils/mappers";

type TApplicationTimelineProps = {
  status_id: number;
  mode?: TimelineProps["mode"];
};

export const ApplicationTimeline: FC<TApplicationTimelineProps> = ({
  status_id,
  mode
}) => {
  return (
    <Timeline
      style={{ minWidth: "252px" }}
      mode={mode}
      items={
        status_id === 122
          ? [
              {
                children: (
                  <StyledStageText $color={timelineColor.ERROR}>
                    {applicationStatusIdMapper[122]?.shortName}
                  </StyledStageText>
                ),
                color: timelineColor.ERROR,
                dot: <CloseOutlined />
              }
            ]
          : [
              {
                children: (
                  <StyledStageText
                    $color={
                      status_id === 92
                        ? timelineColor?.DEFAULT
                        : status_id > 92
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED
                    }
                  >
                    {applicationStatusIdMapper[92]?.shortName}
                  </StyledStageText>
                ),
                color:
                  status_id >= 92 ? timelineColor.ACTIVE : timelineColor.MUTED,
                dot:
                  status_id === 92 ? (
                    <AimOutlined />
                  ) : status_id > 92 ? (
                    <CheckOutlined />
                  ) : (
                    <ClockCircleOutlined />
                  )
              },
              {
                children: (
                  <StyledStageText
                    $color={
                      status_id === 93
                        ? timelineColor?.DEFAULT
                        : status_id > 93
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED
                    }
                  >
                    {applicationStatusIdMapper[93]?.shortName}
                  </StyledStageText>
                ),
                color:
                  status_id >= 93 ? timelineColor.ACTIVE : timelineColor.MUTED,
                dot:
                  status_id === 93 ? (
                    <AimOutlined />
                  ) : status_id > 93 ? (
                    <CheckOutlined />
                  ) : (
                    <ClockCircleOutlined />
                  )
              },
              ...(status_id === 95
                ? [
                    {
                      children: (
                        <StyledStageText $color={timelineColor.ERROR}>
                          {applicationStatusIdMapper[95]?.shortName}
                        </StyledStageText>
                      ),
                      color: timelineColor.ERROR,
                      dot: <CloseOutlined />
                    }
                  ]
                : [
                    {
                      children: (
                        <StyledStageText
                          $color={
                            status_id === 94
                              ? timelineColor?.DEFAULT
                              : status_id > 94
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED
                          }
                        >
                          {applicationStatusIdMapper[94]?.shortName}
                        </StyledStageText>
                      ),
                      color:
                        status_id >= 94
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        status_id === 94 ? (
                          <AimOutlined />
                        ) : status_id > 94 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    }
                  ]),
              ...(status_id === 95
                ? []
                : [
                    {
                      children: (
                        <StyledStageText
                          $color={
                            status_id === 96
                              ? timelineColor?.DEFAULT
                              : status_id > 96
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED
                          }
                        >
                          {applicationStatusIdMapper[96]?.shortName}
                        </StyledStageText>
                      ),
                      color:
                        status_id >= 96
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        status_id === 96 ? (
                          <AimOutlined />
                        ) : status_id > 96 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    },
                    {
                      children: (
                        <StyledStageText
                          $color={
                            status_id === 121
                              ? timelineColor?.DEFAULT
                              : status_id > 96
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED
                          }
                        >
                          {applicationStatusIdMapper[121]?.shortName}
                        </StyledStageText>
                      ),
                      color:
                        status_id > 96
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        status_id === 121 ? (
                          <AimOutlined />
                        ) : status_id > 96 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    },
                    ...(status_id === 98
                      ? [
                          {
                            children: (
                              <StyledStageText $color={timelineColor.ERROR}>
                                {applicationStatusIdMapper[98]?.shortName}
                              </StyledStageText>
                            ),
                            color: timelineColor.ERROR,
                            dot: <CloseOutlined />
                          }
                        ]
                      : [
                          {
                            children: (
                              <StyledStageText
                                $color={
                                  status_id === 97
                                    ? timelineColor.ACTIVE
                                    : timelineColor.MUTED
                                }
                              >
                                {applicationStatusIdMapper[97]?.shortName}
                              </StyledStageText>
                            ),
                            color:
                              status_id === 97
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED,
                            dot:
                              status_id === 97 ? (
                                <CheckOutlined />
                              ) : (
                                <ClockCircleOutlined />
                              )
                          }
                        ])
                  ])
            ]
      }
    />
  );
};
